<script>
  import {MONTHS} from '../../../constants';

  export let props;

  let date;

  const formatDate = () => {
    try {
      const d = new Date(props[props.column.key]);
      return `${MONTHS[d.getMonth()].substring(
        0,
        3
      )} ${d.getDate()}, ${d.getFullYear()} at ${d.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      })}`;
    } catch (error) {
      return 'N/A';
    }
  };

  $: date = formatDate(props);
</script>

<span>{date}</span>
