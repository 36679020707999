<script>
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
  import CameraSelector from './CameraSelector.svelte';

  import {isMobile} from '@cox2m/city-services-ui-components/src/funcs';
  import {createEventDispatcher} from 'svelte';

  const dispatch = createEventDispatcher();

  export let cameraViewError = false;
  export let selectedEvent = null;
  export let cameraViews = [];
  export let currentIndex = 0;
  export let liveCameraToggleSwitch = false;
  export let enableLinkToLive = false;
</script>

<style>
  .camera-viewer-control-box {
    min-height: var(--cox2m-spacing-10-units);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: var(--cox2m-spacing-8-units);
    border-bottom: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-300);
    z-index: 1;
  }
  .fs-1000{
    font-size: var(--cox2m-fs-1000);
  }
  .selected-event-media-container{
    display: flex;
    align-items: center;
  }
  .live-camera-switch {
    width: var(--cox2m-spacing-18-units);
    height: var(--cox2m-spacing-5-units);
    border-radius: calc(
      var(--cox2m-spacing-1-units) + var(--cox2m-visual-corrector)
    );
    color: var(--cox2m-clr-neutral-white);
    background-color: var(--cox2m-clr-neutral-400);
    display: flex;
    align-items: center;
    padding: var(--cox2m-spacing-2-units);
  }

  .live-camera-switch.active {
    background-color: var(--cox2m-clr-success-400);
  }

  .live-camera-switch .rounded-circle {
    background-color: var(--cox2m-clr-neutral-white);
    height: var(--cox2m-spacing-2-units);
    width: var(--cox2m-spacing-2-units);
  }

  .live-camera-switch.active .rounded-circle {
    background-color: var(--cox2m-clr-critical-500);
  }
  @media only screen and (max-width: 767px){
    .camera-viewer-control-box {
      flex-direction: column;
      align-items: flex-start;
      padding-block: var(--cox2m-spacing-2-units);
      row-gap: var(--cox2m-spacing-2-units);
    }
  }
</style>

<div class="camera-viewer-control-box">
  <div class="selected-event-media-container">
    {#if !cameraViewError || selectedEvent}
      <Icon
        id="camera-viewer-icon"
        className="mr-3"
        icon="monitor"
        color="var(--cox2m-clr-neutral-black)"
        size="var(--cox2m-spacing-5-units)" />
      {#if selectedEvent}
        <span class="fs-1000" id="active-event-disclaimer">
          {#if !isMobile()}Selected{/if}
          Event Media
        </span>
      {:else}
        <CameraSelector
          {cameraViews}
          selectedCameraIndex={currentIndex}
          on:cameraIndexChange />
      {/if}
    {/if}
  </div>
  {#if !selectedEvent}
    <span
      id="live-camera-switch"
      class="live-camera-switch cursor-pointer"
      class:active={liveCameraToggleSwitch}
      on:click={() => dispatch('toggleLiveCamera')}>
      <span class="rounded-circle mr-2" />
      <span class="live-label cox2m-smalltext-2">LIVE</span>
    </span>
  {/if}

  {#if enableLinkToLive && selectedEvent}
    <a
      class="font-weight-bold"
      href={`/#/live-view/${selectedEvent.camera.name}`}>
      Go to live view
    </a>
  {/if}
</div>
