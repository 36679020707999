<script>
  export let imageModal = false;
  export let className = '';
  export let style = '';
  export let image = {};

  let error = false;
</script>

<style>
  img {
    border-radius: 1rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: var(--cox2m-clr-neutral-white);
    height: 100% !important;
    min-height: 250px;
    max-height: 70vh;
    touch-action: pinch-zoom;
  }
  .gallery-image-container > div {
    display: grid;
    grid-template-rows: 1fr;
    background-color: var(--cox2m-clr-neutral-white);
    border-radius: 1rem;
  }

  .gallery-image-container {
    border-radius: 1rem;
    padding-right: var(--cox2m-spacing-6-units) !important;
    margin-bottom: var(--cox2m-spacing-4-units) !important;
  }
  .image-information {
    background-color: var(--cox2m-clr-neutral-white);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .fs-1200{
    font-size: var(--cox2m-fs-1200);
  }
  .fs-1000{
    font-size: var(--cox2m-fs-1000);
  }
  @media only screen and (max-width: 1440px){
    img{
      min-height: 175px;
    }
  }
</style>

{#if image}
  <div
    class="gallery-image-container m-0 p-0 {className}" {style}
    >
    <div class="w-100 h-100" style={!imageModal ? 'cursor:pointer' : ''}>
      <div class="w-100 image-direct-container" >
          <img
            class="img-fluid w-100 h-100 gallery-image"
            id={`${image}-image`}
            data-testid={`${image}-image`}
            src={image}
            alt={!error? '': `${image}-placeholder`}
            on:error={() => error = true}/>
      </div>
    </div>
  </div>
{/if}
