<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';

  export let items = [...Array(10).keys()];
  export let allEventsSelected = true;
  export let camerasWithEvents = [];
  export let cameraViewError;
  export let cameraIndex = 0;
  export let scrollBy = 1;

  const paginationFactor = 195;
  const totalPaginationPixels = scrollBy * paginationFactor;

  $: offset = 0;
  $: atStart = offset === 0;
  $: atEnd = offset <= paginationFactor * (items.length - scrollBy) * -1;

  const move = direction => {
    if (direction > 0 && !atEnd) {
      offset -= totalPaginationPixels;
    } else if (direction < 0 && !atStart) {
      offset += totalPaginationPixels;
    }
  };
</script>

<style>
  #camera-carousel {
    overflow: hidden;
  }

  .items {
    display: flex;
    transition: transform 0.4s ease-in-out;
    transform: translateX(0px);
  }

  .item {
    min-width: 167px;
    height: 100px;
    margin: 0 var(--cox2m-spacing-4-units);
    border-radius: 0.7rem;
    background-color: var(--cox2m-clr-neutral-white);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    user-select: none;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }
  .item img {
    cursor: pointer;
    object-fit: cover;
  }
  .items .item:first-child {
    margin-left: 5px;
  }

  .items .item:last-child {
    margin-right: 0;
  }
  .floating-camera-label {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--cox2m-clr-neutral-black);
    border-radius: 12px 0;
    color: var(--cox2m-clr-neutral-white);
    width: var(--cox2m-spacing-23-units);
    height: var(--cox2m-spacing-8-units);
  }
  .prev-button,
  .next-button {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .prev-button {
    left: -42px;
  }
  .next-button {
    right: -42px;
  }
  #camera-carousel-container button {
    width: var(--cox2m-spacing-8-units);
    height: var(--cox2m-spacing-8-units);
    background-color: var(--cox2m-clr-neutral-200);
  }
  #camera-carousel-container{
    height: 132px;
    margin: auto;
    width: calc(100% - 90px);
  }
  .fs-1300{
    font-size: var(--cox2m-fs-1300);
  }
  .item img{
    max-width: 167px;
  }
</style>

{#if !cameraViewError}
  <div id="camera-carousel-container" class="position-relative">
    <button
      class="prev-button position-absolute d-flex justify-content-center
      align-items-center rounded"
      id="camera-carousel-prev-button"
      disabled={atStart}
      on:click={() => move(-1)}>
      <Icon
        icon="chevron-left"
        color="var(--cox2m-clr-neutral-700)"
        size="var(--cox2m-spacing-5-units)" />
    </button>
    <div id="camera-carousel" class="pt-3 position-relative">
      <div class="items py-2" style="transform: translateX({offset}px);" id="carousel-items">
        {#each items as item, i (item.id || i)}
          {#if allEventsSelected ? true : camerasWithEvents.indexOf(item.id) !== -1}
            <div class="item cox2m-shadow-1-dp" id={`camera-carousel-item-${i}`} on:click={() => cameraIndex = i}>
              {#if !item.loading}
                {#if item.imageUrl}
                  <img src={item.imageUrl} class="w-100 h-100" alt="camera screenshot" />
                {:else}{i}{/if}
              {:else}
                <LoadingSpinner />
              {/if}
              <span
                class="floating-camera-label text-center fs-1300 d-flex
                align-items-center justify-content-center">
                <span id={`camera-carousel-item-${i}-name`}>{item.name || ''}</span>
              </span>
            </div>
          {/if}
        {/each}
      </div>
    </div>
    <button
      class="next-button position-absolute d-flex justify-content-center
      align-items-center rounded"
      disabled={atEnd}
      id="camera-carousel-next-button"
      on:click={() => move(1)}>
      <Icon
        icon="chevron-right"
        color="var(--cox2m-clr-neutral-700)"
        size="var(--cox2m-spacing-5-units)" />
    </button>
  </div>
{/if}

<!--
  commented for debug purposes
  <div class="details">
  offset: {offset}px
  <br />
  atStart: {atStart}
  <br />
  atEnd: {atEnd}
</div> -->
