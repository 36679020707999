export const setTimeNow = () => {
  let now = new Date();
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);

  return now.toISOString().replace('Z', '');
};

export const timeRangeConstraints = {
  minutesMin: 0,
  minutesMax: 59,
  hoursMin: 1,
  hoursMax: 12
};

export const cameraSortFunction = (a, b) => (a.label > b.label ? 1 : -1);

export const waitForOpenConnection = (
  socket,
  maxAttempts = 30,
  intervalTimeMs = 500
) => {
  return new Promise((resolve, reject) => {
    const maxNumberOfAttempts = maxAttempts;
    const intervalTime = intervalTimeMs;

    let currentAttempt = 0;
    const interval = setInterval(() => {
      if (currentAttempt > maxNumberOfAttempts - 1) {
        clearInterval(interval);
        reject(new Error('Maximum number of attempts exceeded'));
      } else if (socket.readyState === socket.OPEN) {
        clearInterval(interval);
        resolve();
      }
      currentAttempt++;
    }, intervalTime);
  });
};

export const sendWebsocketMessage = async (socket, msg) => {
  if (socket.readyState !== socket.OPEN) {
    try {
      await waitForOpenConnection(socket);
      socket.send(msg);
    } catch (err) {
      console.error(err);
    }
  } else {
    socket.send(msg);
  }
};

export const buildSocketUrl = ({
  selectedType = null,
  selectedCamera = null,
  useViolationEvents = null
}) => {
  return `wss://WEBSOCKET_HOST/ws/event?
  ${selectedType ? `&type=${selectedType.value}` : ''}
  ${selectedCamera ? `&filter=camera.name:${selectedCamera.label}` : ''}
  ${useViolationEvents ? `&is_violation=true` : ''}`;
};

export const setEventsWebsocket = ({eventsWebsocket = null, socketUrl, authMessage , liveEventsActive, onMessageHandler}) => {
  eventsWebsocket && eventsWebsocket.close();

  if (liveEventsActive) {
    const webSocketHost = socketUrl;
    eventsWebsocket = new WebSocket(webSocketHost);

    eventsWebsocket.onopen = () => {
      sendWebsocketMessage(eventsWebsocket, authMessage);
    };

    eventsWebsocket.onmessage = (event) => {
      onMessageHandler(event)
    };

    eventsWebsocket.onclose = () => {
      setTimeout(() => setEventsWebsocket({eventsWebsocket: eventsWebsocket, socketUrl, authMessage , liveEventsActive, onMessageHandler}), 3000);
    };

    return eventsWebsocket;
  }

  return eventsWebsocket;
};

export const clearSockets = eventsWebsocket => {
  if (eventsWebsocket) {
    eventsWebsocket.onclose = () => {};
    eventsWebsocket.close();
  }
};

export const typeOptions = [
  {label: 'Vehicles', value: 'VEHICLE'},
  {label: 'Pedestrians', value: 'PEOPLE'}
];

export const getPoliceUsers = async ({axios, headers}) => {
  const policeUsersGroup = '77423168-9dd6-4516-81dc-e144962cd55c';

  try {
    const {data} = await axios.get(
      `SMART_CITIES_API_HOST/groups/${policeUsersGroup}/users`,
      headers
    );
    return {fulfilled: true, data: data.filter(autorithy => autorithy.phoneNumber)};
  } catch (error) {
    return {fulfilled: false, data: [], error};
  }
};

export const formatDateString = (
  {isInitialDate, date, userPickedTime, initialTime = '00:00:00.000', finalTime = '23:59:59.999'}
) => {
  if (isInitialDate) {
    return date
      .replace(
        /T.*/gi,
        userPickedTime
          ? 'T' + userPickedTime.selectedStartTime.isoFormat.substring(0, 12)
          : `T${initialTime}`
      ).replace('Z', '');
  } else {
    return date
      .replace(
        /T.*/gi,
        userPickedTime
          ? `T${userPickedTime.selectedEndTime.isoFormat.substring(
              0,
              5
            )}:59.999`
          : `T${finalTime}`
      ).replace('Z', '');
  }
};

export const handleEventMarkerClick = ({mapView = null, data, event}) => {
  if (mapView) {
    mapView.popup.autoOpenEnabled = false;
    mapView.popup.collapseEnabled = false;
    mapView.popup.open({
      title: `Camera ${data.name}`,
      location: event.mapPoint,
      content: `<b>${data.name}</b><br>${data.address}`
    });
    mapView.popup.visible = true;
  }
};

export const classifyDataForMapJourney = (eventGroup, selectedGroupedEventIndex) => {
  return eventGroup.map((eventToClassify, eventIndex) => {
    if (eventIndex === 0 && selectedGroupedEventIndex === 0) {
      eventToClassify.markerType = 'journey-start-selected';
    } else if (eventIndex === 0) {
      eventToClassify.markerType = 'journey-start';
    } else if (
      eventIndex === eventGroup.length - 1 &&
      eventIndex === selectedGroupedEventIndex
    ) {
      eventToClassify.markerType = 'journey-end-selected';
    } else if (eventIndex === eventGroup.length - 1) {
      eventToClassify.markerType = 'journey-end';
    } else if (eventIndex === selectedGroupedEventIndex) {
      eventToClassify.markerType = 'selected-marker';
    } else {
      eventToClassify.markerType = 'regular-marker';
    }

    //this seems weird but it's the only way to communicate with the map because the groupedEventId's should be different but they apparently are not
    eventToClassify.index = eventIndex;
    return eventToClassify;
  });
};

/**
 * Gets and build the map marker collection given an event
 * @param event
 */
export const getMapMarkerCollection = (eventGroup, selectedGroupedEventIndex) => {
  eventGroup = classifyDataForMapJourney(
    eventGroup,
    selectedGroupedEventIndex
  );

  return eventGroup;
};
