<script>
  export let props = {};
</script>

<style>
  img {
    max-width: 240px;
  }
</style>

<img
    src={props.imageUrl}
    alt={props.imageUrl}
/>
