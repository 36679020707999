<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import ToggleSwitch from '@cox2m/city-services-ui-components/src/forms/ToggleSwitch.svelte';
  import NoEventsAssociatedMessage from './camera-viewer/NoEventsAssociatedMessage.svelte';
  import CameraViewsErrorMessage from './camera-viewer/CameraViewsErrorMessage.svelte';
  import AdditionalControllers from './camera-viewer/AdditionalControllers.svelte';
  import MainCamerasCarousel from './camera-viewer/MainCamerasCarousel.svelte';
  import MainControllers from './camera-viewer/MainControllers.svelte';
  import CameraCarousel from './events/CameraCarousel.svelte';
  import axios from 'axios';
  import Hls from 'hls.js';

  import {
    getEventImageAsBase64,
    getEventVideoAsBase64,
    clearSockets,
    getRtspCredentials,
    getRtspPath
  } from '../../../funcs';
  import {isMobile} from '@cox2m/city-services-ui-components/src/funcs';
  import {HLS_CONFIG} from '../../constants';
  import {selectedPark} from '../../stores';
  import {getCameras} from '../../actions';
  import {onMount, tick, createEventDispatcher} from 'svelte';
  import {Buffer} from 'buffer';

  export let activateCameraCarousel = false;
  export let enableLinkToLive = false;
  export let allEventsSelected = true;
  export let dashboardViewer = false;
  export let shouldShowVideo = false;
  export let camerasWithEvents = [];
  export let selectedEvent = null;
  export let customClass = '';
  export let height;
  export let token;

  const dispatch = createEventDispatcher();
  const MAXIMUM_REFETCH_TRIES = 5;
  const IS_VIDEO_ENABLED = 'EVENT_VIDEO_ENABLED' === 'true';

  let videoRetryCounter = 0;
  let cameraViews = Array.from({length: 12}, () => {
    return {loading: true};
  });

  let liveCameraToggleSwitch = false;
  let selectedMediaLoading = false;
  let videoImageSwitch = false;
  let cameraViewError = false;
  let cameraWebSocket = null;
  let mediaError = false;
  let currentIndex = 0;
  let eventImage = null;
  let eventVideo = null;
  let video;
  let isMobileView = false;
  let vidElement = null;

  const hexToBase64 = data => {
    return Buffer.from(data).toString('base64');
  };

  const updateCameras = cameraData => {
    if (cameraData.siteName === $selectedPark.name) {
      if (cameraViews.some(view => view.name === cameraData.name)) {
        cameraViews.map((view, index) => {
          if (view.name === cameraData.name) {
            cameraViews[index] = {
              ...view,
              ...cameraData,
              loading: false
            };
          }
        });
      } else {
        let cameraToUpdateIndex = cameraViews.findIndex(view => !view.name);
        if (cameraToUpdateIndex >= 0) {
          cameraViews[cameraToUpdateIndex] = {
            ...cameraViews[cameraToUpdateIndex],
            ...cameraData,
            loading: false
          };
        } else {
          cameraViews.push({
            ...cameraData,
            loading: false
          });
        }
      }
    }
  };

  const setCameraWebSocket = () => {
    cameraWebSocket && cameraWebSocket.close();

    const webSocketHost = 'wss://WEBSOCKET_HOST/ws/camera';
    const authMessage = `{"auth" : "${token}"}`;

    cameraWebSocket = new WebSocket(webSocketHost);

    cameraWebSocket.onopen = () => {
      cameraViewError = false;
      cameraWebSocket.send(authMessage);
    };

    cameraWebSocket.onmessage = event => {
      let cameraData = JSON.parse(event.data);
      updateCameras(cameraData);
    };

    cameraWebSocket.onerror = () => {
      cameraViewError = true;
    };

    cameraWebSocket.onclose = () => {
      cameraWebSocket = null;
      setCameraWebSocket();
    };
  };

  const setLiveCamera = () => {
    try {
      if (
        Hls.isSupported() &&
        cameraViews[currentIndex] &&
        cameraViews[currentIndex].name &&
        cameraViews[currentIndex].rtsp
      ) {
        setTimeout(async () => {
          let latestLoadedFragment;
          window.hls && window.hls.destroy();
          video = document.getElementById(`live-video-${currentIndex}`);

          video &&
            (video.ontimeupdate = () => {
              if (
                video &&
                video.currentTime < 1 &&
                !video.paused &&
                !video.ended &&
                video.duration > 1 &&
                video.readyState > video.HAVE_CURRENT_DATA
              ) {
                video.pause();
                setTimeout(
                  (video.currentTime = latestLoadedFragment.start),
                  2000
                );
                video.play();
              }
            });

          const rtspUrl = cameraViews[currentIndex].rtsp;
          const rtspCredentials = getRtspCredentials(rtspUrl);
          const rtspPath = getRtspPath(rtspUrl);
          const rtspAuth = btoa(
            `${rtspCredentials.username}:${rtspCredentials.password}`
          );
          const hlsConfig = {
            ...HLS_CONFIG,
            xhrSetup: function(xhr) {
              xhr.setRequestHeader('Authorization', `Basic ${rtspAuth}`);
            },
            fetchSetup: function(context, initParams) {
              const headers = new Headers();
              headers.append('Authorization', `Basic ${rtspAuth}`);
              initParams.headers = headers;
              return new Request(context.url, initParams);
            }
          };
          window.hls = new Hls(hlsConfig);
          window.hls.loadSource(`STREAMING_HOST/${rtspPath}/stream.m3u8`);
          window.hls.attachMedia(video);

          window.hls.on(Hls.Events.ERROR, (event, data) => {
            if (videoRetryCounter === MAXIMUM_REFETCH_TRIES) {
              window.hls.destroy();
              window.hls.loadSource();
              videoRetryCounter = 0;
            } else if (data.fatal) {
              videoRetryCounter += 1;
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  setLiveCamera();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  window.hls.recoverMediaError();
                  break;
                default:
                  window.hls.destroy();
                  break;
              }
            }
          });

          window.hls.on(Hls.Events.FRAG_LOADED, (e, d) => {
            latestLoadedFragment = d.frag;
            videoRetryCounter = 0;
          });
        }, 1000);
      } else if (
        cameraViews[currentIndex] &&
        cameraViews[currentIndex].name &&
        cameraViews[currentIndex].rtsp
      ) {
        setTimeout(() => {
          const rtspUrl = cameraViews[currentIndex].rtsp;
          const rtspCredentials = getRtspCredentials(rtspUrl);
          const rtspPath = getRtspPath(rtspUrl);
          const hlsHost = `${'STREAMING_HOST'}`.replace('https://', '');

          video = document.getElementById(`live-video-${currentIndex}`);
          video.src = `https://${rtspCredentials.username}:${rtspCredentials.password}@${hlsHost}/${rtspPath}/stream.m3u8`;
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleIndexChange = async () => {
    if (activateCameraCarousel) {
      clearLiveCamera();
      selectedEvent = null;
      await tick();
      let slides = document.getElementsByClassName('carousel-item');
      Array.from(slides).forEach(
        (slide, index) =>
          index !== currentIndex && slide.classList.remove('active')
      );
      slides[currentIndex] &&
        slides[currentIndex].classList &&
        slides[currentIndex].classList.add('active');
    }
  };

  const handleEventChange = async () => {
    clearLiveCamera();
    if (selectedEvent) {
      mediaError = false;
      selectedMediaLoading = true;
      currentIndex = null;

      try {
        if (videoImageSwitch) {
          let videoResponse = await getEventVideoAsBase64({
            eventId: selectedEvent.id,
            axios,
            token
          });
          eventVideo = hexToBase64(videoResponse);
          eventImage = null;
          return;
        }

        const showDetectionScore = !(
          selectedEvent.isViolationEvent ||
          window.location.hash.includes('violations')
        );
        let imageResponse = await getEventImageAsBase64({
          eventId: selectedEvent.id,
          axios,
          token,
          showDetectionScore
        });
        eventImage = hexToBase64(imageResponse);
        eventVideo = null;
      } catch (error) {
        mediaError = true;
      }

      selectedMediaLoading = false;
    }
  };

  const clearLiveCamera = () => {
    liveCameraToggleSwitch = false;
    window.hls && window.hls.destroy();
  };

  const handleLiveCameraSwitch = async () => {
    if (liveCameraToggleSwitch) {
      clearLiveCamera();
    } else {
      liveCameraToggleSwitch = true;
      setLiveCamera();
    }
  };

  const initializeCameras = async () => {
    cameraViews = [];
    const cameraResponse = await getCameras(
      token,
      $selectedPark && $selectedPark.name ? {siteName: $selectedPark.name} : {}
    );
    if (cameraResponse.fulfilled) {
      cameraViews = cameraResponse.cameras.map(
        camera => (camera = {...camera, loading: true})
      );
    }
    setCameraWebSocket();

    window.addEventListener('clearSockets', () => {
      currentIndex = 0;
      selectedEvent = null;
      clearSockets(cameraWebSocket);
    });
    isMobileView = isMobile();
  };

  const handleEventVideoErrors = () => {
    vidElement &&
      vidElement.addEventListener('error', error => {
        dispatch('video-element-error', error);
      });
  };

  onMount(async () => {
    await initializeCameras();
  });

  $: handleIndexChange(currentIndex);
  $: handleEventChange(selectedEvent);
  $: handleEventChange(videoImageSwitch);
  $: initializeCameras($selectedPark);
  $: handleEventVideoErrors(vidElement);
</script>

<style>
  :global(#camera-options-container) {
    z-index: 1;
  }

  #camera-viewer {
    /* padding-left: var(--cox2m-spacing-2-units); */
  }
  .camera-viewer-content-container {
    border-radius: 1rem;
    background-color: var(--cox2m-clr-neutral-white);
    height: 100%;
    display: grid;
    grid-template-areas:
      'main-controllers'
      'media'
      'additional-controllers';
    grid-template-rows: auto 1fr auto;
  }
  #main-controllers-container {
    grid-area: main-controllers;
  }
  #carousel-container {
    grid-area: media;
    height: var(--height, max(50vh, 400px));
    max-width: calc(100%);
  }
  #additional-controllers-container {
    grid-area: additional-controllers;
  }
  .media-switch {
    z-index: 1;
    top: calc(var(--cox2m-spacing-2-units) + var(--cox2m-visual-corrector));
    left: calc(var(--cox2m-spacing-2-units) + var(--cox2m-visual-corrector));
  }

  img {
    object-fit: contain;
    background: var(--cox2m-clr-neutral-white);
  }

  .live-video::-webkit-media-controls-timeline,
  .live-video::-webkit-media-controls-current-time-display {
    display: none;
  }

  .live-video {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  @media only screen and (max-width: 767px) {
    .camera-viewer-content-container {
      grid-template-areas:
        'media media'
        'main-controllers additional-controllers';
      grid-template-rows: auto 1fr auto;
    }
    img {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
    #carousel-container {
      height: auto;
    }
    .live-video {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
  }
</style>

{#if cameraViews.length}
  <div class={customClass} id="camera-viewer">
    <div class="camera-viewer-content-container cox2m-shadow-1-dp">
      <div id="main-controllers-container">
        <MainControllers
          {cameraViewError}
          {selectedEvent}
          {cameraViews}
          {currentIndex}
          {liveCameraToggleSwitch}
          {enableLinkToLive}
          selectedSite={$selectedPark}
          on:toggleLiveCamera={() => handleLiveCameraSwitch()}
          on:cameraIndexChange={e => (currentIndex = e.detail)} />
      </div>
      <div id="carousel-container" style="--height: {height}">
        {#if cameraViews.length}
          <div
            id="camera-views-carousel"
            data-interval="false"
            class="carousel h-100 slide {dashboardViewer ? 'dashboard-carousel-responsive-handler' : ''}"
            data-ride="carousel"
            data-touch="false">
            {#if cameraViewError && !selectedEvent}
              <CameraViewsErrorMessage />
            {:else if selectedEvent}
              <div
                class="carousel-item h-100 active"
                id={`camera-view-slide-0`}>
                {#if window.location.hash.includes('events') && !selectedMediaLoading}
                  <span class="position-absolute media-switch">
                    <ToggleSwitch
                      id="media-switch"
                      bind:checked={videoImageSwitch} />
                  </span>
                {/if}

                {#if selectedMediaLoading}
                  <div
                    class="w-100 h-100 d-flex align-items-center
                    justify-content-center">
                    <LoadingSpinner />
                  </div>
                {:else if (window.location.hash.includes('/violations') && IS_VIDEO_ENABLED) || (eventVideo && videoImageSwitch && !mediaError)}
                  <video
                    id="selected-event-video-slide"
                    src={`data:video/webm;base64,${eventVideo}`}
                    type="video/webm"
                    class="w-100 h-100"
                    poster={selectedEvent.imageUrl}
                    controls
                    muted>
                    <track kind="captions" />
                  </video>
                {:else if eventImage}
                  {#if !shouldShowVideo}
                    <img
                      id="selected-event-image-slide"
                      class="d-block w-100 h-100"
                      src={mediaError ? eventImage : `data:image/jpeg;base64,${eventImage}`}
                      alt={selectedEvent.id} />
                  {:else}
                    <video
                      autoplay
                      controls
                      muted
                      id="selected-event-video-slide"
                      class="d-block w-100 h-100"
                      bind:this={vidElement}
                      poster={mediaError ? eventImage : `data:image/jpeg;base64,${eventImage}`}
                      src={selectedEvent.videoUrl}
                      alt={selectedEvent.id} />
                  {/if}
                {:else}
                  <img
                    id="selected-event-image-slide"
                    class="d-block w-100 h-100"
                    src={selectedEvent.imageUrl}
                    alt={selectedEvent.id} />
                {/if}

              </div>
            {:else if !camerasWithEvents.length && !allEventsSelected}
              <NoEventsAssociatedMessage />
            {:else}
              <MainCamerasCarousel
                {cameraViews}
                {selectedEvent}
                {liveCameraToggleSwitch} />
            {/if}

          </div>
        {/if}
      </div>
      {#if !liveCameraToggleSwitch}
        <div id="additional-controllers-container">
          <AdditionalControllers
            imageUrl={(selectedEvent ? selectedEvent.imageUrl : cameraViews[currentIndex] && cameraViews[currentIndex].imageUrl) || ''} />
        </div>
      {/if}
    </div>
  </div>
  {#if activateCameraCarousel}
    <CameraCarousel
      {allEventsSelected}
      {camerasWithEvents}
      items={cameraViews}
      {cameraViewError}
      bind:cameraIndex={currentIndex} />
  {/if}
{/if}
