<script>
  import ImageModal from './ImageModal.svelte';
  import ImageFrame from './ImageFrame.svelte';
  import {isMobile} from '@cox2m/city-services-ui-components/src/funcs';

  export let images = [];

  let selectedImage = null;
  let showImageModal = false;

  const handleImageSelection = ({image}) => {
    selectedImage = image;
    showImageModal = true;
  };
</script>

<style>
  #gallery-container {
    margin-top: var(--cox2m-spacing-12-units);
    padding: 0 var(--cox2m-spacing-2-units) 0 var(--cox2m-spacing-8-units);
  }

  @media only screen and (max-width: 1200px) {
    #gallery-container {
      margin-top: var(--cox2m-spacing-12-units);
      padding: 0;
    }
  }
</style>

<div class="w-100" id="gallery-container">
  <div
    class="row {isMobile() ? '' : 'pl-2 pr-3'}"
    data-testid="gallery-row-container">
    {#each images as image (image.id)}
      <ImageFrame
        className="col-xl-4"
        {image}
        on:imageSelected={e => handleImageSelection(e.detail)} />
    {/each}
  </div>
</div>

<ImageModal bind:show={showImageModal} image={selectedImage} />
