<script>
  import MapMarkersIndividualUpdate from '@cox2m/city-services-ui-components/src/components/maps/arcgis/MapMarkersIndividualUpdate.svelte';
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import SelectBadge from '@cox2m/city-services-ui-components/src/components/SelectBadge.svelte';
  import Map from '@cox2m/city-services-ui-components/src/components/maps/arcgis/Map.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';

  import {typeOptions} from './components/scripts/events-screen/events-screen-funcs';
  import {getCookieByName} from '@cox2m/city-services-ui-components/src/funcs';
  import {menuOpts, selectedPark, user} from '../stores';
  import {MarkersByEventType} from '../constants';
  import {onMount, onDestroy} from 'svelte';

  const MAXIMUM_NON_UPDATE_TIME_MS = 1000 * 60 * 1;
  const DELETION_CHECK_TIME_MS = 1100;
  const UPDATE_CHECK_TIME_MS = 1000;

  let objectsWebsocket;

  let isSiteLoading;
  let isSiteOnError;
  let isWsOnError = false;

  let detectableObjectsTemp = [];
  let detectableObjects = [];

  let updateObjectsInterval;
  let eraseObjectsInterval;

  let mapView;

  let selectedTypeFilter = null;

  const token = getCookieByName(`${'ENV'}_token`);

  const updateDetectableObjects = data => {
    const indexOfObject = detectableObjectsTemp.findIndex(
      detectableObject => detectableObject.id === data.id
    );
    if (indexOfObject === -1) {
      detectableObjectsTemp = [
        ...detectableObjectsTemp,
        {
          ...data,
          markerConfig: MarkersByEventType.find(
            markerByEventType => markerByEventType.for === data.type
          ),
          updatedAt: Date.parse(new Date())
        }
      ];
    } else {
      detectableObjectsTemp[indexOfObject] = {
        ...data,
        markerConfig: MarkersByEventType.find(
          markerByEventType => markerByEventType.for === data.type
        ),
        updatedAt: Date.parse(new Date())
      };
    }
  };

  const setObjectsWebSocket = () => {
    objectsWebsocket && objectsWebsocket.close();
    detectableObjects = [];
    detectableObjectsTemp = [];

    const webSocketHost = `wss://WEBSOCKET_HOST/ws/object?site-name=${
      $selectedPark.name
    }${selectedTypeFilter ? `&type=${selectedTypeFilter.value}` : ''}`;
    const authMessage = `{"auth" : "${token}"}`;

    objectsWebsocket = new WebSocket(webSocketHost);

    objectsWebsocket.onopen = () => {
      isWsOnError = false;
      objectsWebsocket.send(authMessage);
    };

    objectsWebsocket.onmessage = event => {
      let data = JSON.parse(event.data);
      updateDetectableObjects(data);
    };

    objectsWebsocket.onerror = () => {
      isWsOnError = true;
    };

    objectsWebsocket.onclose = () => {
      objectsWebsocket = null;
      setObjectsWebSocket();
    };
  };

  const handleMarkerClick = (data, event) => {
    if (mapView) {
      mapView.popup.autoOpenEnabled = false;
      mapView.popup.collapseEnabled = false;
      mapView.popup.open({
        title: `${data.type}`,
        location: event.mapPoint,
        content: `<b>id: ${data.id}</b><br><div><img src="${data.imageUrl}" alt="${data.id}" style="width: 100%;"></div>`
      });
      mapView.popup.visible = true;
    }
  };

  const changeWebsocketFilter = () => {
    objectsWebsocket && objectsWebsocket.close();
    detectableObjects = [];
    detectableObjectsTemp = [];
  };

  onMount(async () => {
    setObjectsWebSocket();
    eraseObjectsInterval = setInterval(() => {
      const parsedDateNow = Date.parse(new Date());
      detectableObjectsTemp = detectableObjectsTemp.filter(
        detectableObjectTemp =>
          parsedDateNow - detectableObjectTemp.updatedAt <
          MAXIMUM_NON_UPDATE_TIME_MS
      );
    }, DELETION_CHECK_TIME_MS);

    updateObjectsInterval = setInterval(() => {
      detectableObjects = detectableObjectsTemp;
    }, UPDATE_CHECK_TIME_MS);
  });

  onDestroy(() => {
    clearInterval(eraseObjectsInterval);
    eraseObjectsInterval = null;

    clearInterval(updateObjectsInterval);
    updateObjectsInterval = null;
  });

  $: changeWebsocketFilter(selectedTypeFilter);
</script>

<style>
  .type-filter-container {
    margin-bottom: var(--cox2m-spacing-8-units);
  }
  .filters-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .map-container {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
  }
</style>

<MainScreen
  title="Realtime Map"
  menuOpt={$menuOpts}
  showAlertsBanner={false}
  user={$user}
  appSlug="smart-security">
  <div slot="main-dashboard" class="w-100 h-100">

    {#if isSiteLoading}
      <div class="w-100 h-100">
        <LoadingSpinner />
      </div>
    {:else if !isSiteLoading && isSiteOnError}
      <div class="w-100 h-100 d-flex justify-content-center align-items-center">
        <h3>
          we are sorry we cannot fetch the information, please refresh the page
          or try again later
        </h3>
      </div>
    {:else if !isSiteLoading && !isSiteOnError}
      <div class="map-container">
        <div class="filters-container">
          <div class="type-filter-container">
            <SelectBadge
              bind:selectedOption={selectedTypeFilter}
              id="type-filter-badge"
              options={typeOptions}
              defaultLabel="Type"
              responsiveTitle="Filter by type" />
          </div>
        </div>
        <div class="direct-map-container h-100">
          <Map
            props={{zoom: 18, center: $selectedPark && $selectedPark.coordinates ? [ $selectedPark.coordinates[1], $selectedPark.coordinates[0]] : []}}
            basemap="satellite"
            bind:view={mapView}>
            <MapMarkersIndividualUpdate
              bind:collection={detectableObjects}
              onMarkerClick={handleMarkerClick}
              markerConfig={{type: 'simple-marker', path: 'M256 0c-88.366 0-160 71.634-160 160 0 160 160 352 160 352s160-192 160-352c0-88.366-71.635-160-160-160zM256 256c-53.020 0-96-42.98-96-96s42.98-96 96-96 96 42.98 96 96-42.98 96-96 96z', color: '#2e6ce4', outline: {color: [255, 255, 255, 0.7], width: 1}, size: 22}} />
          </Map>
        </div>
      </div>
    {/if}
  </div>
</MainScreen>
