<script>
  import Accordion from '@cox2m/city-services-ui-components/src/components/Accordion.svelte';
  import CheckBox from '@cox2m/city-services-ui-components/src/components/CheckBox.svelte';
  import TextInput from '@cox2m/city-services-ui-components/src/forms/TextInput.svelte';
  import Button from '@cox2m/city-services-ui-components/src/components/Button.svelte';
  import Badge from '@cox2m/city-services-ui-components/src/components/Badge.svelte';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
  import MobileModal from '@cox2m/city-services-ui-components/src/components/mobile/MobileModal/MobileModal.svelte';
  import {createEventDispatcher} from 'svelte';
  import {isMobile} from '@cox2m/city-services-ui-components/src/funcs';

  export let selectedZone;
  export let assets;
  export let zones;
  export let show;

  export let token;

  const dispatch = createEventDispatcher();

  let savedSelections = JSON.parse(
    localStorage.getItem('gallerySidebarSelections')
  );

  let clientId = token.substring(0, 8);

  const isSavedSelectionsObjectValid = () => {
    try {
      assets.forEach(asset => {
        if (!savedSelections.selections[asset.value]) {
          throw 'the selections contain an invalid field, probably there was a change in the structure, we are resetting the selections object';
        }
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  let selections =
    savedSelections &&
    isSavedSelectionsObjectValid() &&
    savedSelections.clientId === clientId
      ? savedSelections.selections
      : {};

  let filteredAssets = assets;
  let selectedBadges = [];

  let hideAllSelectionCheckbox = false;

  let assetFilterString = '';

  const buildSelections = ({resetSelections = false}) => {
    if (Object.keys(selections).length === 0 || resetSelections) {
      assets.forEach(asset => {
        let subAssets = {};
        asset.assets.forEach(subasset => (subAssets[subasset] = false));
        selections[asset.value] = {
          allSelected: false,
          assets: subAssets
        };
      });
    }
  };

  const handleAllSubAssetsSelection = key => {
    selections[key].allSelected = !selections[key].allSelected;

    for (const assets in selections[key].assets) {
      selections[key].assets[assets] = selections[key].allSelected;
    }
  };

  const checkAllSubAssetsSelected = (value, key) => {
    if (value === false) {
      selections[key].allSelected = false;
    } else {
      const subAssetsList = Object.keys(selections[key].assets);
      if (
        subAssetsList.every(
          subasset => selections[key].assets[subasset] === true
        )
      ) {
        selections[key].allSelected = true;
      }
    }
  };

  const getBadgesValue = (assetCategory, badgeAssets, childAsset = null) => {
    let assetValues = [];

    if (!childAsset) {
      for (const subAsset in badgeAssets) {
        if (badgeAssets[subAsset]) {
          assetValues.push({
            value: `${assetCategory}-${subAsset}`,
            assetCategory,
            subAsset
          });
        }
      }
    } else {
      assetValues.push({
        value: `${assetCategory}-${childAsset}`,
        assetCategory,
        subAsset: childAsset
      });
    }
    return assetValues;
  };

  const buildBadgesArray = () => {
    let tempBadges = [];

    if (selectedZone !== 'All') {
      tempBadges.push({
        label: `zone: ${selectedZone}`,
        zoneBadge: true,
        values: []
      });
    }

    for (const assetCategory in selections) {
      if (selections[assetCategory].allSelected) {
        tempBadges.push({
          label: `all ${assetCategory}`,
          values: getBadgesValue(
            assetCategory,
            selections[assetCategory].assets
          ),
          zoneBadge: false
        });
      } else {
        for (const subAsset in selections[assetCategory].assets) {
          if (selections[assetCategory].assets[subAsset]) {
            tempBadges.push({
              label: `${assetCategory} ${subAsset}`,
              values: getBadgesValue(
                assetCategory,
                selections[assetCategory].assets,
                subAsset
              ),
              zoneBadge: false
            });
          }
        }
      }
    }

    //prevent badges popping up strangely for the user
    selectedBadges = tempBadges;
  };

  const removeBadge = async badgeToRemove => {
    if (!badgeToRemove.zoneBadge) {
      selections[badgeToRemove.values[0].assetCategory].allSelected = false;
    } else {
      dispatch('resetSelectedZone');
    }

    selectedBadges = selectedBadges.filter(selectedBadge => {
      selectedBadge.label !== badgeToRemove.label;
    });

    !badgeToRemove.zoneBadge &&
      badgeToRemove.values.forEach(badgeValue => {
        selections[badgeValue.assetCategory].assets[
          badgeValue.subAsset
        ] = false;
      });
  };

  const handleClearAllFilters = () => {
    buildSelections({resetSelections: true});

    if (selectedBadges.length === 1 && selectedBadges[0].zoneBadge) {
      dispatch('resetSelectedZone');
    }
  };

  const saveSelections = () => {
    localStorage.setItem(
      'gallerySidebarSelections',
      JSON.stringify({
        clientId,
        selections
      })
    );
  };

  let timer;
  const debounceFilterAssets = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      filterAssets();
    }, 500);
  };

  const filterAssets = () => {
    if (assetFilterString === '') {
      filteredAssets = assets;
      hideAllSelectionCheckbox = false;
    } else {
      hideAllSelectionCheckbox = true;
      filteredAssets = assets;

      filteredAssets = filteredAssets
        .map(asset => {
          let filteredSubAssets = asset.assets.filter(subAsset =>
            `${asset.label} ${subAsset}`
              .toLowerCase()
              .includes(assetFilterString.toLowerCase())
          );

          if (filteredSubAssets.length) {
            return {...asset, assets: filteredSubAssets};
          }
        })
        .filter(filteredAsset => filteredAsset);
    }
  };

  const dispatchFilterSelection = () => {
    let selectionString = '';

    selectedBadges.forEach(selectedBadge => {
      if (!selectedBadge.zoneBadge) {
        selectedBadge.values.forEach(badgeValue => {
          selectionString += `${badgeValue.assetCategory} ${badgeValue.subAsset},`;
        });
      }
    });

    selectionString.length &&
      (selectionString = selectionString.slice(0, selectionString.length - 1));

    dispatch('applyFiltersSelection', selectionString);
    show = false;
  };

  $: buildBadgesArray(selections, selectedZone);
  $: buildSelections(zones, assets, selectedZone);

  $: saveSelections(selections);
</script>

<style>
  .header-title {
    font-size: 1.25rem;
  }

  .clear-all {
    color: var(--cox2m-clr-brand-600);
    font-size: var(--cox2m-fs-1000);
    text-decoration: underline;
  }

  .sidebar-header-content {
    display: grid;
    grid-template-columns: auto auto;
    grid-auto-columns: 1fr;
    padding: var(--cox2m-spacing-6-units) var(--cox2m-spacing-6-units)
      var(--cox2m-spacing-4-units) var(--cox2m-spacing-6-units);
    border-bottom: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-400);
  }

  #filters-sidebar-content-container {
    display: grid;
    grid-template-rows: auto minmax(auto, 200px) 1fr 150px;
    /* max-height: calc(100vh - var(--header-height) - 180px); */
    overflow-y: auto;
  }

  .sidebar-applied-filters-section {
    min-height: 160px;
  }

  .sidebar-filters-accordions-section {
    overflow-y: auto;
  }

  .sidebar-button-content {
    padding-top: var(--cox2m-spacing-4-units);
    padding-bottom: var(--cox2m-spacing-9-units);
  }

  .sidebar-button-section {
    border-top: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-400);
  }

  .badges-container {
    padding: var(--cox2m-spacing-5-units);
    max-height: 150px;
    overflow-y: auto;
    gap: 0 var(--cox2m-spacing-2-units);
  }

  .applied-filters-title {
    font-size: var(--cox2m-fs-900);
    padding: var(--cox2m-spacing-6-units) var(--cox2m-spacing-6-units) 0
      var(--cox2m-spacing-6-units);
    margin-bottom: 0;
  }

  #assets-search-container {
    grid-column: span 2;
  }

  :global(#assets-search-container .form-control) {
    margin-top: var(--cox2m-spacing-6-units);
    height: var(--cox2m-spacing-10-units);
  }

  @media only screen and (max-width: 1200px) {
    .direct-options-container {
      overflow-y: auto;
      height: 100%;
      margin-top: var(--cox2m-spacing-5-units);
      max-height: max(30vh, 300px);
    }

    .buttons-container {
      box-shadow: var(--cox2m-shadow-1-dp-dir) var(--cox2m-shadow-1-dp-clr);
      background-color: var(--cox2m-clr-neutral-white);
      padding: var(--cox2m-spacing-4-units);
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .options-container {
      /* 180 pixels is an estimate of the space occupied by the bottom controllers + the search bar + the padding + a safe space for the options to grow */
      max-height: calc(100vh - var(--header-height) - 160px) !important;
      overflow-y: auto;
      margin-top: var(--cox2m-spacing-6-units);
    }
  }
</style>

<div
  class="w-100 h-100"
  id="filters-sidebar-content-container"
  data-testid="filters-sidebar-content-container">

  {#if isMobile()}
    <MobileModal
      viewTitle="Filters"
      leftIcon={'chevron-left'}
      leftIconClick={() => {
        show = false;
      }}
      open={true}>
      <TextInput
        name="assets-search"
        icon="search"
        onChange={debounceFilterAssets}
        height="var(--cox2m-spacing-10-units)"
        iconColor="var(--cox2m-clr-brand-600)"
        backgroundColor="var(--cox2m-clr-brand-200)"
        placeholder="Search assets"
        id="assets-search"
        bind:value={assetFilterString}
        borderRadius="60px"
        customContainerClass="border-0" />

      <div class="options-container">
        <!--id={`${id}-options-container`}-->

        <section class="sidebar-filters-accordions-section">
          <div class="sidebar-filters-content justify-content-between w-100">
            {#each filteredAssets as assetCategory}
              <Accordion id="accordion-{assetCategory.value}">
                <div class="d-flex align-items-center" slot="header-component">
                  <span class={hideAllSelectionCheckbox ? 'invisible' : ''}>
                    <CheckBox
                      onClick={() => handleAllSubAssetsSelection(assetCategory.value)}
                      id="{assetCategory.value}-category-checkbox"
                      bind:checked={selections[assetCategory.value].allSelected} />
                  </span>
                  <span class="ml-2 pr-1 font-weight-bold">
                    {assetCategory.label} {`(${assetCategory.assets.length})`}
                  </span>
                </div>
                <div
                  class="d-flex flex-column align-items-center"
                  slot="body-component">
                  {#each assetCategory.assets as childAsset (childAsset)}
                    <div class="col px-0 d-flex align-items-center">
                      <CheckBox
                        onChange={e => checkAllSubAssetsSelected(e.currentTarget.checked, assetCategory.value)}
                        id="{assetCategory.value}-{childAsset}-checkbox"
                        bind:checked={selections[assetCategory.value].assets[childAsset]} />
                      <span class="ml-2 pr-1 font-weight-medium">
                        {assetCategory.label} {childAsset}
                      </span>
                    </div>
                  {/each}
                </div>
              </Accordion>
            {/each}
          </div>
        </section>
      </div>
      <div class="buttons-container d-flex justify-content-end">
        <Button
          customClass="font-weight-bold"
          text="Clear all"
          width="fit-content"
          height="var(--cox2m-spacing-8-units)"
          color="var(--cox2m-clr-brand-600)"
          borderColor="none"
          backgroundColor="transparent"
          padding="0 var(--cox2m-spacing-4-units)"
          on:click={() => {
            handleClearAllFilters();
          }} />
        <Button
          height="var(--cox2m-spacing-10-units)"
          customClass="font-weight-bold"
          color="var(--cox2m-clr-neutral-white)"
          backgroundColor="var(--cox2m-clr-brand-600)"
          width="fit-content"
          text="Apply Filters"
          id="filter-sidebar-apply-button"
          on:click={() => dispatchFilterSelection()} />

      </div>
    </MobileModal>
  {:else}
    <section class="sidebar-header-section">
      <div
        class="sidebar-header-content align-items-center justify-content-between
        w-100">
        <span class="header-title font-weight-bold">Filters</span>
        <div class="d-flex align-items-center justify-content-end">
          <span
            class="clear-all font-weight-bold text"
            style="cursor: pointer"
            id="clear-all-tag"
            data-testid="clear-all-tag"
            on:click={() => handleClearAllFilters()}>
            Clear all
          </span>
          <span
            class="ml-4 d-flex align-items-center"
            style="cursor: pointer"
            on:click={() => (show = false)}>
            <Icon
              icon="cross"
              size="var(--cox2m-spacing-5-units)"
              color="var(--cox2m-clr-neutral-black)" />
          </span>
        </div>
        <div id="assets-search-container" data-testid="assets-search-container">
          <TextInput
            id="assets-search"
            onChange={debounceFilterAssets}
            bind:value={assetFilterString}
            name="assets-search"
            icon="search"
            iconColor="var(--cox2m-clr-brand-600)"
            placeholder="Search assets"
            borderRadius="60px" />
        </div>
      </div>
    </section>

    <section class="sidebar-applied-filters-section">
      <div
        class="sidebar-applied-filters-content justify-content-between w-100">
        <h3 class="font-weight-bold applied-filters-title">Filters Applied</h3>
        <div
          class="badges-container d-flex flex-wrap"
          id="badges-container"
          data-testid="badges-container">
          {#each selectedBadges as selectedBadge (selectedBadge.label)}
            <Badge
              onIconClick={() => removeBadge(selectedBadge)}
              text={selectedBadge.label}
              customBadgeClass="mb-2"
              padding="2px 8px 2px 8px"
              textColor="var(--cox2m-clr-neutral-black)"
              borderColor="var(--cox2m-clr-brand-400)"
              iconColor="var(--cox2m-clr-neutral-black)"
              backgroundColor="var(--cox2m-clr-neutral-white)"
              customIconClass="pl-1 pr-0"
              iconSize="var(--cox2m-spacing-5-units)"
              id={selectedBadge.label
                .replace(' ', '-')
                .replace(':', '') + '-badge'}
              icon="cross" />
          {/each}
        </div>
      </div>
    </section>

    <section class="sidebar-filters-accordions-section">
      <div class="sidebar-filters-content justify-content-between w-100">
        {#each filteredAssets as assetCategory}
          <Accordion id="accordion-{assetCategory.value}">
            <div class="d-flex align-items-center" slot="header-component">
              <span class={hideAllSelectionCheckbox ? 'invisible' : ''}>
                <CheckBox
                  onClick={() => handleAllSubAssetsSelection(assetCategory.value)}
                  id="{assetCategory.value}-category-checkbox"
                  bind:checked={selections[assetCategory.value].allSelected} />
              </span>
              <span class="ml-2 pr-1 font-weight-bold">
                {assetCategory.label} {`(${assetCategory.assets.length})`}
              </span>
            </div>

            <div
              class="d-flex flex-column align-items-center"
              slot="body-component">
              {#each assetCategory.assets as childAsset (childAsset)}
                <div class="col px-0 d-flex align-items-center">
                  <CheckBox
                    onChange={e => checkAllSubAssetsSelected(e.currentTarget.checked, assetCategory.value)}
                    id="{assetCategory.value}-{childAsset}-checkbox"
                    bind:checked={selections[assetCategory.value].assets[childAsset]} />
                  <span class="ml-2 pr-1 font-weight-medium">
                    {assetCategory.label} {childAsset}
                  </span>
                </div>
              {/each}
            </div>
          </Accordion>
        {/each}
      </div>
    </section>

    <section class="sidebar-button-section">
      <div
        class="sidebar-button-content d-flex flex-column justify-content-around
        align-items-center w-100 h-100">
        <Button
          height="var(--cox2m-spacing-10-units)"
          customClass="font-weight-bold"
          color="var(--cox2m-clr-neutral-white)"
          backgroundColor="var(--cox2m-clr-brand-600)"
          width="calc(100% - 50px)"
          text="Apply Filters"
          id="filter-sidebar-apply-button"
          on:click={() => dispatchFilterSelection()} />
        <Button
          height="var(--cox2m-spacing-10-units)"
          customClass="font-weight-bold"
          color="var(--cox2m-clr-brand-600)"
          borderColor="var(--cox2m-clr-neutral-400)"
          backgroundColor="var(--cox2m-clr-neutral-white)"
          width="calc(100% - 50px)"
          text="Cancel"
          id="filter-sidebar-cancel-button"
          on:click={() => (show = false)} />
      </div>
    </section>
  {/if}

</div>
