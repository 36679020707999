<script>
  import { onDestroy } from "svelte";

  export let image = null;
  export let imgUrl = '';

  let glass, glassWidth, glassHeight, pos, x, y;
  const BW = 3;
  const ZOOM = 3;

  const magnify = () => {
    /*create magnifier glass:*/
    if (image) {
      glass && glass.remove()
      glass = document.createElement('DIV');
      glass.setAttribute('class', 'img-magnifier-glass');
      /*insert magnifier glass:*/
      image.parentElement.insertBefore(glass, image);
      /*set background properties for the magnifier glass:*/
      glass.style.backgroundImage = "url('" + imgUrl + "')";
      glass.style.backgroundRepeat = 'no-repeat';
      glass.style.backgroundSize =
        image.width * ZOOM + 'px ' + image.height * ZOOM + 'px';
      glassWidth = glass.offsetWidth / 2;
      glassHeight = glass.offsetHeight / 2;
      /*execute a function when someone moves the magnifier glass over the image:*/
      glass.addEventListener('mousemove', moveMagnifier);
      image.addEventListener('mousemove', moveMagnifier);
      /*and also for touch screens:*/
      glass.addEventListener('touchmove', moveMagnifier);
      image.addEventListener('touchmove', moveMagnifier);
    }
  };

  const moveMagnifier = e => {
    /*prevent any other actions that may occur when moving over the image*/
    e.preventDefault();
    /*get the cursor's x and y positions:*/
    pos = getCursorPos(e);
    x = pos.x;
    y = pos.y;
    /*prevent the magnifier glass from being positioned outside the image:*/
    if (x > image.width - glassWidth / ZOOM) {
      x = image.width - glassWidth / ZOOM;
    }
    if (x < glassWidth / ZOOM) {
      x = glassWidth / ZOOM;
    }
    if (y > image.height - glassHeight / ZOOM) {
      y = image.height - glassHeight / ZOOM;
    }
    if (y < glassHeight / ZOOM) {
      y = glassHeight / ZOOM;
    }
    /*set the position of the magnifier glass:*/
    glass.style.left = e.offsetX - glassWidth + 'px';
    glass.style.top = e.offsetY - glassHeight + 'px';
    /*display what the magnifier glass "sees":*/
    glass.style.backgroundPosition =
      '-' + (x * ZOOM - glassWidth + BW) + 'px -' + (y * ZOOM - glassHeight + BW) + 'px';
  };

  const getCursorPos = e => {
    const imageRect = image.getBoundingClientRect();
    /*get the x and y positions of the image:*/
    /*calculate the cursor's x and y coordinates, relative to the image:*/
    x = e.pageX - imageRect.left;
    y = e.pageY - imageRect.top;
    /*consider any page scrolling:*/
    x = x - window.scrollX;
    y = y - window.scrollY;
    return {x: x, y: y};
  };

  onDestroy(() => {
    glass.remove();
  })

  $: magnify(image, imgUrl);
</script>

<style>
  :global(.img-magnifier-glass) {
    position: absolute;
    border: 3px solid #000;
    border-radius: 50%;
    cursor: crosshair;
    /*Set the size of the magnifier glass:*/
    width: 100px;
    height: 100px;
    pointer-events: none;
  }
</style>
