<script>
  import clickOutside from '@cox2m/city-services-ui-components/src/svelte-actions/clickOutside';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';

  import {createEventDispatcher} from 'svelte';

  export let selectedCameraIndex = 0;
  export let cameraViews = [];

  const dispatch = createEventDispatcher();

  let expanded = false;

  const dispatchIndexChange = () => {
    dispatch('cameraIndexChange', selectedCameraIndex);
  };

  $: dispatchIndexChange(selectedCameraIndex);
</script>

<style>
  #camera-selector {
    min-width: 160px;
    height: var(--cox2m-spacing-6-units);
    border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-500);
    border-radius: calc(var(--cox2m-spacing-1-units) + var(--cox2m-visual-corrector));
  }
  #active-camera-name {
    margin-right: var(--cox2m-spacing-8-units);
    color: var(--cox2m-clr-neutral-black);
    font-weight: var(--cox2m-fw-medium);
  }
  #camera-options-container {
    background-color: var(--cox2m-clr-neutral-white);
    border-radius: 1rem;
    height: 200px;
    overflow-y: auto;
    top: calc(var(--cox2m-spacing-7-units) + var(--cox2m-visual-corrector));
  }
  .camera-option{
    cursor: pointer;
    font-weight: var(--cox2m-fw-medium);
  }
  .camera-option:hover, .camera-option.selected{
    background-color: var(--cox2m-clr-brand-200);
    color: var(--cox2m-clr-brand-600);
    font-weight: var(--cox2m-fw-bold);
  }

  @media only screen and (max-width: 1440px){
    .camera-option, #active-camera-name{
      font-size: var(--cox2m-fs-1000);
    }
  }
</style>

{#if cameraViews[0].name}
  <div class="position-relative d-inline" id="camera-selector-container">
    <span
      id="camera-selector"
      class="d-flex align-items-center px-2 position-relative"
      on:click={() => (expanded = !expanded)}
      use:clickOutside={() => {
        expanded = false;
      }}>

      <span id="active-camera-name">
        {cameraViews[selectedCameraIndex || 0].name}
      </span>
      <span class="m-0 p-0 d-flex align-items-center" style="cursor: pointer">
        <Icon
        icon={expanded ? 'chevron-up' : 'chevron-down'}
        size="var(--cox2m-spacing-5-units)"
        color="var(--cox2m-clr-brand-600)"
        id="camera-selector-expand-button" />
      </span>
    </span>

    {#if expanded}
      <div id="camera-options-container" class="position-absolute w-100 py-2 cox2m-shadow-1-dp">
        {#each cameraViews as view, i}
          <p
            class="w-100 camera-option p-2 m-0"
            class:selected={i === selectedCameraIndex}
            id={`${view.id}-camera-option`}
            on:click={() => (selectedCameraIndex = i)}>
            {view.name}
          </p>
        {/each}
      </div>
    {/if}
  </div>
{/if}
