<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import SiteIndicator from './components/shared/SiteIndicator.svelte';
  import CameraGrid from './components/CameraGrid.svelte';

  import {menuOpts, user} from '../stores';
  import {getCookieByName} from "@cox2m/city-services-ui-components/src/funcs";

  export let params = {};

  const token = getCookieByName(`${'ENV'}_token`);
  let eventsFetchingError = false;

  let isSiteLoading;
  let isSiteOnError;
</script>

<style>
  .dashboard-component-container {
    display: grid;
    grid-template-rows: auto 1fr;
  }


  :global(#cardView-report-container iframe) {
    border: none;
  }

  .camera-viewer-container{
    padding-block: var(--cox2m-spacing-3-units);
  }

  @media only screen and (min-width: 1200px) {
    .camera-viewer-container {
      display: flex;
      flex-direction: row-reverse;
      background-color: var(--cox2m-clr-neutral-white);
      border-radius: 0.7rem;
      max-height: calc(100vh - 190px);
      min-height: 250px;
    }
  }

  @media only screen and (max-width: 1199px) {
    .camera-viewer-container {
      display: grid;
    }
  }
</style>


<MainScreen title="Live View" menuOpt={$menuOpts} user={$user} showAlertsBanner={false} appSlug="smart-security">
  <div class="dashboard-component-container w-100 h-100 pl-50 h-100" slot="main-dashboard">
    <div class="w-100">
      <SiteIndicator title="Live View" bind:isLoading={isSiteLoading} bind:isOnError={isSiteOnError}/>
    </div>

    {#if isSiteLoading}
      <div class="w-100 h-100 d-flex align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    {:else}
      {#if eventsFetchingError || isSiteOnError}
        <div
          class="error-container h-100 d-flex justify-content-center
          align-items-center text-center">
          <h2 id="dashboard-page-error-message">
            We are sorry we couldn't fetch the page information, please reload the
            page or try again later
          </h2>
        </div>
      {:else}
        <div class="w-100 m-0 p-0">
          <div class="camera-viewer-container">
            <CameraGrid
              customClass="w-100"
              dashboardViewer
              {token}
              selectedCamera={params.id}
              activateCameraCarousel />
          </div>
        </div>
      {/if}
    {/if}

  </div>
</MainScreen>
