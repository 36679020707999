import Api from 'smart_security_api';

import {setErrorEventData} from '@cox2m/city-services-ui-components/src/funcs';

const {ApiClient, EventApi, CameraApi, ParkingApi, NotificationsApi, VehicleApi, SiteApi, PeopleApi} = Api;
/**
 * Initialize the API client
 */
const initApiClient = () => {
  const apiClient = new ApiClient();
  apiClient.basePath = 'API_HOST';
  apiClient.defaultHeaders = {
    'Access-Control-Allow-Origin': '*'
  };
  return apiClient;
};

const apiClient = initApiClient();
const eventApi = new EventApi(apiClient);
const cameraApi = new CameraApi(apiClient);
const parkingApi = new ParkingApi(apiClient);
const notificationsApi = new NotificationsApi(apiClient);
const vehicleApi = new VehicleApi(apiClient);
const siteApi = new SiteApi(apiClient);
const peopleApi = new PeopleApi(apiClient);

/**
 * Set token to use in security scheme
 * @param token
 */
const setToken = token => {
  apiClient.authentications['API_TOKEN'].apiKey = token;
};

/**
 * Retrieve violation events
 * @param {string} token
 * @param {string} filter
 * @param {object} opts
 *
 */
export const getViolationsUsingFilter = async (token, opts = {}) => {
  try {
    setToken(token);
    const response = await eventApi.getViolationsUsingFilter(opts.siteName, opts);
    return {
      fulfilled: true,
      events: response.events
    };
  } catch (error) {
    setErrorEventData(
      window.dispatchEvent,
      error,
      'get-violations-using-filter'
    );
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Retrieve events
 * @param {string} token
 * @param {object} opts
 *
 */
export const getEventsUsingFilter = async (token, opts = {}) => {
  try {
    setToken(token);
    const response = await eventApi.getEventsUsingFilter(opts.siteName, opts);
    return {
      fulfilled: true,
      events: response.events
    };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-events-using-filter');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Dismiss an event/alert
 * @param {string} token
 * @param {string} eventId
 *
 */
export const dismissEvent = async (token , eventId) => {
  try {
    setToken(token);
    await Promise.all([
      eventApi.putEventViolationWithViolationeventidDismiss(eventId),
      eventApi.putEventViolationWithViolationeventidReport(eventId)
    ]);

    return {
      fulfilled: true
    };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'delete-event');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Report an event/alert
 * @param {string} token
 * @param {string} eventId
 *
 */
export const reportEvent = async (token , eventId) => {
  try {
    setToken(token);
    await eventApi.putEventViolationWithViolationeventidReport(eventId);
    return {
      fulfilled: true
    };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'report-event');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Share a particular event given its id
 * @param {string} token
 * @param {object} opts (securityEventDto)
 *
 */
export const shareAlert = async (token, opts) => {
  try {
    setToken(token);
    await notificationsApi.postNotificationsSendEmail(opts);
    return {
      fulfilled: true
    };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'share-alert');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Send an sms related to an event given its id
 * @param {string} token
 * @param {object} opts (securityEventDto)
 *
 */
export const sendSms = async (token, opts) => {
  try {
    setToken(token);
    await notificationsApi.postSmartSecuritySendSms(opts);
    return {
      fulfilled: true
    };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'send-sms');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Get the cameras information setup
 * @param {string} token
 */
export const getCameras = async (token, opts = {}) => {
  try {
    setToken(token);
    const response = await cameraApi.getCameras(opts);
    return {
      fulfilled: true,
      cameras: response.cameras
    };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-cameras');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Get the occupied spots in the parking lot
 * @param {string} token
 */
export const getParkingSpacesOccupied = async (token, opts = {}) => {
  try {
    setToken(token);
    const response = await parkingApi.getParkingSpacesOccupied(opts);

    return {
      fulfilled: true,
      spaces: response
    };
  } catch (error) {
    setErrorEventData(
      window.dispatchEvent,
      error,
      'get-parking-spaces-occupied'
    );
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Get the image for the given event
 * @param {string} token
 * @param {string} eventId
 */
export const getEventImage = async (token, eventId) => {
  try {
    setToken(token);
    return await eventApi.getImageEventId(eventId);
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-event-image');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Retrieve all the zones of the park
 * @param {string} token
 * @param {object} opts
 */
export const getZones = async (token, opts = {}) => {
  try {
    setToken(token);
    const response = await eventApi.getZones(opts.siteName, opts);
    return {fulfilled: true, zones: ['All', ...response]};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-zones');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Retrieve all the asset types of the park
 * @param {string} token
 * @param {object} opts
 */
export const getAssetTypes = async (token, opts = {}) => {
  try {
    setToken(token);
    const response = await eventApi.getAssetTypes(opts.siteName, opts);
    return {fulfilled: true, assets: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-zones');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Retrieve events
 * @param {string} token
 * @param {object} opts
 *
 */
export const getEventsGalleryUsingFilter = async (token, opts = {}) => {
  try {
    setToken(token);
    const response = await eventApi.getEventsGalleryUsingFilter(opts.siteName, opts);
    return {
      fulfilled: true,
      gallery: response.gallery,
      paging: response.paging
    };
  } catch (error) {
    setErrorEventData(
      window.dispatchEvent,
      error,
      'get-events-gallery-using-filter'
    );
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Retrieve vehicles using filter
 * @param {string} token
 * @param {object} opts
 *
 */
export const getVehiclesUsingFilter = async (token, opts = {}) => {
  try {
    setToken(token);
    const response = await vehicleApi.getVehiclesUsingFilter(opts.siteName, opts);
    return {
      fulfilled: true,
      vehicles: response.vehicles,
      paging: response.paging
    };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-vehicles-using-filter');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Export vehicles using filter in CSV format
 * @param {string} token
 * @param {object} opts
 *
 */
export const exportVehiclesUsingFilter = async (token, opts = {}) => {
  try {
    setToken(token);
    opts = opts || {};
    let queryParams = {
      'limit': opts['limit'],
      'offset': opts['offset'],
      'since': opts['since'],
      'until': opts['until'],
      'vehicleType': opts['vehicleType'],
      'color': opts['color'],
      'licensePlate': opts['licensePlate'],
      'region': opts['region'],
      'siteName': opts['siteName']
    };
    const authNames = ['API_TOKEN'];

    const response = await apiClient.callApi(
      '/vehicle', 'GET',
      {}, queryParams, {}, {}, null,
      authNames, [], ['text/csv'], String, null
    );
    return {
      fulfilled: true,
      csv: response.data
    };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-vehicles-using-filter');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * start a camera to be able to use its streaming functions
 * @param {string} token
 * @param {string} id
 *
 */
 export const getCameraStartWithId = async (token, id) => {
  try {
    setToken(token);
    await cameraApi.getCameraStartWithId(id);
    return {
      fulfilled: true,
    };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-camera-start-with-id');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * stop the streaming of a camera
 * @param {string} token
 * @param {string} id
 *
 */
 export const getCameraStopWithId = async (token, id) => {
  try {
    setToken(token);
    await cameraApi.getCameraStopWithId(id);
    return {
      fulfilled: true,
    };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-camera-stop-with-id');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Get a site using its name
 * @param {string} token
 * @param {string} name
 *
 */
export const getSiteByName = async (token, name) => {
  try {
    setToken(token);
    const response = await siteApi.getSiteName(name);
    return {
      fulfilled: true,
      site: response
    };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-site-name');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Retrieve all the public safety parks overnight violations since last 24 hours
 * @param {string} token
 * @param {string} siteName
 */
export const getParksOvernightViolations = async (token, siteName) => {
  try {
    setToken(token);
    const response = await peopleApi.getPublicSafetyParksOvernightViolations(siteName);
    return {fulfilled: true, data: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-public-safety-parks-overnight-violations');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Retrieve all the public safety parks people information since last 24 hours
 * @param {string} token
 */
export const getParksPeople = async (token, siteName) => {
  try {
    setToken(token);
    const response = await peopleApi.getPublicSafetyParksPeople(siteName);
    return {fulfilled: true, data: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-public-safety-parks-people');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Retrieve all the public safety site people/vehicles information since last 24 hours
 * @param {string} token
 */
export const getSitePeopleVehicles = async (token, siteName) => {
  try {
    setToken(token);
    const response = await peopleApi.getPublicSafetySitePeopleVehicles(siteName);
    return {fulfilled: true, data: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-public-safety-site-people-vehicles');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Retrieve the minimum event date
 * @param {string} token
 */
export const getMinEventDate = async (token, opts = {}) => {
  try {
    setToken(token);
    const response = await eventApi.getMinEventDate(opts.siteName);
    return {fulfilled: true, data: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-min-event-date');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Get the cameras information setup with pagination
 * @param {string} token
 * @param {object} opts
 */
export const getCamerasPagination = async (token, opts) => {
  try {
    setToken(token);
    const response = await cameraApi.getCameras(opts);
    return {
      fulfilled: true,
      collection: response.cameras,
      paging: response.paging
    };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-cameras');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Get park operation settings
 * @param {string} token
 * @param {object} opts
 */
 export const getParkOperationSettings = async (token, opts = {}) => {
  try {
    setToken(token);
    const response = await siteApi.getParkOperationSettings(opts.siteName, opts);
    return {
      fulfilled: true,
      data: response
    };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-park-operation-settings');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Put park operation settings
 * @param {string} token
 * @param {object} opts (parkOperationSettingsDto Array)
 *
 */
 export const putParkOperationSettings = async (token , opts) => {
  try {
    setToken(token);
    await siteApi.putParkOperationSettings({parkOperationSettingsDto: opts});
    return {
      fulfilled: true
    };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'put-park-operation-settings');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Start the live stream service for the current site cameras
 * @param token
 * @param siteName
 * @returns {Promise<{fulfilled: boolean}>}
 */
export const startLiveStreamSite = async (axios, token, siteName) => {
  try {
    await axios.post(
      `STREAMING_HOST/stream/start/${siteName}`,
      {},
      {
        headers: {
          'X-Token': token
        }
      }
    );
    return {fulfilled: true};
  } catch (error) {
    return {fulfilled: false};
  }
};


/**
 * get all sites (parks)
 * @param {string} token
 */
export const getAllSites = async (token) => {
  try {
    setToken(token);
    const response = await siteApi.getAllSites();
    return {
      fulfilled: true,
      sites: response.sites,
      paging: response.paging
    };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-all-sites');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Retrieve events
 * @param {string} token
 * @param {object} opts
 *
 */
export const getEventsCoordinatesUsingFilter = async (token, opts = {}) => {
  try {
    setToken(token);
    const response = await eventApi.getEventsCoordinatesUsingFilter(opts.siteName, opts);
    return {
      fulfilled: true,
      events: response.events
    };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-events-coordinates-using-filter');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Retrieve events
 * @param {string} token
 * @param {object} opts
 *
 */
export const getObjectsCoordinatesUsingFilter = async (token, opts = {}) => {
  try {
    setToken(token);
    const response = await eventApi.getObjectsCoordinatesUsingFilter(opts.siteName, opts);
    return {
      fulfilled: true,
      events: response.events
    };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-objects-coordinates-using-filter');
  }
}

export const getEventId = async (token, opts = {}) => {
  try {
    setToken(token);
    const response = await eventApi.getEventId(opts.searchedEventId);

    return {
      fulfilled: true,
      searchedEvent: response
    };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-event-id');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Retrieve events
 * @param {string} token
 * @param {object} opts
 */
export const getEventsCoordinatesGroupedUsingFilter = async ({token, type, objectId, parentId}) => {
  try {
    setToken(token);
    const response = await eventApi.getEventsCoordinatesGroupedUsingFilter(type, objectId);

    return {
      fulfilled: true,
      events: response.map(event => ({...event, parentId}))
    };
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-events-coordinates-grouped-using-filter');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Retrieve detectable object locations
 * @param {string} token
 * @param {string} siteName
 * @param {string} since
 * @param {string} until
 * @param {string} cameraName
 * @param {string} type
 */
export const getDetectableObjectLocations = async ({token, since = null, until = null, siteName, cameraName = null, type = null}) => {
  try {
    setToken(token);
    const response = await eventApi.getDetectableObjectLocations(siteName, {since, until, cameraName, type});
    return {
      fulfilled: true,
      objects: response
    };

  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-pedestrians-locations');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Updates or inserts homography points
 * @param {String} token
 * @param {String} cameraId
 * @param {String} presetName
 * @param {object} opts
 */
export const upsertHomographyPoints = async (token, cameraId, presetName, opts = {}) => {
  try {
    setToken(token);
    const response = await cameraApi.postCameraPresetHomographyPoints(cameraId, presetName, opts.homographyPoints);
    return {fulfilled: true, assets: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'upsert-homography-points');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};

/**
 * Updates or inserts homography points
 * @param {String} token
 * @param {String} cameraId
 * @param {object} opts
 */
export const getCameraPresets = async (token, cameraId, opts = {}) => {
  try {
    setToken(token);
    const response = await cameraApi.getCameraPresets(cameraId, opts);
    return {fulfilled: true, assets: response};
  } catch (error) {
    setErrorEventData(window.dispatchEvent, error, 'get-camera-presets');
    return {fulfilled: false, error: error.body, status: error.status};
  }
};
