<script>
  import SearchableSelectBadge from '@cox2m/city-services-ui-components/src/components/SearchableSelectBadge.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Popper from '@cox2m/city-services-ui-components/src/components/Popper.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import Magnifier from './components/Magnifier.svelte';
  import Map from '@cox2m/city-services-ui-components/src/components/maps/arcgis/Map.svelte';
  import MapDraggableMarker
    from '@cox2m/city-services-ui-components/src/components/maps/arcgis/MapDraggableMarker.svelte';
  import Button from '@cox2m/city-services-ui-components/src/components/Button.svelte';
  import {menuOpts, selectedPark, user} from '../stores';
  import {getCameraPresets, getCameras, upsertHomographyPoints} from '../actions';
  import {cameraSortFunction} from './components/scripts/events-screen/events-screen-funcs';
  import {getCookieByName, setSuccessToast} from '@cox2m/city-services-ui-components/src/funcs';
  import {getLastEventImageAsBase64} from '../../funcs';
  import axios from 'axios';
  import {Buffer} from 'buffer';

  let imageToMagnify = null;
  let homographyPoints = [];
  let showSelectedPoints = true;
  let showReferenceGrid = false;
  let rows = [];
  let mapView = null;
  const bakerParkCoordinates = [-115.1410481, 36.1473869];
  let selectedPoint = null;
  let showMagnifier = true;
  let showAllPoints = false;
  let imgUrl = '';
  let image = null;
  let windowWidth;
  let selectedCamera = '';
  let selectedPreset = '';
  let cameraOptions = [];
  let presetOptions = [];
  let loadingOptions = true;
  let homographyUpdateError = false;

  const token = getCookieByName(`${'ENV'}_token`);

  const eventsScreenSetup = async () => {
    loadingOptions = true;
    selectedCamera = null;

    const cameraResponse = await getCameras(token, {siteName: $selectedPark.name});

    if (cameraResponse.fulfilled) {
      cameraOptions = cameraResponse.cameras.map(camera => ({
        label: camera.name,
        value: camera.id
      }));

      homographyUpdateError = false;
      loadingOptions = false;
    } else {
      homographyUpdateError = true;
    }
  };
  const updatePresetOptions = async () => {
    let camId;
    if (selectedCamera && selectedCamera.value) {
      camId = selectedCamera.value;
    } else return;
    loadingOptions = true;
    selectedPreset = null;

    const presetResponse = await getCameraPresets(token, camId, {});

    if (presetResponse.fulfilled) {
      presetOptions = presetResponse.assets.presets.map(preset => ({
        label: preset.name,
        value: preset.name,
        homographyPoints: preset.homographyPoints
      }));
      homographyUpdateError = false;
      loadingOptions = false;
    } else {
      homographyUpdateError = true;
    }
  };

  /**
   * Update the rows using the homography points
   * @param homographyPoints
   */
  const updateRows = (homographyPoints) => {
    rows = homographyPoints.map(point => ({
      id: point.id,
      image_x: point.image_coordinates.x,
      image_y: point.image_coordinates.y,
      latitude: point.map_coordinates.latitude,
      longitude: point.map_coordinates.longitude,
      tracked: point.tracking
    }));
  };

  const hexToBase64 = data => {
    return Buffer.from(data).toString('base64');
  };

  const updateHomographyPoints = async () => {
    image = await getLastEventImageAsBase64({cameraId: selectedCamera.value, presetName: selectedPreset.value, axios, token});
    imgUrl = hexToBase64(image).replace(/(\r\n|\n|\r)/gm, "");
    const homographyPointsImage = selectedPreset.homographyPoints.approxCornerPoints;
    const homographyPointsMap = selectedPreset.homographyPoints.destinationPoints;

    homographyPoints = homographyPointsImage.map((point, index) => ({
      id: `${point.x}_${point.y}`,
      image_coordinates: {
        x: point.x,
        y: point.y
      },
      map_coordinates: {
        latitude: homographyPointsMap[index].x,
        longitude: homographyPointsMap[index].y
      },
      tracking: true
    }));
    updateRows(homographyPoints);
  };

  /**
   * Persist homography points
   * @param camera
   * @param preset
   * @param homographyPoints
   * @returns {Promise<void>}
   */
  const saveHomographyPoints = async (camera, preset, homographyPoints) => {
    const approxCornerPoints = homographyPoints
      .map(point => ({x: point.image_coordinates.x, y: point.image_coordinates.y}));
    const destinationPoints = homographyPoints
      .map(point => ({x: point.map_coordinates.latitude, y: point.map_coordinates.longitude}));

    await upsertHomographyPoints(token, camera, preset, {homographyPoints: {approxCornerPoints, destinationPoints}});
  };

  $: eventsScreenSetup($selectedPark.name);
  $: selectedCamera && updatePresetOptions();
  $: selectedPreset && updateHomographyPoints();
  $: homographyPoints && updateRows(homographyPoints);
</script>

<style>
  .image-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    position: relative;
  }

  img {
    height: 360px;
    width: 640px;
    margin: 0 auto;
    cursor: crosshair;
  }

  .image-grid-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    height: calc(100% - 80px);
    gap: var(--cox2m-spacing-2-units);
  }

  .reference-grid {
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .reference-grid > div {
    width: 100%;
    height: 100%;
    border: 0.5px solid var(--cox2m-clr-brand-600);
    pointer-events: none;
  }

  .information-table-container {
    max-height: min(36vh, 500px);
    padding: 0 var(--cox2m-spacing-1-units);
    overflow: auto;
  }

  .map-container {
    background-color: var(--cox2m-clr-neutral-white);
    width: 100%;
    height: 100%;
  }

  .image-point {
    position: relative;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    border: 1px solid var(--cox2m-clr-neutral-white);
    cursor: pointer;
  }

  .image-info-container {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
    gap: var(--cox2m-spacing-2-units);
  }

  .data-point-container {
    background-color: var(--cox2m-clr-neutral-white);
    padding: var(--cox2m-spacing-2-units);
    border-radius: 12px;
    margin-bottom: var(--cox2m-spacing-2-units);
    cursor: pointer;
  }

  .data-point-container p {
    margin: 0;
  }

  .data-point-container .point-id {
    color: var(--cox2m-clr-brand-600);
  }

  .data-point-container.tracked {
    border: var(--cox2m-brd-w-2) solid var(--cox2m-clr-brand-600);
  }

  .warning-disclaimer {
    color: var(--cox2m-clr-warning-500);
  }

  .mapping-tool-buttons-container {
    display: flex;
    justify-content: space-between;
  }

  .badge-filters {
    display: flex;
    justify-content: flex-end;
    gap: var(--cox2m-spacing-2-units);
    padding: var(--cox2m-spacing-4-units) 0;
  }

</style>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<svelte:window bind:innerWidth={windowWidth} />

<Modal>
  <MainScreen
    title='Mapping Tool'
    showAlertsBanner={false}
    menuOpt={$menuOpts}
    user={$user}
    appSlug='smart-security'>
    <div slot='main-dashboard' class='w-100 h-100'>
      <div class='mapping-tool-buttons-container'>
        <div>
          <Button
            customClass='font-weight-bold'
            text="{showSelectedPoints ? 'hide' : 'show'} image selections"
            width='fit-content'
            height='var(--cox2m-spacing-10-units)'
            backgroundColor='var(--cox2m-clr-brand-600)'
            color='var(--cox2m-clr-neutral-white)'
            margin='0 var(--cox2m-spacing-4-units) var(--cox2m-spacing-4-units)
            0'
            padding='0 var(--cox2m-spacing-4-units)'
            on:click={() => (showSelectedPoints = !showSelectedPoints)} />

          <Button
            customClass='font-weight-bold'
            text="{showReferenceGrid ? 'hide' : 'show'} reference grid"
            width='fit-content'
            height='var(--cox2m-spacing-10-units)'
            backgroundColor='var(--cox2m-clr-brand-600)'
            color='var(--cox2m-clr-neutral-white)'
            margin='0 var(--cox2m-spacing-4-units) var(--cox2m-spacing-4-units)
            0'
            padding='0 var(--cox2m-spacing-4-units)'
            on:click={() => (showReferenceGrid = !showReferenceGrid)} />

          <Button
            customClass='font-weight-bold'
            text="{showMagnifier ? 'hide' : 'show'} magnifier"
            width='fit-content'
            height='var(--cox2m-spacing-10-units)'
            backgroundColor='var(--cox2m-clr-brand-600)'
            margin='0 0 var(--cox2m-spacing-4-units) 0'
            color='var(--cox2m-clr-neutral-white)'
            padding='0 var(--cox2m-spacing-4-units)'
            on:click={() => (showMagnifier = !showMagnifier)} />
        </div>
        <div>
          <Button
            customClass='font-weight-bold'
            text="{showAllPoints ? 'hide' : 'show'} all points"
            width='fit-content'
            height='var(--cox2m-spacing-10-units)'
            backgroundColor='var(--cox2m-clr-brand-600)'
            color='var(--cox2m-clr-neutral-white)'
            margin='0 var(--cox2m-spacing-4-units) var(--cox2m-spacing-4-units)
            0'
            padding='0 var(--cox2m-spacing-4-units)'
            on:click={() => (showAllPoints = !showAllPoints)} />

          <Button
            customClass='font-weight-bold'
            text='Save Points'
            width='fit-content'
            height='var(--cox2m-spacing-10-units)'
            backgroundColor='var(--cox2m-clr-brand-600)'
            color='var(--cox2m-clr-neutral-white)'
            margin='0 0 0 0'
            padding='0 var(--cox2m-spacing-4-units)'
            on:click={() => {
              console.log(homographyPoints);
              saveHomographyPoints(selectedCamera.value, selectedPreset.value, homographyPoints);
              setSuccessToast(window.dispatchEvent, 'Points saved correctly.');
            }} />
        </div>
      </div>

      <div class='search-container'>
        <span class='badge-filters'>
          {#if selectedCamera && !loadingOptions}
            <span class='d-flex'>
              <SearchableSelectBadge
                bind:selectedOption={selectedPreset}
                options={presetOptions}
                id='preset-filter-badge'
                defaultLabel='Preset'
                responsiveTitle='Filter by preset' /></span>
          {/if}
          <span class='d-flex'>
            <SearchableSelectBadge
              bind:selectedOption={selectedCamera}
              id='camera-filter-badge'
              options={ cameraOptions.sort(cameraSortFunction)}
              defaultLabel='Camera'
              responsiveTitle='Filter by camera' />
          </span>
          </span>
        <!-- todo remove
        <MuiTextInput
          id='image-url'
          name='image-url'
          label='Image Url'
          bind:value={imgUrl}
          customContainerClass='w-100 mb-2' />
          -->

        {#if showAllPoints && rows.length && rows.filter(row => !row.latitude && !row.longitude).length}
          <p class='warning-disclaimer'>
            <b>Warning:</b>
            you have points without latitude and longitude, click on the button
            on the left that says hide all points and click on the points
            individually to set them lat/long values
          </p>
        {/if}
        {#if homographyUpdateError}
          <p class='warning-disclaimer'>
            <b>Warning:</b>
            Camera or preset selected are invalid reselect a camera or preset.
          </p>
        {/if}
      </div>

      <div class='image-grid-container'>
        <div class='image-info-container'>
          <div>
            <div class='image-container'>
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <img
                on:dblclick={e => {
                  if (!selectedCamera || !selectedPreset || homographyUpdateError){ homographyUpdateError = true; return;}
                  try {
                    const xCoordinate = Math.round((e.offsetX * e.target.naturalWidth) / e.target.width);
                    const yCoordinate = Math.round((e.offsetY * e.target.naturalHeight) / e.target.height);
                    homographyPoints = [...homographyPoints, {
                                 id: `${xCoordinate}_${yCoordinate}`,
                                 image_coordinates: {x: xCoordinate, y: yCoordinate},
                                 map_coordinates: {latitude: null, longitude: null},
                                 image_reference: {x: e.offsetX, y: e.offsetY},
                                 tracking: false
                    }];
                    homographyUpdateError =false
                  } catch (e) {
                    homographyUpdateError =true
                  }
                }}
                bind:this={imageToMagnify}
                id='homography-image'
                src={'data:image/png;base64,'+imgUrl}
                alt='test' />
              {#if showSelectedPoints}
                {#each homographyPoints as point}
                  <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <div
                    on:click={() => {
                      homographyPoints = homographyPoints.map(hpoint => {
                        if (point.id === hpoint.id) {
                          hpoint.tracking = !hpoint.tracking;
                          selectedPoint = rows.filter(row => row.id === hpoint.id);
                          selectedPoint[0].latitude = selectedPoint[0].latitude || bakerParkCoordinates[1];
                          selectedPoint[0].longitude = selectedPoint[0].longitude || bakerParkCoordinates[0];
                        } else {
                          hpoint.tracking = false;
                        }
                        return hpoint;
                      });
                    }}
                    style='left: {((point.image_reference && point.image_reference.x) || (point.image_coordinates && point.image_coordinates.x/2)) - 5}px; top: {((point.image_reference && point.image_reference.y) || (point.image_coordinates && point.image_coordinates.y/2)) - 5}px;
                    position:absolute;'>
                    <Popper>
                      <div
                        slot='tooltip-trigger'
                        class='image-point'
                        style="background: {point.tracking ? 'var(--cox2m-clr-warning-500)' : point.map_coordinates.latitude && point.map_coordinates.longitude ? 'var(--cox2m-clr-success-600)' : 'var(--cox2m-clr-critical-600)'};
                        " />
                      <div slot='tooltip-component'>
                        <div>id: {point.id}</div>
                        <div>image_x: {point.image_coordinates.x}</div>
                        <div>image_y: {point.image_coordinates.y}</div>
                        <div>latitude: {point.map_coordinates.latitude}</div>
                        <div>longitude: {point.map_coordinates.longitude}</div>
                      </div>
                    </Popper>
                  </div>
                {/each}
              {/if}
              {#if showMagnifier}
                <Magnifier image={imageToMagnify} imgUrl={'data:image/png;base64,'+imgUrl} />
              {/if}
              {#if showReferenceGrid}
                <div class='reference-grid'>
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              {/if}
            </div>
          </div>
          <div class='information-table-container'>
            {#each rows as pointRow}
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <div
                class='data-point-container cox2m-shadow-1-dp cursor'
                on:click={() => {
                  homographyPoints = homographyPoints.map(hpoint => {
                    hpoint.tracking=pointRow.id === hpoint.id;
                    return hpoint;
                  });
                  selectedPoint = rows.filter(row => row.id === pointRow.id);
                  selectedPoint[0].latitude = selectedPoint[0].latitude || bakerParkCoordinates[1];
                  selectedPoint[0].longitude = selectedPoint[0].longitude || bakerParkCoordinates[0];
                }}
                class:tracked={pointRow.tracked}>
                <div>

                  <p class='point-id'>
                    point id:
                    <b>{pointRow.id}</b>
                  </p>
                  <p>
                    image x, y:
                    <b>{pointRow.image_x}, {pointRow.image_y}</b>
                    - lat,long:
                    <b>{pointRow.latitude}, {pointRow.longitude}</b>
                  </p>
                  {#if showAllPoints && !pointRow.latitude && !pointRow.longitude}
                    <p class='warning-disclaimer'>
                      this point is not being show you have to set the latitude
                      and longitude
                    </p>
                  {/if}
                  <div>
                    <Button
                      customClass='font-weight-bold'
                      text='Remove'
                      width='fit-content'
                      height='var(--cox2m-spacing-10-units)'
                      backgroundColor='var(--cox2m-clr-brand-600)'
                      margin='0 0 0 0'
                      color='var(--cox2m-clr-neutral-white)'
                      padding='0 var(--cox2m-spacing-4-units)'
                      on:click={e => {
                        homographyPoints = homographyPoints.filter(point => point.id !== pointRow.id);
                        upsertHomographyPoints(token, selectedCamera, selectedPreset, {homographyPoints})
                        e.stopPropagation();
                      }} />

                    <Button
                      customClass='font-weight-bold'
                      text="{pointRow.tracked ? 'untrack' : 'track'} in image"
                      width='fit-content'
                      height='var(--cox2m-spacing-10-units)'
                      backgroundColor='var(--cox2m-clr-brand-600)'
                      margin='0 0 0 0'
                      color='var(--cox2m-clr-neutral-white)'
                      padding='0 var(--cox2m-spacing-4-units)'
                      on:click={e => {
                        e.stopPropagation();
                        homographyPoints = homographyPoints.map(point => {
                          if (point.id === pointRow.id) {
                            point.tracking = !point.tracking;
                            selectedPoint = rows.filter(row => row.id === pointRow.id);
                            selectedPoint[0].latitude = selectedPoint[0].latitude || bakerParkCoordinates[1];
                            selectedPoint[0].longitude = selectedPoint[0].longitude || bakerParkCoordinates[0];
                          } else {
                            point.tracking = false;
                          }
                          return point;
                        });
                      }} />
                  </div>
                </div>
              </div>
            {/each}
          </div>
        </div>
        <div class='map-container'>
          <Map
            props={{zoom: 16, center: bakerParkCoordinates}}
            basemap='hybrid'
            bind:view={mapView}>
            <MapDraggableMarker
              layerTitle='Homography'
              collection={showAllPoints ? rows.filter(row => row.latitude && row.longitude) : selectedPoint ? selectedPoint : []}
              markerConfig={{type: 'simple-marker', path: 'M256 0c-88.366 0-160 71.634-160 160 0 160 160 352 160 352s160-192 160-352c0-88.366-71.635-160-160-160zM256 256c-53.020 0-96-42.98-96-96s42.98-96 96-96 96 42.98 96 96-42.98 96-96 96z', color: [250, 149, 25], outline: {color: [0, 0, 0, 0], width: 0.5}, size: 25}}
              onMarkerClick={e => {
                if (e && e.id) {
                  homographyPoints = homographyPoints.map(hpoint => {
                    hpoint.tracking=e.id === hpoint.id;
                    return hpoint;
                  });
                  selectedPoint = rows.filter(row => row.id === e.id);
                  selectedPoint[0].latitude = e.latitude;
                  selectedPoint[0].longitude = e.longitude;
                }
              }}
              on:ModifyPoint={e => {
                homographyPoints = homographyPoints.map(point => {
                  if (point.id === e.detail.id) {
                    point.map_coordinates.latitude = e.detail.latitude;
                    point.map_coordinates.longitude = e.detail.longitude;
                    selectedPoint[0].latitude = e.detail.latitude;
                    selectedPoint[0].longitude = e.detail.longitude;
                  }
                  return point;
                });
              }} />
          </Map>
        </div>
      </div>

    </div>
  </MainScreen>
</Modal>
