<script>
  import LoadingSpinner from "@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte";

  export let cameraViews = [];
  export let selectedEvent = null;
  export let liveCameraToggleSwitch = false;
</script>

<style>
  img{
    object-fit: contain;
  }

  .loading-container{
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (max-width: 767px){
    img {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
    .loading-container{
      min-height: 200px;
    }
  }
</style>

{#each cameraViews as view, index}
  <div
    class="carousel-item h-100 {index === 0 && !selectedEvent ? 'active' : ''}"
    id={`camera-view-slide-${index}`}>
    {#if view.loading}
      <div class="h-100 loading-container">
        <LoadingSpinner />
      </div>
    {:else if !liveCameraToggleSwitch}
      <img
        id={`${view.ip}-image-slide`}
        class="d-block w-100 h-100"
        src={view.imageUrl}
        alt={view.name || ''} />
    {:else}
      <!-- svelte-ignore a11y-media-has-caption -->
      <video
        class="w-100 h-100 live-video"
        id={`live-video-${index}`}
        muted
        autoplay
        controls />
    {/if}
  </div>
{/each}
