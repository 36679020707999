import {isEmpty} from '@cox2m/city-services-ui-components/src/funcs.js';
import {writableLocal} from './store-util';
import {writable} from 'svelte/store';

const getUserInitials = userData => {
  if (!isEmpty(userData.givenName) && !isEmpty(userData.familyName)) {
    return `${userData.givenName[0].toUpperCase()}${userData.familyName[0].toUpperCase()}`;
  }
  return '';
};

const getUserFullName = userData => {
  return `${userData.givenName || ''} ${userData.familyName || ''}`.trim();
};

const getGroupName = userData => {
  return `${userData.groupName}`;
};

const userStore = () => {
  const {subscribe, set, update} = writableLocal('user', {});
  return {
    subscribe,
    saveUser: userData =>
      update(() => ({
        ...userData,
        initials: getUserInitials(userData),
        fullName: getUserFullName(userData),
        groupName: getGroupName(userData)
      })),
    logout: () => set({})
  };
};

const parkStore = () => {
  const PARK_KEY = 'smart-park-selected-site';
  const {subscribe, set} = writableLocal(
    PARK_KEY,
    localStorage.getItem(PARK_KEY) &&
      localStorage.getItem(PARK_KEY) !== 'undefined'
      ? JSON.parse(localStorage.getItem(PARK_KEY))
      : {}
  );
  return {
    subscribe,
    setSelectedPark: data => set(data),
    clear: () => {
      localStorage.removeItem(PARK_KEY);
    }
  };
};

const menuOptsStore = () => {
  const {subscribe, set} = writable([]);

  return {
    subscribe,
    updateMenuValues: data => set(data)
  };
};

export const user = userStore();
export const menuOpts = menuOptsStore();
export const selectedPark = parkStore();
