<script>
  import CalendarPicker from '@cox2m/city-services-ui-components/src/components/time/CalendarPicker.svelte';
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import TimePicker from '@cox2m/city-services-ui-components/src/components/time/TimePicker.svelte';
  import Sidebar from '@cox2m/city-services-ui-components/src/components/Sidebar.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
  import FiltersSidebar from './components/gallery-screen/FiltersSidebar.svelte';
  import ImageGallery from './components/gallery-screen/ImageGallery.svelte';
  import SiteIndicator from './components/shared/SiteIndicator.svelte';

  import {
    getCookieByName,
    isMobile
  } from '@cox2m/city-services-ui-components/src/funcs';
  import {waitForElementExistance} from '../../funcs';
  import {menuOpts, selectedPark, user} from '../stores';
  import {onMount} from 'svelte';
  import {
    getEventsGalleryUsingFilter,
    getAssetTypes,
    getZones
  } from '../actions';

  const token = getCookieByName(`${'ENV'}_token`);

  const NUMBER_OF_IMAGES = 20;
  const BOTTOM_OFFSET = 40;
  let limit = NUMBER_OF_IMAGES,
    offset = 0;

  let clientId = token.substring(0, 8);
  let savedFilters = JSON.parse(localStorage.getItem('galleryFilters'));
  let isSiteLoading;
  let isSiteOnError;

  if (savedFilters && savedFilters.clientId !== clientId && $selectedPark.name === savedFilters.siteName.name) {
    savedFilters = null;
  }

  const formatDateStringPicker = date => {
    let dateToFormat = new Date(date);
    let tempDate = new Date(
      dateToFormat.getFullYear(),
      dateToFormat.getMonth(),
      dateToFormat.getDate()
    );
    let formattedDate = `${tempDate.getFullYear()}/${tempDate.getMonth() + 1}/${tempDate.getDate()}`;
    return formattedDate;
  };

  const TODAY_FORMATTED_DATE = formatDateStringPicker(new Date());

  let zonesFetchingError = false;
  let currentDate =
    (savedFilters && new Date(savedFilters.currentDate)) || new Date();
  let finishDate =
    (savedFilters && new Date(savedFilters.finishDate)) || new Date();

  let loading = true;

  let assetSelectionString =
    (savedFilters && savedFilters.assetSelectionString) || '';

  let imagesFetchingError = false;
  let assetsOptionsError = false;

  let imagesLoading, infiniteScrollLoading;
  let filteredImages = [];
  let imagesResponse = [];

  let userPickedTime = (savedFilters && savedFilters.userPickedTime) || null;

  let selectedTabZone = (savedFilters && savedFilters.selectedTabZone) || 'All';
  let assetsOptions = [];
  let zones = [];

  let selectedOptions = {};

  let showFiltersSidebar = false;

  const formatDateString = date => {
    return new Date(date).toISOString().replace(/T.*/gi, '');
  };

  let sinceDate = formatDateString(currentDate);
  let untilDate = formatDateString(finishDate);
  let sinceHour = (savedFilters && savedFilters.sinceHour) || 0;
  let untilHour = (savedFilters && savedFilters.untilHour) || 23;
  let sinceMinute = (savedFilters && savedFilters.sinceMinute) || 0;
  let untilMinute = (savedFilters && savedFilters.untilMinute) || 59;

  const onDateChange = dateSelectionObject => {
    currentDate = dateSelectionObject.firstDate;
    finishDate = dateSelectionObject.latestDate;

    buildDateFilter();
  };

  const handleTimeChange = e => {
    userPickedTime = e.detail;
    buildDateFilter(currentDate);
  };

  const buildDateFilter = () => {
    if (userPickedTime) {
      const selectedHour =
        userPickedTime.selectedStartTime.selectedMeridiem === 'pm'
          ? userPickedTime.selectedStartTime.selectedHour === 12
            ? 12
            : userPickedTime.selectedStartTime.selectedHour + 12
          : userPickedTime.selectedStartTime.selectedHour === 12
            ? 0
            : userPickedTime.selectedStartTime.selectedHour;
      const selectedUntilHour =
        userPickedTime.selectedEndTime.selectedMeridiem === 'pm'
          ? userPickedTime.selectedEndTime.selectedHour === 12
            ? 12
            : userPickedTime.selectedEndTime.selectedHour + 12
          : userPickedTime.selectedEndTime.selectedHour === 12
            ? 0
            : userPickedTime.selectedEndTime.selectedHour;
      sinceHour = selectedHour;
      untilHour = selectedUntilHour;
      sinceMinute = userPickedTime.selectedStartTime.selectedMinute;
      untilMinute = userPickedTime.selectedEndTime.selectedMinute;
    }
    sinceDate = formatDateString(currentDate);
    untilDate = formatDateString(finishDate);
  };

  const fetchZones = async () => {
    let zonesResponse = await getZones(token, $selectedPark ? {siteName: $selectedPark.name}: {});
    if (zonesResponse.fulfilled) {
      zones = zonesResponse.zones;
    } else {
      zonesFetchingError = true;
    }
  };

  const getAssetOptions = async () => {
    const assetTypesResponse = await getAssetTypes(token, $selectedPark ? {siteName: $selectedPark.name}: {});
    if (assetTypesResponse.fulfilled) {
      assetTypesResponse.assets.map((asset, i) => {
        assetsOptions = [
          ...assetsOptions,
          {
            id: i,
            label: asset.assetType,
            value: asset.assetType,
            assets: asset.assets
          }
        ];
      });
    } else {
      assetsOptionsError = true;
    }
  };

  const optionsBuilder = () => {
    selectedOptions = {
      limit,
      offset,
      ...(selectedTabZone !== 'All' && {zone: selectedTabZone}),
      sinceRange: sinceDate,
      untilRange: untilDate,
      sinceHourRange: sinceHour,
      ...(sinceHour !== null &&
        sinceDate && {sinceMinuteRange: sinceMinute || 0}),
      untilHourRange: untilHour,
      ...(sinceHour !== null &&
        untilDate && {untilMinuteRange: untilMinute || 0}),
      ...(assetSelectionString &&
        assetSelectionString !== '' && {names: assetSelectionString}),
      ...($selectedPark && {siteName: $selectedPark.name})
    };

    persistFilteredOptions();
  };

  const persistFilteredOptions = async () => {
    await localStorage.setItem(
      'galleryFilters',
      JSON.stringify({
        clientId: token.substring(0, 8),
        currentDate,
        finishDate,
        userPickedTime,
        zones,
        selectedTabZone,
        sinceHour,
        untilHour,
        sinceMinute,
        untilMinute,
        assetSelectionString,
        siteName: $selectedPark
      })
    );
  };

  const getFilteredImages = (infiniteScrolling = false) => {
    optionsBuilder();
    sinceDate && getImages(selectedOptions, infiniteScrolling);
  };

  const getImages = async (opts = {}, {infiniteScrolling = false}) => {
    !infiniteScrolling
      ? (imagesLoading = true)
      : (infiniteScrollLoading = true);
    imagesResponse = await getEventsGalleryUsingFilter(token, opts);
    if (imagesResponse.fulfilled) {
      parseInt(opts.offset) == 0
        ? (filteredImages = imagesResponse.gallery)
        : (filteredImages = [...filteredImages, ...imagesResponse.gallery]);
    } else {
      imagesFetchingError = true;
    }
    !infiniteScrolling
      ? (imagesLoading = false)
      : (infiniteScrollLoading = false);
  };

  const checkExtraImageLoading = imagesContainer => {
    if (
      imagesContainer.scrollTop + imagesContainer.clientHeight >=
      imagesContainer.scrollHeight - BOTTOM_OFFSET
    ) {
      if (
        !imagesLoading &&
        !infiniteScrollLoading &&
        imagesResponse.paging.next
      ) {
        offset += NUMBER_OF_IMAGES;
        getFilteredImages({infiniteScrolling: true});
      }
    }
  };

  const resetImagesLimit = () => {
    offset = 0;
  };

  const dashboardContainerInfiniteScrollHandler = () => {
    const dashboardContainer = document.querySelector(
      '.main-dashboard-container'
    );

    dashboardContainer.addEventListener('scroll', e =>
      checkExtraImageLoading(e.target)
    );
  };
  let selectedFiltersLabel = "None";
  const getSelectedFiltersLabel = (selectedFilters) => {
    if (!selectedFilters) {selectedFiltersLabel = "None"; return;}

    const selected = new Map();
    let appliedFilters = selectedFilters.split(',');

    for (const i in appliedFilters) {
      let zone = appliedFilters[i].split(' ')[0];
      selected.set(zone, selected.get(zone) + 1 || 1);
    }

    if (selected.size === 0) {selectedFiltersLabel = "None"; return;}
    selectedFiltersLabel = selected.size === 1 ?
      `${selected.entries().next().value[0]} ${selected.entries().next().value[1]}` :
      `Customized +${selected.size}`;
  };

  const fetchParkAssetsData = async () => {
    await fetchZones();
    await getAssetOptions();
  }

  onMount(async () => {
    await waitForElementExistance('.main-dashboard-container');
    dashboardContainerInfiniteScrollHandler();

    await fetchParkAssetsData();
    getSelectedFiltersLabel();

    loading = false;
  });

  $: resetImagesLimit(
    currentDate,
    finishDate,
    userPickedTime,
    zones,
    selectedTabZone,
    assetSelectionString,
    $selectedPark
  );

  $: getFilteredImages(
    currentDate,
    finishDate,
    userPickedTime,
    zones,
    selectedTabZone,
    assetSelectionString,
    $selectedPark
  );

</script>

<style>
  .tabs-container {
    height: var(--cox2m-spacing-19-units);
    margin-left: calc(var(--cox2m-spacing-8-units) * (-1));
    margin-right: calc(var(--cox2m-spacing-8-units) * (-1));
    top: calc(var(--cox2m-spacing-28-units) * (-1));
  }
  .site-indicator-container{
    position: relative;
    top: var(--cox2m-spacing-16-units);
    margin-left: var(--cox2m-spacing-4-units);
  }

  .tabs-button {
    min-width: var(--cox2m-spacing-10-units);
    color: var(--cox2m-clr-neutral-black);
  }

  .tabs-button:not(:last-of-type) {
    margin-right: var(--cox2m-spacing-8-units);
  }

  .tabs-button.selected {
    border-radius: 0.25rem;
    color: var(--cox2m-clr-brand-600);
    background-color: var(--cox2m-clr-brand-300);
    border-bottom: var(--cox2m-spacing-1-units) solid;
    border-bottom-color: var(--cox2m-clr-brand-600);
    min-width: fit-content;
    max-width: var(--cox2m-spacing-30-units);
    width: fit-content;
  }

  .pointer {
    cursor: pointer;
  }

  .date-picker-container {
    margin-right: var(--cox2m-spacing-6-units);
  }

  .filters-container {
    margin-top: var(--cox2m-spacing-3-units);
    padding: 0 var(--cox2m-spacing-8-units) 0 var(--cox2m-spacing-8-units);
  }

  .filter-badge {
    color: var(--cox2m-clr-neutral-black);
    border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-brand-400);
    border-radius: 54px;
    height: var(--cox2m-spacing-8-units);
    font-weight: var(--cox2m-fw-bold);
  }

  #empty-images-message {
    color: var(--cox2m-clr-neutral-black);
  }

  :global(.filter-rows-container .box.visible) {
    z-index: var(--cox2m-z-index-1);
  }

  @media only screen and (max-width: 1200px) {
    .tabs-container{
      top: -124px;
    }
    .site-indicator-container{
      margin-left: calc(var(--cox2m-spacing-4-units) * (-1));
    }
    #zones-tabs-container {
      overflow-x: auto;
      flex-wrap: nowrap;
      flex: none;
      padding-left: var(--cox2m-spacing-8-units);
      justify-content: unset !important;
      padding-right: var(--cox2m-spacing-8-units);
    }

    #zones-tabs-container::-webkit-scrollbar {
      display: none;
    }

    .tabs-button {
      min-width: unset;
    }

    .filters-container {
      top: 0;
      margin-top: 0;
      padding: 0;
    }

    .filter-rows-container {
      flex-direction: column !important;
    }

    .filter-badges-container {
      height: var(--cox2m-spacing-8-units);
      padding-left: var(--cox2m-spacing-8-units);
    }

    .time-pickers-container {
      display: flex;
      flex-direction: column;
      gap: var(--cox2m-spacing-3-units);
    }

    :global(.date-picker-container .relative) {
      min-width: 190px;
    }

    :global(#gallery-time-picker .time-label-container) {
      flex-wrap: nowrap;
      flex: none;
    }

    :global(#gallery-time-picker) {
      width: calc(100% - 24px);
      justify-content: center;
    }

    .filter-rows-container .row::-webkit-scrollbar {
      display: none;
    }

    .filter-badge {
      margin-top: var(--cox2m-spacing-3-units);
      position: relative;
      left: calc(var(--cox2m-spacing-4-units) * (-1));
    }

    :global(.date-picker-container:not(.position-relative), .time-pickers-container > .d-flex > .position-relative) {
      width: 100% !important;
      justify-content: center;
    }

    :global(.time-pickers-container .time-label-container) {
      width: fit-content !important;
    }

    .mobile-filters {
      margin: 0 !important;
    }

    .selected-filters {
      background-color: var(--cox2m-clr-brand-600);
      color: var(--cox2m-clr-neutral-white);
    }
  }

  @media only screen and (max-width: 767px) {
    .tabs-button {
    background-color: var(--cox2m-clr-neutral-white);
    color: var(--cox2m-clr-neutral-black);
    margin-top: var(--cox2m-spacing-4-units);
    }
  }


</style>

<MainScreen title="Park Assets" menuOpt={$menuOpts} showAlertsBanner={false} user={$user} appSlug="smart-security">
  <div slot="main-dashboard" class="w-100 h-100">
    <div class="site-indicator-container">
      <SiteIndicator
        title="Park Assets"
        bind:isLoading={isSiteLoading}
        bind:isOnError={isSiteOnError} />
    </div>
    {#if loading || isSiteLoading}
      <div class="h-100 w-100 d-flex align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    {:else}
      <Sidebar id='filters-sidebar' bind:show={showFiltersSidebar}>
        <FiltersSidebar
          {token}
          bind:show={showFiltersSidebar}
          on:applyFiltersSelection={e => {
            assetSelectionString = e.detail
            getSelectedFiltersLabel(e.detail);
          }}
          on:resetSelectedZone={() => (selectedTabZone = 'All')}
          selectedZone={selectedTabZone}
          {zones}
          assets={assetsOptions} />
      </Sidebar>

      {#if zonesFetchingError || assetsOptionsError || imagesFetchingError || isSiteOnError}
        <div
          class='h-100 w-100 d-flex align-items-center justify-content-center'>
          <h2 id='zones-loading-error' class='d-block w-100 text-center'>
            We are sorry, we couldn't get the required information, please
            reload the page or try again later.
          </h2>
        </div>
      {:else}
        <div class='tabs-container position-relative'>
          <div
            class='tab-buttons-container d-flex row mx-0 h-100
            align-items-center justify-content-center'
            id='zones-tabs-container'
            data-testid='zones-tabs-container'>
            {#each zones as zone}
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <span
                id={`${zone.toLowerCase()}-tab`}
                data-testid={`${zone.toLowerCase()}-tab`}
                class:selected={selectedTabZone === zone}
                class='tabs-button p-2 pointer'
                on:click={() => (selectedTabZone = zone)}>
                {zone}
              </span>
            {/each}
          </div>
        </div>

        <div class='container-fluid filters-container position-relative'>
          <div
            class='row justify-content-between filter-rows-container
            mobile-filters'
            data-testid='filter-rows-container'>
            <div class='row m-0 time-pickers-container'>
              <div class='d-flex date-picker-container'>
                <CalendarPicker
                  firstSelectedDate={(savedFilters && savedFilters.currentDate) || TODAY_FORMATTED_DATE}
                  latestSelectedDate={(savedFilters && savedFilters.finishDate) || TODAY_FORMATTED_DATE}
                  endCalendarAt={TODAY_FORMATTED_DATE}
                  on:date-selection-change={event => onDateChange(event.detail)} />
              </div>

              <div class='d-flex'>
                <TimePicker
                  id='gallery-time-picker'
                  on:dateSelectionChange={handleTimeChange}
                  initialStartTime={savedFilters && savedFilters.userPickedTime ? savedFilters.userPickedTime.selectedStartTime.userFriendlyFormat : '12:00 am'}
                  initialEndTime={savedFilters && savedFilters.userPickedTime ? savedFilters.userPickedTime.selectedEndTime.userFriendlyFormat : '11:59 pm'}
                  timeRange />
              </div>
            </div>
            {#if isMobile()}
              <div class='row align-items-center filter-badges-container'>
              <span class='mr-1 reset-filter-badge d-flex'>
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <span
                  class='filter-badge d-flex align-items-center
                  justify-content-center cox2m-smalltext-1 px-3 py-0 pointer'
                  data-testid='filter-badge'
                  on:click={() => (showFiltersSidebar = true)}>
                  <Icon
                    icon='filter'
                    size='18px'
                    color='var(--cox2m-clr-neutral-700)' />
                </span>
              </span>
                <span class='mr-1 all-filter-badge d-flex'>
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <span
                  class='filter-badge d-flex align-items-center
                  justify-content-center cox2m-smalltext-1 px-3 py-0 pointer'
                  data-testid='filter-badge'
                  on:click={() => (selectedTabZone = 'All')}>
                    All
                </span>
              </span>
                <span class='mr-1 selected-filter-badge d-flex'>
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <span class='filter-badge selected-filters d-flex align-items-center
                  justify-content-center cox2m-smalltext-1 px-3 py-0 pointer'
                        data-testid='filter-badge'
                        on:click={() => (showFiltersSidebar = true)}>
                    {selectedFiltersLabel}
                  </span>
                </span>
              </div>
            {:else}
              <div class='row align-items-center filter-badges-container'>
              <span class='mr-3 reset-filter-badge d-flex'>
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <span
                  class='filter-badge d-flex align-items-center
                  justify-content-center cox2m-smalltext-1 px-3 py-0 pointer'
                  data-testid='filter-badge'
                  on:click={() => (showFiltersSidebar = true)}>
                  <Icon
                    icon='filter'
                    size='18px'
                    className='mr-2'
                    color='var(--cox2m-clr-neutral-700)' />
                  Filter
                </span>
              </span>
              </div>
            {/if}
          </div>
        </div>

        {#if imagesLoading}
          <div
            class='h-100 w-100 d-flex align-items-center justify-content-center'>
            <LoadingSpinner />
          </div>
        {:else if filteredImages && filteredImages.length}
          <ImageGallery images={filteredImages} />
          {#if infiniteScrollLoading}
            <div
              class=' my-2 w-100 d-flex align-items-center
              justify-content-center'>
              <LoadingSpinner />
            </div>
          {/if}
        {:else}
          <div
            class='mt-5 pt-5 w-100 d-flex align-items-center
            justify-content-center'>
            <h2 id='empty-images-message' class='d-block w-100 text-center'>
              The selected search criteria doesn't show any results
            </h2>
          </div>
        {/if}
      {/if}
    {/if}
  </div>
</MainScreen>
