<script>
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
  import CameraViewerImageModal from './CameraViewerImageModal.svelte';

  export let imageUrl = null;

  let imageModalShow = false;
</script>

<style>
  .media-control-box{
    height: var(--cox2m-spacing-10-units);
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: var(--cox2m-spacing-8-units);
    border-top: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-300);
  }
</style>

{#if imageUrl}
<CameraViewerImageModal bind:show={imageModalShow} image={imageUrl} />
{/if}

<div
  class="media-control-box justify-content-end cursor-pointer">
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <span
    on:click={() => (imageModalShow = true)}>
    <Icon
      icon="external-link-2"
      color="var(--cox2m-clr-neutral-black)"
      size="var(--cox2m-spacing-6-units)" />
  </span>
</div>
