<script>
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
  import ImageFrame from './ImageFrame.svelte';

  import {onMount, onDestroy} from 'svelte';

  export let show;
  export let image;

  let modalContent;

  const clickOutsideHandler = e => {
    if (show && !modalContent.contains(e.target)) {
      show = false;
    }
  };

  onMount(() => {
    document
      .querySelector('#image-modal.fade')
      .addEventListener('click', clickOutsideHandler);
  });

  onDestroy(() => {
    try {
      document
        .querySelector('#image-modal.fade')
        .removeEventListener('click', clickOutsideHandler);
    } catch (_) {
      console.log('cannot remove event listener');
    }
  });
</script>

<style>
  .modal-container {
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }
  .modal-content {
    border-radius: 1rem;
    top: 50%;
    transform: translateY(-50%);
    position: fixed;
    width: 1140px;
  }

  .modal-header {
    justify-content: flex-end;
  }

  .modal-dialog {
    width: 1140px;
    height: 920px;
    margin: auto;
    max-width: unset;
  }
  @media only screen and (max-width: 1200px) {
    .modal-content {
      width: 100vw !important;
      height: auto;
      position: fixed;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      max-height: 100vh;
    }
  }
</style>

<div class="modal-container w-100 h-100 position-fixed {show ? '' : 'd-none'}">
  <div
    class="modal fade d-block {show ? 'show' : ''}"
    id="image-modal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div bind:this={modalContent} class=" modal-content border-0 m-auto">
        <div class="modal-header py-2 border-0">
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <span
            class="close btn"
            data-dismiss="modal"
            aria-label="Close"
            on:click={() => (show = false)}>
            <Icon
              icon="cross"
              size="var(--cox2m-spacing-5-units)"
              color="var(--cox2m-clr-neutral-black)" />
          </span>

        </div>
        <div class="modal-body pt-0">
          <ImageFrame
            imageModal
            style={`padding-right: 0 !important;
            margin-bottom: 0 !important;
            border-bottom-right-radius: 1rem;
            border-bottom-left-radius: 1rem;`}
            className="cox2m-shadow-1-dp h-100"
            {image} />
        </div>

      </div>
    </div>
  </div>
</div>
