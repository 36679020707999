<script>
  import MapMarkersJourney from '@cox2m/city-services-ui-components/src/components/maps/arcgis/MapMarkersJourney.svelte';
  import SearchableSelectBadge from '@cox2m/city-services-ui-components/src/components/SearchableSelectBadge.svelte';
  import DateTimePicker from '@cox2m/city-services-ui-components/src/components/time/DateTimePicker.svelte';
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import SelectBadge from '@cox2m/city-services-ui-components/src/components/SelectBadge.svelte';
  import MapManualCenter from '@cox2m/city-services-ui-components/src/components/maps/arcgis/MapManualCenter.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
  import AlertPoliceModal from './components/events/AlertPoliceModal.svelte';
  import GroupedEventCard from './components/GroupedEventCard.svelte';
  import ShareModal from './components/events/ShareModal.svelte';
  import CameraViewer from './components/CameraViewer.svelte';
  import axios from 'axios';

  import {
    getCookieByName,
    setErrorEventData,
    setSuccessToast
  } from '@cox2m/city-services-ui-components/src/funcs';
  import {
    getCameras,
    getMinEventDate,
    dismissEvent,
    getObjectsCoordinatesUsingFilter,
    getEventsCoordinatesGroupedUsingFilter
  } from '../actions';
  import {onDestroy, onMount, createEventDispatcher} from 'svelte';
  import {location, querystring} from 'svelte-spa-router';
  import {menuOpts, selectedPark, user} from '../stores';
  import {PARK_JOURNEY_MARKER_TYPE} from '../constants';

  import {
    buildSocketUrl,
    cameraSortFunction,
    clearSockets,
    formatDateString,
    getPoliceUsers,
    setEventsWebsocket,
    setTimeNow,
    typeOptions,
    getMapMarkerCollection
  } from './components/scripts/events-screen/events-screen-funcs';
  import SiteIndicator from './components/shared/SiteIndicator.svelte';
  import GroupEventDetail from './components/GroupEventDetail.svelte';

  export let params = {};
  export let from = null;
  export let to = null;

  const token = getCookieByName(`${'ENV'}_token`);
  const useViolationEvents =
    new URLSearchParams($querystring).get('violation-events') || true;

  const authMessage = `{"auth" : "${token}"}`;
  const ONE_DAY_MS = 1000 * 60 * 60 * 24;
  const headers = {
    headers: {
      Authorization: token
    }
  };
  const INITIAL_ZOOM_VALUE = 18;

  let eventsWebsocket = null;
  let initialParkNameQueryString = new URLSearchParams($querystring).get(
    'sitename'
  );
  let startDate = new Date();
  let currentDate; //this initial dates are handled by setDatesAccordingToLocation
  let finishDate; //this initial dates are handled by setDatesAccordingToLocation
  let userPickedTime;

  let hasSelectedEvent = false;
  let windowWidth;

  let eventsResponse;
  let filteredEvents = [];
  let events = [];

  let isDetailModalOpen = false;

  let liveEventsActive = false;
  let eventsFetchingError = false;
  let eventsLoading = true;

  let cameraOptionsError = false;
  let loadingOptions = true;

  let allEventsSelected = true;
  let selectedEvent = null;

  let selectedCamera = null;
  let selectedType = null;

  let camerasWithEvents = [];

  let filteredCameraOptions = [];
  let cameraOptions = [];

  let policeUsers = [];

  let mapView = undefined;
  let shouldShowVideo = false;

  let isSiteLoading;
  let isSiteOnError;
  let queryParams;
  let initialTime;
  let finalTime;

  let expanded = false;
  let shareModalShow = false;
  let smsModalShow = false;

  let selectedGroupedEventIndex = 0;
  let groupedEvents = [];
  let groupedEventsLoading = true;
  let groupedEventsFetchError = false;

  let zoom = INITIAL_ZOOM_VALUE;
  let center = undefined;

  const formatDateStringPicker = date => {
    let dateToFormat = new Date(date);
    let tempDate = new Date(
      dateToFormat.getFullYear(),
      dateToFormat.getMonth(),
      dateToFormat.getDate()
    );
    let formattedDate = `${tempDate.getFullYear()}/${tempDate.getMonth() +
      1}/${tempDate.getDate()}`;
    return formattedDate;
  };

  const TODAY_FORMATTED_DATE = formatDateStringPicker(new Date());

  const setDatesAccordingToLocation = ({resetDates = false}) => {
    queryParams = new URLSearchParams($querystring);
    if (!params.id || resetDates) {
      currentDate = new Date(new Date() - ONE_DAY_MS);
      initialTime = '12:00 am';
      from = currentDate.toISOString().replace(/T.*/gi, 'T00:00:00.000');

      finishDate = new Date();
      finalTime = '11:59 pm';
      to = finishDate.toISOString().replace(/T.*/gi, 'T23:59:59.999');
    }
  };

  const removeEvent = eventToRemoveId => {
    events = events.filter(event => event.id !== eventToRemoveId);
    filteredEvents = filteredEvents.filter(
      event => event.id !== eventToRemoveId
    );
  };

  const handleAllEvensFilter = () => {
    if (!allEventsSelected) {
      allEventsSelected = true;
      filteredEvents = events;
      selectedCamera = null;
      selectedType = null;
    }
  };

  const eventFetchBuilder = async () => {
    let initialTime = '00:00:00.000';
    let finalTime = '23:59:59.999';

    if (from) {
      initialTime = new Date(from).toTimeString().substring(0, 8) + '.000';
    }
    if (to) {
      finalTime = new Date(to).toTimeString().substring(0, 8) + '.999';
    }

    let fetchOptions = {
      limit: 500,
      since: currentDate
        ? formatDateString({
            isInitialDate: true,
            date: from ? from : new Date(currentDate).toISOString(),
            userPickedTime,
            initialTime,
            finalTime
          })
        : setTimeNow(),
        ...((to || finishDate) && {until: formatDateString({
        isInitialDate: false,
        date: to ? to : new Date(finishDate).toISOString(),
        userPickedTime,
        initialTime,
        finalTime
      })}),
      filter: `${selectedCamera ? `camera.name:${selectedCamera.label}` : ''}`,
      type: selectedType ? selectedType.value : null,
      siteName: $selectedPark.name,
      isViolation: useViolationEvents
    };

    return await getObjectsCoordinatesUsingFilter(token, fetchOptions);
  };

  const filterByCameraAndType = async () => {
    eventsLoading = true;
    camerasWithEvents = [];
    liveEventsActive = false;

    if (selectedCamera || selectedType) {
      allEventsSelected = false;
      let filteredEventsResponse = await eventFetchBuilder();
      filteredEvents = filteredEventsResponse.events;
      getCamerasWithEvents();
      eventsLoading = false;
    } else {
      allEventsSelected = true;
      await getEventsResponse();
      filteredEvents = events;
      camerasWithEvents = [];
      eventsLoading = false;
    }
  };

  const getCamerasWithEvents = () => {
    if (filteredEvents) {
      filteredEvents.map(event => {
        if (event.camera && event.camera.id) {
          camerasWithEvents = [...camerasWithEvents, event.camera.id];
        }
      });
      camerasWithEvents = Array.from(new Set(camerasWithEvents));
    }
    filterCameraOptions();
  };

  const filterCameraOptions = () => {
    filteredCameraOptions = [];

    camerasWithEvents.forEach(cameraId => {
      let foundCamera = cameraOptions.find(camera => camera.value === cameraId);
      if (foundCamera) {
        filteredCameraOptions = [...filteredCameraOptions, foundCamera];
      }
    });
  };

  const socketMessageHandler = event => {
    let eventData = JSON.parse(event.data);

    if (eventData.camera.siteName === $selectedPark.name) {
      if (
        !filteredEvents.some(event => event.id === eventData.id) &&
        new Date(finishDate).toDateString() === new Date().toDateString() &&
        liveEventsActive
      ) {
        filteredEvents = [eventData, ...filteredEvents];
        getCamerasWithEvents();
      }
    }
  };

  const setEventsWebsocketHandler = () => {
    eventsWebsocket = setEventsWebsocket({
      eventsWebsocket,
      socketUrl: buildSocketUrl({
        selectedType,
        selectedCamera,
        useViolationEvents
      }),
      authMessage,
      liveEventsActive,
      onMessageHandler: socketMessageHandler
    });
  };

  const getEventsResponse = async () => {
    eventsFetchingError = false;
    eventsLoading = true;
    eventsResponse = await eventFetchBuilder();

    if (eventsResponse.fulfilled) {
      events = eventsResponse.events;
      if (params && params.id) {
        selectedEvent = events.find(event => event.frameId === params.id);
      } else if (useViolationEvents) {
        selectedEvent = events[0];
      }
      filteredEvents = eventsResponse.events;
    } else {
      eventsFetchingError = true;
    }
    eventsLoading = false;
  };

  const eventsScreenSetup = async () => {
    loadingOptions = true;
    allEventsSelected = true;
    selectedCamera = null;
    selectedType = null;
    liveEventsActive = false;

    let policeUsersResponse = await getPoliceUsers({axios, headers});
    policeUsers = policeUsersResponse.data;

    if (!policeUsersResponse.fulfilled) {
      setErrorEventData(
        window.dispatchEvent,
        policeUsersResponse.error.response,
        'get-user'
      );
    }

    const [cameraResponse, startDateResponse] = await Promise.all([
      getCameras(token, {siteName: $selectedPark.name}),
      getMinEventDate(token, {siteName: $selectedPark.name})
    ]);

    if (cameraResponse.fulfilled && startDateResponse.fulfilled) {
      cameraOptions = cameraResponse.cameras.map(camera => ({
        label: camera.name,
        value: camera.id
      }));

      startDate = new Date(startDateResponse.data.date);
      loadingOptions = false;
    } else {
      cameraOptionsError = true;
    }
  };

  const dispatch = createEventDispatcher();

  const dismissAlert = async () => {
    const dismissEventResponse = await dismissEvent(token, event.id);
    if (dismissEventResponse.fulfilled) {
      expanded = false;
      dispatch('eventDeleted', {
        eventId: event.id
      });
      setSuccessToast(window.dispatchEvent, 'Alert dispatched successfully.');
    }
  };

  const getGroupedEventsOfObject = async () => {
    if (
      selectedEvent &&
      groupedEvents &&
      !groupedEvents.some(
        groupedEvent => groupedEvent.cardId === selectedEvent.cardId
      )
    ) {
      groupedEventsLoading = true;

      let groupedEventsResponse = await getEventsCoordinatesGroupedUsingFilter({
        token,
        type: selectedEvent.type.toLowerCase(),
        objectId: selectedEvent.cardId,
        parentId: selectedEvent.id
      });

      groupedEventsFetchError = !groupedEventsResponse.fulfilled;

      if (groupedEventsResponse.fulfilled) {
        groupedEvents = groupedEventsResponse.events;
      } else {
        groupedEvents = [];
      }

      groupedEventsLoading = false;
    }
  };

  onMount(async () => {
    setDatesAccordingToLocation({resetDates: true});
    window.addEventListener('clearSockets', () =>
      clearSockets(eventsWebsocket)
    );
  });

  onDestroy(() => {
    eventsWebsocket && eventsWebsocket.close();
  });

  $: setDatesAccordingToLocation($location, $selectedPark.name);
  $: eventsScreenSetup(useViolationEvents, $selectedPark.name);
  $: filterByCameraAndType(
    selectedCamera,
    selectedType,
    currentDate,
    finishDate,
    userPickedTime,
    useViolationEvents,
    $selectedPark.name
  );

  $: setEventsWebsocketHandler(liveEventsActive, $selectedPark.name);
  $: $selectedPark && $selectedPark.name && getCamerasWithEvents();
  $: getGroupedEventsOfObject(selectedEvent);

  $: showNoViolationsThatDayMessage =
    allEventsSelected &&
    filteredEvents.length == 0 &&
    (!userPickedTime ||
      (userPickedTime &&
        userPickedTime.selectedEndTime.userFriendlyFormat == '11:59 pm' &&
        userPickedTime.selectedStartTime.userFriendlyFormat == '12:00 am'));
</script>

<style>
  /* globals */
  :global(.date-time-picker-container > .row) {
    margin-left: 0 !important;
  }

  :global(.date-time-picker-container .box) {
    z-index: var(--cox2m-z-index-1) !important;
  }

  /* layout */
  .page-content-container {
    display: grid;
    grid-template-areas:
      'filters filters'
      'viewer  map'
      'cards   group-detail';

    grid-template-columns: 60% 1fr;
    grid-template-rows: auto 1fr;
    min-height: 100%;
    column-gap: var(--cox2m-spacing-5-units);
    row-gap: var(--cox2m-spacing-5-units);
  }
  .camera-viewer-container {
    grid-area: viewer;
    position: relative;
  }
  .map-container {
    grid-area: map;
    min-height: 240px;
    height: 100%;
  }

  .cards-container {
    grid-area: cards;
    height: 100%;
  }

  .group-detail-container {
    grid-area: group-detail;
    background-color: var(--cox2m-clr-brand-200);
    margin-top: var(--cox2m-spacing-1-unit);
    border-radius: 21px;
    padding: var(--cox2m-spacing-6-units) var(--cox2m-spacing-4-units);
    display: grid;
    grid-template-rows: auto 1fr;
  }

  /* styles */
  .loading-options-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--cox2m-spacing-6-units);
  }
  #event-cards-container {
    overflow: auto;
    overflow-x: hidden;
    max-height: 400px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: var(--cox2m-spacing-6-units);
    padding: var(--cox2m-spacing-1-unit);
    padding-left: 2px;
  }
  #event-cards-container::-webkit-scrollbar {
    display: none;
  }
  #event-cards-container:hover::-webkit-scrollbar {
    display: initial;
  }

  #filters-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--cox2m-spacing-2-units);
    grid-area: filters;
  }
  .date-time-picker-container {
    display: flex;
    align-items: center;
    gap: var(--cox2m-spacing-6-units);
  }
  .selector-badges-container {
    display: flex;
    align-items: center;
    gap: var(--cox2m-spacing-2-units);
  }

  .all-badge-filter {
    color: var(--cox2m-clr-neutral-black);
    border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-brand-400);
    border-radius: 54px;
  }

  .all-badge-filter.active {
    background-color: var(--cox2m-clr-brand-600);
    border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-brand-600);
    color: var(--cox2m-clr-neutral-white);
  }
  #events-empty-message {
    color: var(--cox2m-clr-neutral-600);
  }
  #events-page-error-message {
    color: var(--cox2m-clr-critical-500);
  }

  :global(.esri-popup__footer) {
    display: none !important;
  }
  :global(#filters-container .date-time-picker-container #date-picker) {
    padding-left: 0 !important;
  }

  .event-actions-mobile {
    position: fixed;
    width: 100vw;
    bottom: 0;
    left: 0;
    height: auto !important;
    z-index: 10;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: var(--cox2m-spacing-4-units);
    padding-right: var(--cox2m-spacing-4-units);
  }

  .event-actions-mobile-header {
    display: flex;
    justify-content: space-between;
    padding-left: var(--cox2m-spacing-2);
    padding-right: var(--cox2m-spacing-2);
    height: 60px;
  }

  .action-card-mobile {
    display: flex;
    margin-left: var(--cox2m-spacing-2);
    height: 48px;
    color: rgba(0, 0, 0, 0.6);
  }

  .action-card-mobile-text {
    margin-left: 36px;
    font-size: var(--cox2m-smalltext-1);
    font-weight: 600;
  }

  .no-violations-that-day {
    grid-column: -1 / 1;
    margin-top: var(--cox2m-spacing-8-units);
  }

  .control-icon-container{
    padding: var(--cox2m-spacing-3-units);
    color: var(--cox2m-clr-neutral-white);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    background: var(--cox2m-clr-neutral-white);
  }

  .control-icon-container:first-of-type{
    left: -24px;
  }
  .control-icon-container:last-of-type{
    right: -24px;
  }

  @media only screen and (max-width: 1200px) {
    :global(#events-time-picker) {
      flex-grow: 1;
      justify-content: center;
    }
    :global(.date-time-picker-container > .position-relative) {
      width: 100%;
    }

    .page-content-container {
      grid-template-areas:
        'map'
        'filters'
        'cards'
        'group-detail';
      grid-template-columns: 100%;
      grid-template-rows: 1fr auto auto;
      column-gap: 0;
      row-gap: var(--cox2m-spacing-6-units);
    }

    .date-time-picker-container {
      flex-wrap: wrap;
    }
    #filters-container {
      flex-direction: column;
      row-gap: var(--cox2m-spacing-6-units);
    }
    .selector-badges-container {
      margin-bottom: var(--cox2m-spacing-6-units);
    }
    #event-cards-container::-webkit-scrollbar,
    #event-cards-container:hover::-webkit-scrollbar {
      display: initial !important;
    }
    .camera-viewer-container {
      display: none;
    }

    #event-cards-container {
      flex-direction: row;
      gap: var(--cox2m-spacing-2-units);
      height: 300px;
    }
    .map-container {
      height: calc(100vh - 554px);
    }
    .back-icon-mobile {
      font-size: var(--cox2m-fs-800);
      font-weight: var(--cox2m-fw-bold);
      color: var(--cox2m-clr-neutral-black);
    }
    .back-icon-container {
      position: relative;
      left: calc(var(--cox2m-spacing-1-units) * (-1));
      margin-bottom: var(--cox2m-spacing-2-units);
    }
    .group-detail-container {
      background-color: transparent;
      margin-top: calc(var(--cox2m-spacing-15-units) * (-1));
      padding: 0;
    }
  }
</style>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<svelte:window bind:innerWidth={windowWidth} />

<MainScreen
  title="Violations"
  menuOpt={$menuOpts}
  showAlertsBanner={false}
  user={$user}
  appSlug="smart-security">
  <div slot="main-dashboard">
    <div>
      <SiteIndicator
        title={'Violations'}
        bind:isLoading={isSiteLoading}
        bind:isOnError={isSiteOnError}
        pickedSite={initialParkNameQueryString ? decodeURI(initialParkNameQueryString) : null} />
    </div>

    <div class="page-content-container" id="events-page-container">
      {#if !loadingOptions && !cameraOptionsError && !isSiteLoading}
        <div id="filters-container" class="filters-container">
          <div class="date-time-picker-container">
            <DateTimePicker
              on:confirm-selection-change={e => {
                e.detail.latestValidFirstSelectedDate && (currentDate = e.detail.latestValidFirstSelectedDate);
                e.detail.latestValidLatestSelectedDate && (finishDate = e.detail.latestValidLatestSelectedDate);
                userPickedTime = {selectedStartTime: e.detail.selectedStartTime, selectedEndTime: e.detail.selectedEndTime};
                from = null;
                to = null;
              }}
              firstSelectedDate={formatDateStringPicker(currentDate)}
              latestSelectedDate={formatDateStringPicker(finishDate)}
              endCalendarAt={TODAY_FORMATTED_DATE}
              initialStartTime={initialTime}
              initialEndTime={finalTime}
              timeRange={true} />
          </div>

          <div class="selector-badges-container">
            <span class="d-flex filter-icon-container">
              <Icon
                icon="sliders-horiz"
                size="var(--cox2m-spacing-5-units)"
                color="var(--cox2m-clr-neutral-500)" />
            </span>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <span
              class="reset-filter-badge d-flex"
              on:click={() => handleAllEvensFilter()}>
              <span
                class="all-badge-filter d-flex align-items-center
                justify-content-center cox2m-smalltext-1 {allEventsSelected ? 'active' : ''}
                px-3 py-0 pointer">
                All
              </span>
            </span>
            <span class="d-flex">
              <SearchableSelectBadge
                bind:selectedOption={selectedCamera}
                id="camera-filter-badge"
                options={allEventsSelected ? cameraOptions.sort(cameraSortFunction) : filteredCameraOptions.sort(cameraSortFunction)}
                defaultLabel="Camera"
                responsiveTitle="Filter by camera" />
            </span>
            <span class="d-flex">
              <SelectBadge
                bind:selectedOption={selectedType}
                id="type-filter-badge"
                options={typeOptions}
                defaultLabel="Type"
                responsiveTitle="Filter by type" />
            </span>
          </div>
        </div>
      {:else}
        <div class="loading-options-container">
          <LoadingSpinner />
        </div>
      {/if}

      {#if showNoViolationsThatDayMessage}
        <h2 class="w-100 text-center no-violations-that-day">
          There are no {useViolationEvents ? 'violations' : 'events'} for the
          selected days.
        </h2>
      {:else}
        <div class="camera-viewer-container">
          {#if selectedGroupedEventIndex > 0 }
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div class="control-icon-container cox2m-shadow-1-dp" on:click={() => {selectedGroupedEventIndex = selectedGroupedEventIndex - 1; selectedEvent = groupedEvents[selectedGroupedEventIndex]}}>
            <Icon icon="chevron-left" color="var(--cox2m-clr-brand-500)" size="var(--cox2m-spacing-7-units)" />
          </div>
          {/if}

          <CameraViewer
            {token}
            {camerasWithEvents}
            {allEventsSelected}
            {shouldShowVideo}
            activateCameraCarousel={!useViolationEvents}
            enableLinkToLive={useViolationEvents}
            height="300px"
            selectedEvent={{...selectedEvent, id: selectedEvent.frameId, isViolationEvent: useViolationEvents === 'true'}}
            on:video-element-error={error => {
              shouldShowVideo = false;
              setErrorEventData(window.dispatchEvent, error, '', null, 'video not available');
            }} />

            {#if selectedGroupedEventIndex < groupedEvents.length - 1 }
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div class="control-icon-container cox2m-shadow-1-dp" on:click={() => {selectedGroupedEventIndex = selectedGroupedEventIndex + 1; selectedEvent = groupedEvents[selectedGroupedEventIndex]}}>
              <Icon icon="chevron-right" color="var(--cox2m-clr-brand-500)"  size="var(--cox2m-spacing-7-units)" />
            </div>
            {/if}
        </div>
        <div id="map-container" class="map-container">
          {#if selectedEvent && !isDetailModalOpen}
            <MapManualCenter
              {zoom}
              center={center ? center : [selectedEvent.camera.longitude, selectedEvent.camera.latitude]}
              basemap="hybrid"
              bind:view={mapView}>
              <MapMarkersJourney
                collection={getMapMarkerCollection(groupedEvents, selectedGroupedEventIndex)}
                onMarkerClick={data => {
                  selectedEvent = data;
                  selectedGroupedEventIndex = data.index;
                }}
                markerConfigs={PARK_JOURNEY_MARKER_TYPE}
                on:zoom-change={({detail}) => {
                  zoom = detail.zoom;
                }} />
            </MapManualCenter>
          {/if}
        </div>
        <div class="cards-container">

          <div
            class="event-cards-container"
            id="event-cards-container"
            class:d-none={windowWidth <= 1200 && hasSelectedEvent}>
            {#if eventsLoading || isSiteLoading}
              <div class="d-flex justify-content-center align-items-center">
                <LoadingSpinner />
              </div>
            {:else if filteredEvents && filteredEvents.length === 0 && !eventsFetchingError && !isSiteOnError}
              <span id="events-empty-message" class="d-block w-100 text-center">
                The selected criteria doesn't show any results
              </span>
            {:else if filteredEvents && !eventsFetchingError && !isSiteOnError}
              {#each filteredEvents as event}
                <GroupedEventCard
                  selected={selectedEvent && selectedEvent.id === event.id || groupedEvents.length && groupedEvents[0].parentId === event.id}
                  isViolationEvent={useViolationEvents}
                  on:eventSelected={e => {
                    windowWidth <= 1200 && (hasSelectedEvent = true);
                    selectedEvent = e.detail.event;
                    selectedGroupedEventIndex = 0;
                    zoom = INITIAL_ZOOM_VALUE;
                    center = [e.detail.event.camera.longitude, e.detail.event.camera.latitude]
                    shouldShowVideo = false;
                  }}
                  on:eventDeleted={e => removeEvent(e.detail.eventId)}
                  showExpand={windowWidth > 1200}
                  authorities={policeUsers}
                  bind:shareModalShow
                  bind:smsModalShow
                  {event} />
              {/each}
            {:else if eventsFetchingError || !filteredEvents || isSiteOnError}
              <span
                id="events-page-error-message"
                class="d-block w-100 text-center">
                We are sorry, we couldn't get the events information, please
                reload the page or try again later.
              </span>
            {/if}
          </div>
        </div>

        <div
          class="group-detail-container"
          class:d-none={(windowWidth <= 1200 && !hasSelectedEvent) || (eventsLoading && !hasSelectedEvent)}>
          {#if windowWidth <= 1200}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <span
              class="back-icon-container d-flex align-items-center
              cursor-pointer">
              <Icon
                icon="chevron-left"
                size="var(--cox2m-spacing-5-units)"
                color="var(--cox2m-clr-neutral-black)"
                on:click={() => (hasSelectedEvent = false)} />
              <span
                class="back-icon-mobile"
                on:click={() => (hasSelectedEvent = false)}>
                Back
              </span>
            </span>
          {/if}
          <GroupEventDetail
            event={selectedEvent}
            {windowWidth}
            groupList={groupedEvents}
            loading={groupedEventsLoading}
            onError={groupedEventsFetchError}
            bind:shouldShowVideo
            bind:selectedEventIndex={selectedGroupedEventIndex}
            on:open-group-menu={() => (expanded = true)}
            on:change-selected-event={e => {selectedEvent = e.detail; center = [e.detail.longitude, e.detail.latitude]}}
             />

          {#if hasSelectedEvent && expanded}
            <div
              class="event-actions-mobile card"
              id={`${event.id}-event-actions-mobile`}>

              <div
                class="event-actions-mobile-header d-flex align-items-center">
                <span class="font-weight-bold">Menu</span>
                <Icon
                  icon="cross"
                  size="var(--cox2m-spacing-6-units)"
                  onClick={() => (expanded = !expanded)} />
              </div>
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <div
                id={`${event.id}-alert-action-button-mobile`}
                data-toggle="modal"
                data-target={`#${event.id}-share-modal`}
                on:click={() => {
                  shareModalShow = true;
                }}
                class="action-card action-card-mobile">
                <Icon
                  icon="direction-2"
                  size="24px"
                  color="rgba(0, 0, 0, 0.6)"
                  className="event-card-icon-hover" />
                <p class="action-card-mobile-text">Share Alert</p>
              </div>
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <div
                id={`${event.id}-alert-action-button-mobile`}
                on:click={() => (smsModalShow = true)}
                class="action-card action-card-mobile">
                <Icon
                  icon={'comment-text'}
                  size="24px"
                  color="rgba(0, 0, 0, 0.6)"
                  className="event-card-icon-hover" />
                <p class="action-card-mobile-text">Alert Police</p>
              </div>
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <div
                id={`${event.id}-dismiss-action-button-mobile`}
                on:click={() => dismissAlert()}
                class="action-card action-card-mobile">
                <Icon
                  icon="cross"
                  size="24px"
                  color="rgba(0, 0, 0, 0.6)"
                  className="event-card-icon-hover" />
                <p class="action-card-mobile-text">Dismiss Violation</p>
              </div>
              <!-- svelte-ignore a11y-click-events-have-key-events -->
            </div>
          {/if}
        </div>
      {/if}

    </div>
    {#if shareModalShow && selectedEvent}
      <ShareModal bind:show={shareModalShow} event={selectedEvent} />
    {/if}
    {#if smsModalShow && selectedEvent}
      <AlertPoliceModal
        authorities={policeUsers}
        bind:show={smsModalShow}
        event={selectedEvent} />
    {/if}
  </div>

</MainScreen>
