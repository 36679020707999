<script>
  import Button from '@cox2m/city-services-ui-components/src/components/Button.svelte';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';

  export let show = false;
</script>

<style>
  .modal-content {
    box-shadow: var(--cox2m-shadow-6-dp-dir) var(--cox2m-shadow-6-dp-clr);
    max-width: 680px;
  }
  .disclaimer-icon {
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
  }
  .modal-body {
    padding: var(--cox2m-spacing-2-units) var(--cox2m-spacing-4-units);
  }

  .modal-backdrop {
    z-index: 1100;
  }
  .modal.fade.show {
    z-index: 1101;
  }
  .fs-1000 {
    font-size: var(--cox2m-fs-1000);
  }
</style>

{#if show}
  <div class="modal-backdrop fade show" />
  <div
    class="modal fade show d-block"
    id="inexistent-event-modal"
    data-testid="inexistent-event-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="inexistent-event-modal-title"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content pl-4">
        <div class="modal-body">
          <div class="row m-0 justify-content-between align-items-center">
            <h5
              class="modal-title font-weight-bold position-relative"
              id="inexistent-event-modal-title"
              data-testid="inexistent-event-modal-title">
              <span class="position-absolute disclaimer-icon">
                <Icon
                  icon="info"
                  size="var(--cox2m-spacing-6-units)"
                  color="var(--cox2m-primary-500)" />
              </span>
              Inexistent event
            </h5>
            <Icon
              icon="cross"
              size="calc(var(--cox2m-spacing-4-units) +
              var(--cox2m-visual-corrector))"
              className="m-0 p-0"
              id="inexistent-event-modal-close"
              onClick={() => (show = false)}
              color="var(--cox2m-clr-neutral-black)" />
          </div>
          <p class="disclaimer fs-1000 mt-3">
            The event that you were looking doesn't exist anymore, it was
            deleted according to the data retention policies.
          </p>
          <p class="disclaimer fs-1000 mt-3">
            we opened the page keeping your previous settings.
          </p>
        </div>
        <div class="modal-footer pt-0 mt-0 border-0">
          <div class="row m-0 align-items-center pr-1">
            <div class="d-flex">
              <Button
                customClass="mr-3 "
                color="var(--cox2m-clr-brand-600)"
                backgroundColor="var(--cox2m-clr-neutral-white)"
                borderColor="var(--cox2m-clr-brand-400)"
                text="Close"
                id="close-inexistent-event-modal"
                height="var(--cox2m-spacing-8-units)"
                on:click={() => (show = false)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}
