<script>
  import ThoughtSpotComponent from '@cox2m/city-services-ui-components/src/components/thoughtspot/ThoughtspotWrapper.svelte';

  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';

  import {menuOpts, selectedPark, user} from '../stores';

  let runtimeFilters = [{
    columnName: 'Camera Site',
    operator: 'IN',
    values: [$selectedPark.name]
  }]
</script>

<Modal>
  <MainScreen title="Test ThoughtSpot" menuOpt={$menuOpts} showAlertsBanner={false} user={$user} appSlug="smart-security">
    <div slot="main-dashboard" class="w-100 h-100">
      <ThoughtSpotComponent liveboardId='28a6e0f7-25f2-4ec2-9069-b2bce863b57d' {runtimeFilters}/>
    </div>
  </MainScreen>

</Modal>
