<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import TextInput from '@cox2m/city-services-ui-components/src/forms/TextInput.svelte';
  import Button from '@cox2m/city-services-ui-components/src/components/Button.svelte';
  import ScalableDataTable from '@cox2m/city-services-ui-components/src/components/ScalableDataTable.svelte';
  import DateColumn from './components/license-plates/DateColumn.svelte';
  import PlateImage from './components/license-plates/PlateImage.svelte';
  import SiteIndicator from './components/shared/SiteIndicator.svelte';
  import EventImage from "./components/license-plates/EventImage.svelte";

  import {getCookieByName} from '@cox2m/city-services-ui-components/src/funcs';
  import {exportVehiclesUsingFilter, getVehiclesUsingFilter} from '../actions';
  import {menuOpts, selectedPark, user} from '../stores';

  const token = getCookieByName(`${'ENV'}_token`);
  const INITIAL_ITEMS_PER_PAGE = 20;

  let loading = true;
  let exportLoading = false;
  let error = false;
  let rows = [];
  let noItems = false;
  let tempSearchCriteria = '';
  let searchCriteria = '';
  let exportCsv = null;
  let isSiteLoading;
  let isSiteOnError;
  let paginationResponse;
  let sortString = 'createdAt:desc';
  let paginationOptions = {
    limit: INITIAL_ITEMS_PER_PAGE,
    offset: 0
  };

  const columns = [
    {label: 'Plate Number', key: 'plateNumber', type: 'text', notSortable: true},
    {
      label: 'Plate Image',
      key: 'plateNumber',
      type: 'text',
      Component: PlateImage,
      notSortable: true
    },
    {
      label: 'Image',
      key: 'imageUrl',
      type: 'text',
      Component: EventImage
    },
    {label: 'Vehicle Id', key: 'id', type: 'text'},
    {label: 'Vehicle Type', key: 'vehicleType', type: 'text'},
    {label: 'Vehicle Color', key: 'color', type: 'text'},
    {label: 'Plate Region', key: 'region', type: 'text', notSortable: true},
    {label: 'License Plate Confidence', key: 'licensePlateConfidence', type: 'text', notSortable: true},
    {label: 'Timestamp', key: 'createdAt', type: 'date', Component: DateColumn}
  ];

  const formatDateString = date =>
    date
      .toLocaleString()
      .replace('T', ' ')
      .split('.')[0];

  const exportLicensePlateEntries = async plateNumber => {
    loading = true;
    const response = await exportVehiclesUsingFilter(token, {
      shouldIncludeLicensePlate: true,
      licensePlate: plateNumber,
      ...($selectedPark && $selectedPark.name ? {'siteName': $selectedPark.name} : {})
    });

    if (response.fulfilled) {
      exportCsv = encodeURI(response.csv);
    } else {
      error = true;
    }
    loading = false;
  };

  const loadLicensePlateEntries = async plateNumber => {
    loading = true;
    const response = await getVehiclesUsingFilter(token, {
      shouldIncludeLicensePlate: true,
      licensePlate: plateNumber,
      limit: paginationOptions.limit,
      offset: paginationOptions.offset,
      sort: sortString,
      ...($selectedPark && $selectedPark.name ? {'siteName': $selectedPark.name} : {})
    });

    if (response.fulfilled) {
      const {vehicles, paging} = response;
      paginationResponse = paging;

      noItems = !vehicles || (!vehicles.length);
      rows = vehicles.map((vehicle, index) => ({
        ...vehicle,
        plateNumber: vehicle.licensePlate.value,
        region: vehicle.licensePlate.region,
        licensePlateConfidence: vehicle.licensePlate.confidence,
        timestamp: formatDateString(vehicle.createdAt),
        index
      }));
    } else {
      error = true;
    }
    loading = false;
  };

  const handleExport = async () => {
    exportLoading = true;
    await exportLicensePlateEntries(searchCriteria);
    exportLoading = false;

    const a = document.createElement('a');
    a.setAttribute('data-testid', 'license-plate-table-export-link');
    a.href = 'data:text/csv;charset=utf-8,' + exportCsv;
    a.download = `license_plate_list_${
      searchCriteria ? searchCriteria + '_' : ''
    }${new Date().toISOString()}.csv`;
    a.click();
  };

  const handleFetchDataEvent = async e => {
    loading = true;
    paginationOptions = e.detail.dataPositioning;
    await loadLicensePlateEntries(searchCriteria);
  };

  const updateSortingField = async e => {
    sortString = e.detail.sortString;
    await loadLicensePlateEntries(searchCriteria);
  };

  $: loadLicensePlateEntries(searchCriteria, $selectedPark);
</script>

<style>
  :global(#license-plate-table) {
    text-align: center;
  }

  .dashboard-component-container {
    background-color: var(--cox2m-clr-neutral-white);
    border-radius: 16px;
    padding: var(--cox2m-spacing-8-units) 0;
    min-height: 100%;
    box-shadow: var(--cox2m-shadow-3-dp-dir) var(--cox2m-shadow-3-dp-clr);
  }
  header{
    margin-bottom: var(--cox2m-spacing-4-units);
  }
</style>

<MainScreen title="License Plates" menuOpt={$menuOpts} user={$user} showAlertsBanner={false} appSlug="smart-security">
  <div slot="main-dashboard">
    <div class="w-100">
      <SiteIndicator title="License Plates" bind:isLoading={isSiteLoading} bind:isOnError={isSiteOnError}/>
    </div>

    <div class="dashboard-component-container">
      {#if loading || isSiteLoading}
        <div class="d-flex w-100 h-100 align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      {:else if !error && !isSiteOnError && noItems}
        <div class="d-flex justify-content-center">
          <h3 id="empty-license-plate-message">
            There are no license plate vehicles for the given search criteria
          </h3>
        </div>
      {:else if error || isSiteOnError}
        <div class="d-flex h-100 justify-content-center align-items-center">
          <h3 id="error-license-plate-message">
            We are sorry we had an error fetching license plate vehicles
          </h3>
        </div>
      {:else}
        <div id="license-plate-container">
          <div
            class="d-flex flex-column justify-content-center mb-5"
            id="license-plate-table-container">

            <header>
              <div
                id="search-input-container"
                class="d-flex justify-content-end align-items-center">
                <TextInput
                  id="license-plate-table-search-input"
                  icon="search"
                  customContainerClass="shadow-sm mb-2 rounded-pill w-25"
                  placeholder="Search by plate number"
                  bind:value={tempSearchCriteria}
                  onKeyPress={e => e.key === 'Enter' && (searchCriteria = tempSearchCriteria)} />

                {#if exportLoading}
                  <div
                    class="d-flex align-items-center justify-content-center"
                    style="width: var(--cox2m-spacing-12-units); height: var(--cox2m-spacing-9-units);">
                    <LoadingSpinner />
                  </div>
                  >
                {:else}
                  <Button
                    id="license-plate-table-export-button"
                    customClass="font-weight-bold"
                    text="Export"
                    width="fit-content"
                    height="var(--cox2m-spacing-12-units)"
                    backgroundColor="var(--cox2m-clr-brand-600)"
                    color="var(--cox2m-clr-neutral-white)"
                    margin="-2px var(--cox2m-spacing-4-units) 0 0 "
                    padding="0 var(--cox2m-spacing-4-units)"
                    icon="export"
                    iconColor="var(--cox2m-clr-neutral-white)"
                    iconSize="var(--cox2m-spacing-6-units)"
                    on:click={handleExport} />
                {/if}
              </div>
            </header>
            <ScalableDataTable
              id="license-plate-table"
              on:fetchData={handleFetchDataEvent}
              on:sortRequestByField={updateSortingField}
              {columns}
              pagination
              isLoading={loading}
              {paginationResponse}
              {rows}
              fieldSelectionKey="id"
              dataPositioning={paginationOptions}
              itemsPerPage={paginationOptions.limit}
              highlightRow={false}
              sort={sortString}
            />
          </div>
        </div>
      {/if}
    </div>
  </div>
</MainScreen>
