<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
  import Hls from 'hls.js';

  import {clearSockets, getRtspCredentials, getRtspPath} from '../../../funcs';
  import {HLS_CONFIG} from '../../constants';
  import {selectedPark} from '../../stores';
  import {getCameras} from '../../actions';
  import {onDestroy, onMount, tick} from 'svelte';

  export let allEventsSelected = true;
  export let dashboardViewer = false;
  export let camerasWithEvents = [];
  export let token;
  export let selectedCamera = undefined;

  export let cameraViews = Array.from({length: 12}, () => {
    return {loading: true};
  });

  const MAXIMUM_REFETCH_TRIES = 5;
  let videoRetryCounter = 0;
  let cameraStatusLoading = false;
  let cameraStatusCheckInterval;

  let liveCameraToggleSwitch = true;
  let cameraViewError = false;
  let cameraWebSocket = null;
  let currentIndex = 0;
  let video;


  const updateCameras = cameraData => {
    if(cameraData.siteName === $selectedPark.name){
      if (cameraViews.some(view => view.name === cameraData.name)) {
        cameraViews.map((view, index) => {
          if (view.name === cameraData.name) {
            cameraViews[index] = {
              ...view,
              ...cameraData,
              loading: false
            };
          }
        });
      } else {
        let cameraToUpdateIndex = cameraViews.findIndex(view => !view.name);
        if (cameraToUpdateIndex >= 0) {
          cameraViews[cameraToUpdateIndex] = {
            ...cameraViews[cameraToUpdateIndex],
            ...cameraData,
            loading: false
          };
        } else {
          cameraViews.push({
            ...cameraData,
            loading: false
          });
        }
      }
    }
  };

  const setCameraWebSocket = () => {
    cameraWebSocket && cameraWebSocket.close();

    const webSocketHost = 'wss://WEBSOCKET_HOST/ws/camera';
    const authMessage = `{"auth" : "${token}"}`;

    cameraWebSocket = new WebSocket(webSocketHost);

    cameraWebSocket.onopen = () => {
      cameraViewError = false;
      cameraWebSocket.send(authMessage);
    };

    cameraWebSocket.onmessage = event => {
      let cameraData = JSON.parse(event.data);
      updateCameras(cameraData);
    };

    cameraWebSocket.onerror = () => {
      cameraViewError = true;
    };

    cameraWebSocket.onclose = () => {
      cameraWebSocket = null;
      setCameraWebSocket();
    };
  };

  const setLiveCamera = () => {
    try {
      if (
        Hls.isSupported() && cameraViews[currentIndex]
        && cameraViews[currentIndex].name && cameraViews[currentIndex].rtsp
      ) {
        setTimeout(async () => {
          let latestLoadedFragment;

          window.hls && window.hls.destroy();
          video = document.getElementById(`live-video-${currentIndex}`);

          video &&
            (video.ontimeupdate = () => {
              if (
                video &&
                video.currentTime < 1 &&
                !video.paused &&
                !video.ended &&
                video.duration > 1 &&
                video.readyState > video.HAVE_CURRENT_DATA
              ) {
                video.pause();
                setTimeout(
                  (video.currentTime = latestLoadedFragment.start),
                  2000
                );
                video.play();
              }
            });

          const rtspUrl = cameraViews[currentIndex].rtsp;
          const rtspCredentials = getRtspCredentials(rtspUrl);
          const rtspPath = getRtspPath(rtspUrl);
          const rtspAuth = btoa(`${rtspCredentials.username}:${rtspCredentials.password}`);
          const hlsConfig = {
            ...HLS_CONFIG,
            xhrSetup: xhr => {
              xhr.setRequestHeader('Authorization', `Basic ${rtspAuth}`);
            },
            fetchSetup: (context, initParams) => {
              const headers = new Headers();
              headers.append('Authorization', `Basic ${rtspAuth}`);
              initParams.headers = headers;
              return new Request(context.url, initParams);
            }
          }
          window.hls = new Hls(hlsConfig);

          window.hls.loadSource(
            `STREAMING_HOST/${rtspPath}/stream.m3u8`
          );
          window.hls.attachMedia(video);

          window.hls.on(Hls.Events.ERROR, (event, data) => {
            if (videoRetryCounter === MAXIMUM_REFETCH_TRIES) {
              window.hls.destroy();
              window.hls.loadSource();
              videoRetryCounter = 0;

            } else if (data.fatal) {
              videoRetryCounter += 1;

              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  setLiveCamera();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  window.hls.recoverMediaError();
                  break;
                default:
                  window.hls.destroy();
                  break;
              }
            }


          });

          window.hls.on(
            Hls.Events.FRAG_LOADED,
            (e, d) => {
              latestLoadedFragment = d.frag;
              videoRetryCounter = 0;
            }
          );
        }, 1000);
      } else if (cameraViews[currentIndex] && cameraViews[currentIndex].name && cameraViews[currentIndex].rtsp) {
        setTimeout(() => {
          const rtspUrl = cameraViews[currentIndex].rtsp;
          const rtspCredentials = getRtspCredentials(rtspUrl);
          const rtspPath = getRtspPath(rtspUrl);
          const hlsHost = `${'STREAMING_HOST'}`.replace('https://', '');

          video = document.getElementById(`live-video-${currentIndex}`);
          video.src = `https://${rtspCredentials.username}:${rtspCredentials.password}@${hlsHost}/${rtspPath}/stream.m3u8`;
        }, 1000)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleIndexChange = async () => {
    await tick();

    let slides = document.getElementsByClassName('carousel-item');
    Array.from(slides).forEach(
      (slide, index) =>
        index !== currentIndex && slide.classList.remove('active')
    );

    slides[currentIndex] &&
      slides[currentIndex].classList &&
      slides[currentIndex].classList.add('active');
    liveCameraToggleSwitch = false;
    await handleLiveCameraSwitch();
  };


  const clearLiveCamera = () => {
    liveCameraToggleSwitch = false;
    window.hls && window.hls.destroy();
  };

  const handleLiveCameraSwitch = async () => {
    if (liveCameraToggleSwitch) {
      clearLiveCamera();
    } else {
      liveCameraToggleSwitch = true;
      setLiveCamera();
    }
  };

  const initializeCameras = async () => {
    cameraViews = [];

    const cameraResponse = await getCameras(token, $selectedPark && $selectedPark.name ? {siteName: $selectedPark.name} : {});
    if (cameraResponse.fulfilled) {
      cameraViews = cameraResponse.cameras.map(
        camera => ({...camera, loading: true})
      );
    }
    cameraViews.forEach(camera => camera.availableLiveStream = true);
    const cameraIndex = cameraViews.findIndex(view => view.name === selectedCamera);

    if (cameraIndex >= 0) {
      currentIndex = cameraIndex;
    }
    setCameraWebSocket();

    window.addEventListener('clearSockets', () => {
      currentIndex = 0;
      clearSockets(cameraWebSocket);
    });
    setLiveCamera();
  }

  const doubleCheckLiveStreamAvailable = async (cameras) => {
    cameraStatusLoading = true;
    await Promise.allSettled(cameras.map(async (camera) => {
      try {
        const rtspUrl = camera.rtsp;
        const rtspCredentials = getRtspCredentials(rtspUrl);
        const rtspPath = getRtspPath(rtspUrl);
        const rtspAuth = btoa(`${rtspCredentials.username}:${rtspCredentials.password}`);
        const rstpHeaders = new Headers();
        rstpHeaders.append('Authorization', `Basic ${rtspAuth}`);
        rstpHeaders.append('Access-Control-Allow-Origin', '*');
        const hlsHost = `${'STREAMING_HOST'}`.replace('https://', '');
        const url = `https://${hlsHost}/${rtspPath}/index.m3u8`;
        try {
          const response = await fetchWithTimeout(new Request(url, { headers: rstpHeaders }), {
            timeout: 20000
          });
          if (response.status === 200) {
            console.warn(`response.status ${response.status} url ${url}`);
            camera.availableLiveStream = true;
            return camera;
          }
          console.warn(`response.status.error ${response.status} url ${url}`);
          camera.availableLiveStream = false;
          return camera;
        } catch (error) {
          console.warn(`error ${error} url ${url}`);
          console.log(error.name === 'AbortError');
          camera.availableLiveStream = false;
          return camera;
        }
      } catch (error) {
        camera.availableLiveStream = false;
        return camera;
      }
    }));
    cameraStatusLoading = false;
  }

  async function fetchWithTimeout(resource, options = {}) {
    const { timeout = 8000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(resource, {
      ...options,
      signal: controller.signal
    });
    clearTimeout(id);

    return response;
  }

  onMount(async () => {
    await initializeCameras();
    cameraStatusCheckInterval = setInterval(() => {
      if (!cameraStatusLoading) {
        doubleCheckLiveStreamAvailable(cameraViews)
      }
    }, 60000);
  });

  onDestroy(() => {
    if (cameraStatusCheckInterval) {
      clearInterval(cameraStatusCheckInterval);
    }
  });

  $: handleIndexChange(currentIndex);
  $: initializeCameras($selectedPark);

</script>

<style>
  .pointer {
    cursor: pointer;
  }

  .carousel {
    margin-right: var(--cox2m-spacing-4-units);
  }

  .video-features-container {
    height: var(--cox2m-spacing-10-units);
    z-index: 1;
    margin-top: calc(var(--cox2m-spacing-9-units) * (-1));
  }

  .video-features-container {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .camera-views-error-message {
    color: var(--cox2m-clr-neutral-black);
  }

  #selected-event-video-slide,
  video {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .live-video::-webkit-media-controls-timeline,
  .live-video::-webkit-media-controls-current-time-display {
    display: none;
  }

  .live-camera-switch {
    width: var(--cox2m-spacing-18-units);
    height: var(--cox2m-spacing-5-units);
    border-radius: calc(var(--cox2m-spacing-1-units) + var(--cox2m-visual-corrector));
    color: var(--cox2m-clr-neutral-white);
    background-color: var(--cox2m-clr-neutral-400);
  }

  .live-camera-switch.active {
    background-color: var(--cox2m-clr-success-400);
  }

  .live-camera-switch .rounded-circle {
    background-color: var(--cox2m-clr-neutral-white);
    height: var(--cox2m-spacing-2-units);
    width: var(--cox2m-spacing-2-units);
  }

  .live-camera-switch.active .rounded-circle {
    background-color: var(--cox2m-clr-critical-500);
  }

  .content-camera {
    padding-left: var(--cox2m-spacing-3-units);
  }

  .floating-camera-label {
    background-color: var(--cox2m-clr-neutral-black);
    border-radius: var(--cox2m-spacing-3-units) 0;
    color: var(--cox2m-clr-neutral-white);
    width: var(--cox2m-spacing-23-units);
    height: var(--cox2m-spacing-8-units);
  }

  .div-live {
    display: inline-flex;
  }

  .img {
    border-radius: 0.7rem;
  }

  .open-live-modal-icon {
    position: absolute;
    width: calc(var(--cox2m-spacing-7-units) + var(--cox2m-visual-corrector));
    height: calc(var(--cox2m-spacing-7-units) + var(--cox2m-visual-corrector));
    right: calc(var(--cox2m-spacing-7-units) + var(--cox2m-visual-corrector));
    top: calc(var(--cox2m-spacing-7-units) + var(--cox2m-visual-corrector));
    z-index: 10;
    background-color: var(--cox2m-clr-neutral-white);
    border-radius: 50%;
  }

  .cox2m-shadow-1-dp {
    border-radius: 1rem;
  }

  .fs-1300{
    font-size: var(--cox2m-fs-1300);
  }

  .fs-800{
    font-size: var(--cox2m-fs-800);
  }

  .thumbnails-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow-y: auto;
    margin: var(--cox2m-spacing-4-units) 0px 0px var(--cox2m-spacing-1-units);
  }

  .image-container {
    position: relative;
  }

  .image-container-mask {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0.5;
  }

  @media only screen and (min-width: 1280px) {
    .thumbnails-grid {
      max-width: 28%;
    }
  }

  @media only screen and (min-width: 1920px) {
    .thumbnails-grid {
      max-width: 28%;
    }
  }

  @media only screen and (min-width: 2560px) {
    .thumbnails-grid {
      max-width: 28%;
    }
  }

  @media only screen and (max-width: 767px) {
    .live-camera-switch {
      margin-top: 20px;
    }
  }
  .cox2m-shadow-1-dp {
    border-radius: 1rem;
  }
</style>
{#if cameraViews.length}
  <div
    class="content-camera w-100 d-flex align-items-center justify-content-center">
    <div class="w-100">
      <div class="d-flex justify-content-end align-items-center px-3 mb-2">
        <div class="div-live">

          <span
            id="live-camera-switch"
            class="live-camera-switch d-flex align-items-center p-2 pointer"
            class:active={liveCameraToggleSwitch}
            on:click={() => handleLiveCameraSwitch()}>
            <span class="rounded-circle mr-2" />
            <span class="cox2m-color-neutral-color-1 fs-800">
              LIVE
            </span>
          </span>
        </div>

      </div>
      <div class="position-relative">
        <div
          id="camera-views-carousel"
          data-interval="false"
          class="cox2m-shadow-1-dp carousel slide {dashboardViewer ? 'dashboard-carousel-responsive-handler' : ''}"
          data-ride="carousel"
          data-touch="false">
          <div class="h-100">
            {#if cameraViewError}
              <div class="carousel-item h-100 active">
                <div
                  class="d-flex align-items-center h-100 justify-content-center
                  text-center">
                  <span
                    class="camera-views-error-message"
                    id="camera-views-error-message">
                    We are sorry we cannot access the cameras information,
                    please refresh the page or try again later
                  </span>
                </div>
              </div>
            {:else if !camerasWithEvents.length && !allEventsSelected}
              <div
                class="carousel-item h-100 active"
                id={`camera-view-slide-0`}>
                <div
                  style="min-height: var(--cox2m-spacing-28-units);"
                  class="d-flex align-items-center h-100 justify-content-center">
                  <span>
                    There are no events associated with any camera for the
                    moment
                  </span>
                </div>
              </div>
            {:else}
              {#each cameraViews as view, index}
                <div
                  class="cox2m-shadow-1-dp carousel-item h-100 {index === 0 ? 'active' : ''}"
                  id={`camera-view-slide-${index}`}>
                  {#if view.loading}
                    <div
                      style="min-height: var(--cox2m-spacing-28-units);"
                      class="d-flex align-items-center h-100
                      justify-content-center">
                      <LoadingSpinner />
                    </div>
                  {:else if !liveCameraToggleSwitch}
                    <span
                      style="cursor:pointer;"
                      class="open-live-modal-icon d-flex align-items-center
                      justify-content-center"
                      on:click={window.open(cameraViews[currentIndex].imageUrl)}>
                      <Icon
                        icon="external-link-2"
                        color="var(--cox2m-clr-neutral-black)"
                        size="var(--cox2m-spacing-5-units)" />
                    </span>
                    <img
                      id={`${view.ip}-image-slide`}
                      class="d-block w-100 h-100"
                      style="border-radius: 15px"
                      src={view.imageUrl}
                      alt={view.name || ''} />
                  {:else}
                    <!-- svelte-ignore a11y-media-has-caption -->
                    <video
                      class="d-block w-100 h-100 live-video"
                      id={`live-video-${index}`}
                      style="border-radius: 15px"
                      muted
                      autoplay
                      poster={view.imageUrl}
                      controls />
                  {/if}
                </div>
              {/each}
            {/if}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="thumbnails-grid w-100">
    {#each cameraViews as view, index}
      <div
        class="m-2 {index === 0 ? 'active' : ''}"
        id={`camera-view-slide-${index}`}>
        {#if view.loading}
          <div
            style="min-height: var(--cox2m-spacing-28-units);"
            class="d-flex align-items-center h-100 justify-content-center">
            <LoadingSpinner />
          </div>
        {:else}
          <div class="image-container{view.availableLiveStream ? '' : '-mask'}">
            <div class="cox2m-shadow-1-dp">
              {#if !view.availableLiveStream}
              <span style='position: absolute;'>
                <Icon

                  icon='warning-500'
                  size='26px' />
              </span>
              {/if}
              <img
                id={`${view.ip}-image-slide`}
                class="d-block w-100 h-100 img pointer"
                src={view.imageUrl}
                alt={view.name || ''}
                on:click={() => (currentIndex = index)} />
            </div>

            <div
              class="video-features-container d-flex align-items-center
              justify-content-end">
              <span
                class="floating-camera-label text-center fs-1300
                d-flex align-items-center justify-content-center alert-info">
                <span id={`camera-carousel-item-${index}-name`}>
                  {view.name || ''}
                </span>
              </span>
            </div>
          </div>
        {/if}
      </div>
    {/each}
  </div>
{/if}
