<script>
  import {onMount} from 'svelte';

  export let props = {};
  const id = `${props.id}-${props.index}`;

  /**
   * Crops the license plate image
   * @param id
   * @param imageUrl
   * @param licensePlateBox
   */
  const cropLicensePlateImage = (id, imageUrl, licensePlateBox) => {
    const canvas = document.getElementById(`license-plate-canvas-${id}`);
    if (canvas) {
      const context = canvas.getContext('2d');
      const image = new Image();
      image.src = imageUrl;
      image.addEventListener('load', function () {
        const aspectRatio = licensePlateBox.width/licensePlateBox.height;

        let width = licensePlateBox.width;
        let height = licensePlateBox.height;
        if (width < canvas.width) {
          height = canvas.width/aspectRatio;
          height = Math.floor(height);
          width = canvas.width;
        } else {
          canvas.width = width;
          canvas.height = height;
        }

        context.drawImage(
          image,
          licensePlateBox.x,
          licensePlateBox.y,
          licensePlateBox.width,
          licensePlateBox.height,
          0,
          0,
          width,
          height
        );
      });
    }
  };

  onMount(() => {
    cropLicensePlateImage(id, props.imageUrl, props.licensePlate.box)
  });

  $: cropLicensePlateImage(id, props.imageUrl, props.licensePlate.box);
</script>

<style>
  canvas {
    max-width: var(--cox2m-spacing-30-units);
  }
</style>

<canvas id="license-plate-canvas-{props.id}-{props.index}" width="120px" height="71px"></canvas>
