<script>
  import lazyimage from '@cox2m/city-services-ui-components/src/svelte-actions/lazyimage';

  import {createEventDispatcher} from 'svelte';

  export let imageModal = false;
  export let className = '';
  export let style = '';
  export let image = {};

  let error = false;

  const dispatch = createEventDispatcher();
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  const dispatchSeletedImage = () => {
    dispatch('imageSelected', {
      image: image
    });
  };

  const formatDateString = d => {
    let date = new Date(d);
    let month = months[date.getMonth()];
    let minutes = date.getMinutes();
    let year = date.getFullYear();
    let hour =
      date.getHours() === 0
        ? 12
        : date.getHours() > 12
        ? date.getHours() - 12
        : date.getHours();
    let meridiem = date.getHours() >= 12 ? 'PM' : 'AM';
    let day = date.getDate();

    return `${month} ${day}, ${year} ${hour
      .toString()
      .padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${meridiem} `;
  };
</script>

<style>
  img {
    border-radius: 1rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: var(--cox2m-clr-neutral-white);
    height: 100% !important;
    min-height: 250px;
    max-height: 70vh;
    touch-action: pinch-zoom;
  }
  .gallery-image-container > div {
    display: grid;
    grid-template-rows: 1fr calc(
        var(--cox2m-spacing-12-units) + var(--cox2m-visual-corrector)
      );
    background-color: var(--cox2m-clr-neutral-white);
    border-radius: 1rem;
  }

  .gallery-image-container {
    border-radius: 1rem;
    padding-right: var(--cox2m-spacing-6-units) !important;
    margin-bottom: var(--cox2m-spacing-4-units) !important;
  }
  .image-information {
    background-color: var(--cox2m-clr-neutral-white);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .fs-1200 {
    font-size: var(--cox2m-fs-1200);
  }
  .fs-1000 {
    font-size: var(--cox2m-fs-1000);
  }
  @media only screen and (max-width: 1440px) {
    img {
      min-height: 175px;
    }
  }

  @media only screen and (max-width: 1200px) {
    .gallery-image-container {
      border-radius: 1rem;
      padding: 0 var(--cox2m-spacing-4-units) 0 var(--cox2m-spacing-4-units) !important;
      margin-bottom: var(--cox2m-spacing-4-units) !important;
    }
  }
</style>

{#if image}
  <div class="gallery-image-container m-0 p-0 {className}" {style}>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div
      class="w-100 h-100"
      style={!imageModal ? 'cursor:pointer' : ''}
      on:click={() => dispatchSeletedImage()}>
      <div class="w-100 image-direct-container">
        {#if !imageModal}
          <img
            use:lazyimage
            class="img-fluid w-100 h-100 gallery-image"
            id={`${image.id}-image`}
            data-testid={`${image.id}-image`}
            data-src={image.imageUrl}
            alt={!error ? '' : `${image.id}-placeholder`}
            on:error={() => (error = true)} />
        {:else}
          <img
            class="img-fluid w-100 h-100 gallery-image"
            id={`${image.id}-image`}
            data-testid={`${image.id}-image`}
            src={image.imageUrl}
            alt={!error ? '' : `${image.id}-placeholder`}
            on:error={() => (error = true)} />
        {/if}
      </div>
      <div class="w-100 row m-0 d-flex image-information px-2">
        <div class="col-12 mt-1">
          <div class="d-flex w-100 justify-content-between">
            <span
              class="font-weight-bold fs-1000"
              data-testid={`${image.id}-image-info`}>
              {image.assetType}:
              <span class="fs-1000" data-testid={`${image.id}-name`}>
                {image.name}
              </span>
            </span>
            <span
              class="font-weight-bold fs-1200"
              data-testid={`${image.id}-image-zone`}>
              {image.zone}
            </span>
          </div>
        </div>
        <div class="col-12 mb-2">
          <span class="fs-1200" data-testid={`${image.id}-image-date`}>
            {formatDateString(image.createdAt)}
          </span>
        </div>
      </div>
    </div>
  </div>
{/if}
