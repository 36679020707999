<script>
  import clickOutside from '@cox2m/city-services-ui-components/src/svelte-actions/clickOutside';
  import {
    getCookieByName,
    setSuccessToast
  } from '@cox2m/city-services-ui-components/src/funcs';
  import {createEventDispatcher} from 'svelte';
  import {sendSms} from '../../../actions';
  import {fly} from 'svelte/transition';
  import {css} from 'emotion';

  export let event;
  export let show;
  export let authorities = [];

  const dispatch = createEventDispatcher();
  const TOKEN = getCookieByName(`${'ENV'}_token`);

  let expandedPoliceInput = false;
  let phoneNumberUser = '';
  let phoneNumber = '';
  let message = '';

  const sendAlert = async () => {
    const opts = {
      securityEventId: event.id,
      notes: message,
      to: phoneNumber
    };
    const sendResponse = await sendSms(TOKEN, {notificationRequestDto: opts});

    if (sendResponse.fulfilled) {
      setSuccessToast(window.dispatchEvent, 'Alert sent');
      dispatch('smsSent');
      document.getElementById(`${event.id}-sms-modal-close-button`).click();
    }
  };

  const phoneNumberFilteringConditional = authority => {
    return (
      `${authority.givenName} ${authority.familyName}`
        .toLowerCase()
        .includes(phoneNumberUser.toLowerCase()) ||
      authority.email.toLowerCase().includes(phoneNumberUser.toLowerCase()) ||
      authority.phoneNumber
        .toLowerCase()
        .includes(phoneNumberUser.toLowerCase())
    );
  };

  function handleKeyPress(event) {
    if (event.key === 'Escape') {
      expandedPoliceInput = false;
    } else {
      expandedPoliceInput = true;
    }
  }

  const handlePhoneNumberSelection = authority => {
    phoneNumberUser = `${authority.givenName} ${authority.familyName}`;
    phoneNumber = authority.phoneNumber;
  };

  const handleManualInput = inputValue => {
    let foundUser = authorities.find(
      authority =>
        authority.phoneNumber === inputValue ||
        `${authority.givenName.toLowerCase()} ${authority.familyName.toLowerCase()}` ==
          inputValue.toLowerCase()
    );

    foundUser ? (phoneNumber = foundUser.phoneNumber) : (phoneNumber = '');
  };
</script>

<style>
  :global(.event-message-container > div:first-of-type) {
    height: var(--cox2m-spacing-30-units);
  }
  :global(.event-message-container div) {
    height: var(--cox2m-spacing-30-units);
    width: 100%;
    border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-400);
  }
  :global(.event-message-container:focus-within div) {
    object-fit: contain;
    border-radius: 5px;
    border: var(--cox2m-brd-w-2) solid var(--cox2m-clr-brand-500);
    background-color: var(--cox2m-clr-neutral-white);
  }

  .input-combo-select {
    border-radius: 4px;
    height: calc(var(--cox2m-spacing-12-units) + var(--cox2m-visual-corrector));
    outline: none;
    padding-left: calc(var(--cox2m-spacing-3-units) + var(--cox2m-visual-corrector));;
    margin-bottom: 5px;
  }
  .input-combo-select.active {
    border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-brand-500);
  }

  textarea {
    border: none;
    resize: none;
    width: 100%;
    height: 100%;
    outline: none;
    padding-left: 1em;
  }
  textarea:focus-visible {
    border: none;
    box-shadow: unset;
    outline: none;
  }
  .modal-content {
    border-radius: 1rem;
    width: 360px;
    height: 400px;
  }
  .modal-title {
    color: var(--cox2m-clr-neutral-black);
  }
  .send-button {
    background-color: var(--cox2m-clr-brand-500);
    color: var(--cox2m-clr-neutral-white);
    width: var(--cox2m-spacing-17-units);
    height: var(--cox2m-spacing-8-units);
  }
  .police-users-box {
    background: var(--cox2m-clr-neutral-white);
    width: 100%;
    border-radius: 6px;
    max-height: 150px;
    overflow-y: auto;
    top: calc(var(--cox2m-spacing-20-units) + var(--cox2m-visual-corrector));;
  }
  .authority-option {
    color: var(--cox2m-clr-neutral-black);
    font-weight: var(--cox2m-fw-medium);
  }

  .authority-option:hover,
  .authority-option.selected {
    background-color: var(--cox2m-clr-brand-200);
    color: var(--cox2m-clr-brand-500);
  }
  .phone-number-info {
    color: var(--cox2m-clr-neutral-500);
  }
  .fs-1300{
    font-size: var(--cox2m-fs-1300);
  }
  .fs-1000{
    font-size: var(--cox2m-fs-1000);
  }
  @media only screen and (max-width: 1200px) {
    .modal-content {
      width: 100% !important;
    }
  }
</style>

<div
  class="position-fixed d-block h-100 w-100"
  style="background-color:rgb(0,0,0,0.5); top: 0; left: 0; z-index: 10;">
  <div
    transition:fly={{y: -50, duration: 1000}}
    class="modal fade show d-block"
    data-backdrop="false"
    data-keyboard="false"
    id={`${event.id}-sms-modal`}
    tabindex="-1"
    role="dialog"
    aria-labelledby={`${event.id}-sms-modal-label`}
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content p-3 m-auto">
        <div class="modal-header border-0 p-0">
          <h5 class="modal-title mb-4 " id={`${event.id}-sms-modal-label`}>
            Alert Police
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            on:click={() => (show = false)}>
            <span aria-hidden="true" id={`${event.id}-sms-modal-close-button`}>
              &times;
            </span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div
            class="mb-3 phone-number-input-container position-relative {css`
              & div:first-of-type {
                border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-400) !important;
              }`}">
            <span>Phone Number</span>
            <input
              class="w-100 input-combo-select {expandedPoliceInput && 'active'}"
              type="text"
              autocomplete="off"
              id={`${event.id}-sms-modal-add-phone-number`}
              name="phone-number"
              placeholder="Enter name or phone number"
              on:input={e => handleManualInput(e.target.value)}
              on:keydown={handleKeyPress}
              bind:value={phoneNumberUser}
              on:click={() => (expandedPoliceInput = true)}
              use:clickOutside={() => {
                expandedPoliceInput = false;
              }} />
            <p class="phone-number-info fs-1300 text-center w-100">
              Phone numbers should be registered in our system
            </p>
            {#if expandedPoliceInput}
              <div class="position-absolute police-users-box py-2">
                {#each authorities.filter(authority => phoneNumberUser === '' || phoneNumberFilteringConditional(authority)) as authority (authority.clientId)}
                  <p
                    class="fs-1000 authority-option m-0 px-3 d-flex
                    flex-column py-2 "
                    class:selected={phoneNumberUser === authority.phoneNumber || `${authority.givenName.toLowerCase()} ${authority.familyName.toLowerCase()}` == phoneNumberUser.toLowerCase()}
                    on:click={() => handlePhoneNumberSelection(authority)}>
                    <span class="mb-0">
                      {`${authority.givenName} ${authority.familyName}`}
                    </span>
                  </p>
                {/each}
              </div>
            {/if}
          </div>

          <div class="event-message-container">
            <span>Message</span>
            <div class="rounded">
              <textarea
                id={`${event.id}-sms-modal-add-event-message`}
                on:input={e => {
                  message = e.target.value;
                }}
                placeholder="Add a message"
                name="event-message"
                rows="4"
                cols="50" />
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 p-0 pt-3 mb-4">
          <button
            id={`${event.id}-sms-modal-send-button`}
            type="button"
            disabled={!phoneNumber || phoneNumber == '' || !authorities.find(authority => authority.phoneNumber === phoneNumber)}
            on:click={() => sendAlert()}
            class="btn send-button d-flex justify-content-center
            align-items-center {!phoneNumber || phoneNumber == '' ? 'disabled' : ''}">
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
