<script>
  export let id = '';
  export let title;
  export let count;
  export let countText = '';
</script>

<style>
  .card-item-container {
    flex: 1 0 240px;
  }
  .card-div-container {
    border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-white);
    padding:
      var(--cox2m-spacing-5-units)
      var(--cox2m-spacing-5-units)
      var(--cox2m-spacing-8-units)
      var(--cox2m-spacing-5-units);
    border-radius: 16px;
    background-color: var(--cox2m-clr-neutral-white);
    box-shadow: var(--cox2m-shadow-1-dp-dir) var(--cox2m-shadow-1-dp-clr);
  }
  .card-title {
    font-size: var(--cox2m-fs-1000);
  }
  .card-count {
    text-align: center;
    font-size: var(--cox2m-fs-500);
  }
  .card-count-text {
    text-align: center;
    font-size: var(--cox2m-fs-1200);
  }

  @media only screen and (min-width: 1920px) {
    .card-item-container {
      flex-grow: 0;
      flex-basis: 400px;
      height: 220px;
    }
    .card-title {
      font-size: var(--cox2m-fs-600);
    }
  }
</style>

<div class="card-item-container">
  <div class="card-div-container h-100 w-100 d-flex flex-column" {id}>
    <div class="card-title">
      <p class="p-0 mb-0">
        <strong>{title}</strong>
      </p>
    </div>
    <div>
      <p class="card-count p-0 m-0">
        <strong>{count}</strong>
      </p>
      <p class="card-count-text p-0 m-0">
        <strong>{countText}</strong>
      </p>
    </div>
  </div>
</div>
