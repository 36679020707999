<script>
  import lazyimage from '@cox2m/city-services-ui-components/src/svelte-actions/lazyimage';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';

  import {
    getCookieByName,
    isMobile,
    setSuccessToast
  } from '@cox2m/city-services-ui-components/src/funcs';
  import {createEventDispatcher, onMount} from 'svelte';
  import {dismissEvent, reportEvent} from '../../actions';

  export let isViolationEvent = false;
  export let dashboardEvent = false;
  export let showExpand = true;
  export let selected = false;
  export let expanded = false;
  export let event;

  const TOKEN = getCookieByName(`${'ENV'}_token`);

  export let shareModalShow = false;
  export let smsModalShow = false;

  let alertShared = event.shared;
  let smsShared = event.alertPolice;
  let isMobileView = false;

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const getFormattedDate = d => {
    let date = new Date(d);
    let month = months[date.getMonth()];
    let weekday = days[date.getDay()];
    let minutes = date.getMinutes();
    let year = date.getFullYear();
    let hour = date.getHours();
    let day = date.getDate();

    return `${hour.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')} ${weekday} ${day}, ${month} ${year}`;
  };

  const dispatch = createEventDispatcher();
  const dismissAlert = async () => {
    const dismissEventResponse = await dismissEvent(TOKEN, event.frameId);
    if (dismissEventResponse.fulfilled) {
      expanded = false;
      dispatch('eventDeleted', {
        eventId: event.frameId
      });
      setSuccessToast(window.dispatchEvent, 'Alert dispatched successfully.');
    }
  };

  const reportEventAlert = async () => {
    const reportEventResponse = await reportEvent(TOKEN, event.frameId);
    if (reportEventResponse.fulfilled) {
      expanded = false;
      dispatch('eventDeleted', {
        eventId: event.frameId
      });
      setSuccessToast(window.dispatchEvent, 'Alert reported successfully.');
    }
  };

  const dispatchEventImageToViewer = e => {
    if (
      !e.target.classList.contains('expand-button-container') &&
      !e.target.classList.contains('icon')
    ) {
      dispatch('eventSelected', {
        event: {...event, isViolationEvent}
      });
    }
  };

  const countDetectionLabel = () => {
    if (isViolationEvent) {
      return `${event.camera.name}`;
    } else {
      if (event.type === 'VEHICLE_PEOPLE') {
        return `${parseInt(event.vehicleCount)} vehicle${
          parseInt(event.vehicleCount) > 1 ? 's' : ''
        } - ${parseInt(event.pedestrianCount)} person${
          parseInt(event.pedestrianCount) > 1 ? 's' : ''
        } `;
      } else if (event.type === 'VEHICLE') {
        return `${parseInt(event.vehicleCount)} vehicle${
          parseInt(event.vehicleCount) > 1 ? 's' : ''
        } `;
      } else if (event.type === 'PEOPLE') {
        return `${parseInt(event.pedestrianCount)} person${
          parseInt(event.pedestrianCount) > 1 ? 's' : ''
        } `;
      }
    }
  };

  const eventTypeLabel = () => {
    if (event.type === 'VEHICLE_PEOPLE') {
      return `Car/Pedestrian ${isViolationEvent ? 'Detected' : 'Event'}`;
    } else if (event.type === 'VEHICLE') {
      return `Car ${isViolationEvent ? 'Detected' : 'Event'}`;
    } else if (event.type === 'PEOPLE') {
      return `Pedestrian ${isViolationEvent ? 'Detected' : 'Event'}`;
    }
  };

  /**
   * Dispatch a show on map event when the show on map action is selected
   */
  // const dispatchShowOnMap = () => {
  //   dispatch('showEventOnMap', {
  //     event: {...event}
  //   });
  // };

  onMount(() => {
    isMobileView = isMobile();
  });
</script>

<style>
  .card {
    background-color: var(--cox2m-clr-neutral-white);
    height: var(--cox2m-spacing-30-units);
  }
  .outer-card-container,
  .card {
    border-radius: 0.75rem;
  }
  .event-card.selected {
    border: var(--cox2m-brd-w-2) solid var(--cox2m-clr-brand-600) !important;
  }
  .card-content-container {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: var(--cox2m-spacing-4-units);
    height: var(--cox2m-spacing-30-units);
  }
  .card-image-container {
    width: 150px;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-top-left-radius: 0.6rem;
    border-bottom-left-radius: 0.6rem;
  }
  .card-text-container p {
    margin-bottom: 0;
  }
  .card-text-container .event-type {
    color: var(--cox2m-clr-brand-600);
  }
  .card-text-container .event-card-info {
    font-size: var(--cox2m-fs-1000);
  }
  .card-text-container .event-date {
    font-size: var(--cox2m-fs-1200);
  }
  .card-text-container .event-badge {
    background-color: var(--cox2m-clr-brand-600);
    color: var(--cox2m-clr-neutral-white);
    font-size: var(--cox2m-fs-1000);
    width: var(--cox2m-spacing-25-units);
    height: var(--cox2m-spacing-6-units);
    z-index: 2;
  }
  .card-expander-container {
    padding-top: var(--cox2m-spacing-4-units);
    padding-right: var(--cox2m-spacing-4-units);
  }

  .action-card {
    cursor: pointer;
    color: var(--cox2m-clr-brand-600);
    background-color: var(--cox2m-clr-neutral-white);
  }

  .action-card:hover {
    background-color: var(--cox2m-clr-brand-600);
    color: var(--cox2m-clr-neutral-white);
  }

  .event-actions {
    margin-top: var(--cox2m-spacing-2-units);
    gap: var(--cox2m-spacing-2-units);
  }

  .card {
    width: 100%;
    height: 250px;
    padding-inline: var(--cox2m-spacing-2-units);
  }
  .card-content-container {
    grid-template-columns: 1fr;
  }

  img {
    border-radius: 0.6rem;
  }
  .card-image-container {
    height: 160px;
    width: 100%;
    padding-top: var(--cox2m-spacing-4-units);
    padding-inline: var(--cox2m-spacing-2-units);
  }
  .card-text-container .event-type {
    font-size: var(--cox2m-fs-900);
  }
  .card-text-container .event-card-info:not(.event-date) {
    display: none !important;
  }
  .card-text-container .event-date {
    margin-top: var(--cox2m-spacing-2-units);
    font-size: var(--cox2m-fs-1000);
  }
  .card-expander-container {
    display: none;
  }
  .card-text-container .event-badge {
    width: var(--cox2m-spacing-15-units);
    font-size: var(--cox2m-fs-1300);
  }
  @media only screen and (max-width: 1200px) {
  .event-card.selected {
    border: none !important;
  }
  .event-actions{
    display: none;
  }
}
</style>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="outer-card-container ">
  <div class="position-relative pointer ">
    <div
      class="card event-card cox2m-shadow-2-dp"
      class:selected
      id={`${event.frameId}-card`}
      data-testid={`${event.frameId}-card`}
      on:click={e => dispatchEventImageToViewer(e)}>
      <div class="card-content-container">
        <div class="card-image-container">
          {#if dashboardEvent}
            <img src={event.imageUrl} alt={`${event.frameId}-picture`} />
          {:else}
            <img
              use:lazyimage
              data-src={event.imageUrl}
              alt={`${event.frameId}-picture`} />
          {/if}
        </div>

        <div
          class="card-text-container d-flex flex-column justify-content-center">
          <p
            id={`${event.frameId}-card-event-type`}
            data-testid={`${event.frameId}-card-event-type`}
            class="event-type d-flex align-items-center font-weight-bold">
            {eventTypeLabel()}
          </p>
          <p
            id={`${event.frameId}-card-detection-count`}
            data-testid={`${event.frameId}-card-detection-count`}
            class="d-flex align-items-center event-card-info
            event-detection-count">
            {#if event.presetName && !isViolationEvent}
              <strong>{event.presetName}&nbsp;&nbsp;</strong>
            {/if}
            {countDetectionLabel()}
          </p>
          <div class="d-flex">
            {#if alertShared}
              <span
                id={`${event.frameId}-alert-shared-badge`}
                data-testid={`${event.frameId}-alert-shared-badge`}
                class="event-badge d-flex justify-content-center
                align-items-center font-weight-bold rounded mr-1">
                Alert sent
              </span>
            {/if}
            {#if smsShared}
              <span
                id={`${event.frameId}-sms-sent-badge`}
                data-testid={`${event.frameId}-sms-sent-badge`}
                class="event-badge d-flex justify-content-center
                align-items-center font-weight-bold rounded">
                SMS sent
              </span>
            {/if}
          </div>
          <p
            id={`${event.frameId}-card-event-date`}
            data-testid={`${event.frameId}-card-event-date`}
            class="d-flex align-items-center event-card-info event-date">
            {getFormattedDate(event.createdAt)}
          </p>
        </div>

        <div class="card-expander-container">
          {#if !dashboardEvent && showExpand}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <span
              style="cursor: pointer;"
              id={`${event.frameId}-card-expand-button`}
              data-testid={`${event.frameId}-card-expand-button`}
              class="expand-button-container"
              on:click={() => (expanded = !expanded)}>
              <Icon
                icon={expanded ? 'chevron-up' : 'chevron-down'}
                size="var(--cox2m-spacing-5-units)"
                color="var(--cox2m-clr-brand-600)" />
            </span>
          {/if}
        </div>
      </div>
    </div>

    {#if expanded}
      <div
        class="row mx-0 mb-2 event-actions position-relative"
        id={`${event.frameId}-event-actions`}
        data-testid={`${event.frameId}-event-actions`}>
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div
          id={`${event.frameId}-share-alert-action-button`}
          data-testid={`${event.frameId}-share-alert-action-button`}
          data-toggle="modal"
          data-target={`#${event.frameId}-share-modal`}
          on:click={() => (shareModalShow = true)}
          class="col card cox2m-shadow-2-dp position-relative pt-2 action-card
          text-center d-flex align-items-center justify-content-center">
          <Icon
            icon="direction-2"
            size="var(--cox2m-spacing-6-units)"
            color="var(--cox2m-clr-brand-600)"
            className="event-card-icon-hover" />
          <p class="mb-0 font-weight-bold">Share</p>
          <p class=" font-weight-bold m-0">Alert</p>
        </div>
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div
          id={`${event.frameId}-alert-action-button`}
          data-testid={`${event.frameId}-alert-action-button`}
          on:click={() => (isViolationEvent ? (smsModalShow = true) : reportEventAlert())}
          class="col card cox2m-shadow-2-dp position-relative pt-2 action-card
          text-center d-flex align-items-center justify-content-center">
          <Icon
            icon={isViolationEvent ? 'comment-text' : 'image-cross'}
            size="var(--cox2m-spacing-6-units)"
            color="var(--cox2m-clr-brand-600)"
            className="event-card-icon-hover" />
          {#if isViolationEvent}
            <p class="mb-0 font-weight-bold">Alert</p>
            <p class=" font-weight-bold m-0">Police</p>
          {:else}
            <p class="mb-0 font-weight-bold">Report</p>
            <p class=" font-weight-bold m-0">Alert</p>
          {/if}
        </div>
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div
          id={`${event.frameId}-dismiss-action-button`}
          data-testid={`${event.frameId}-dismiss-action-button`}
          on:click={() => dismissAlert()}
          class="col card cox2m-shadow-2-dp position-relative pt-2 action-card
          text-center d-flex align-items-center justify-content-center">
          <Icon
            icon="cross"
            size="var(--cox2m-spacing-6-units)"
            color="var(--cox2m-clr-brand-600)"
            className="event-card-icon-hover" />
          <p class="mb-0 font-weight-bold">Dismiss</p>
          <p class=" font-weight-bold m-0">Violation</p>
        </div>
      </div>
    {/if}
  </div>
</div>
