<script>
  import Map from '@cox2m/city-services-ui-components/src/components/maps/arcgis/Map.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Heatmap from './components/Heatmap.svelte';
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import DateTimePicker from '@cox2m/city-services-ui-components/src/components/time/DateTimePicker.svelte';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
  import SearchableSelectBadge from '@cox2m/city-services-ui-components/src/components/SearchableSelectBadge.svelte';
  import SelectBadge from '@cox2m/city-services-ui-components/src/components/SelectBadge.svelte';

  import {menuOpts, selectedPark, user} from '../stores';
  import {onMount} from "svelte";
  import {getCameras, getDetectableObjectLocations} from "../actions";
  import {getCookieByName} from "@cox2m/city-services-ui-components/src/funcs";
  import {querystring} from 'svelte-spa-router';
  import {formatDateString, setTimeNow, typeOptions} from "./components/scripts/events-screen/events-screen-funcs";

  let loading = true;
  let mapView = null;
  let locations = [];
  let userPickedTime = null;
  export let since = null;
  export let until = null;
  let currentDate; //this initial dates are handled by setDatesAccordingToLocation
  let finishDate; //this initial dates are handled by setDatesAccordingToLocation
  let initialTime;
  let finalTime;
  let queryParams;
  let loadingError = false;
  let noFilterSelected = true;
  let selectedCamera = null;
  let selectedType = null;
  let cameraOptions = [];
  let filter = null;
  let lastFilter = null;

  const ONE_DAY_MS = 1000 * 60 * 60 * 24;
  const token = getCookieByName(`${'ENV'}_token`);
  const heatmapScale =
    new URLSearchParams($querystring).get('heatmap-scale') || 0;

  /**
   * Set dates using the query string
   * @param resetDates
   */
  const setDatesAccordingToLocation = (resetDates = false) => {
    queryParams = new URLSearchParams($querystring);
    if (resetDates) {
      currentDate = new Date();
      initialTime = '12:00 am';
      since = currentDate.toISOString().replace(/T.*/gi, 'T00:00:00.000');

      finishDate = new Date();
      finalTime = '11:59 pm';
      until = finishDate.toISOString().replace(/T.*/gi, 'T23:59:59.999');
    }
  };

  /**
   * Get the object locations
   * @param filter
   * @returns {Promise<{fulfilled: boolean, events: *}|{fulfilled: boolean, error: *, status: *}|undefined>}
   */
  const getObjectLocations = async (filter) => {
    loading = true;
    const response = await getDetectableObjectLocations(filter);

    if (response.fulfilled) {
      locations = response.objects;
      lastFilter = filter;
    } else {
      loadingError = true;
    }
    loading = false;
  }

  const formatDateStringPicker = date => {
    let dateToFormat = new Date(date);
    let tempDate = new Date(
      dateToFormat.getFullYear(),
      dateToFormat.getMonth(),
      dateToFormat.getDate()
    );
    let formattedDate = `${tempDate.getFullYear()}/${tempDate.getMonth() +
    1}/${tempDate.getDate()}`;
    return formattedDate;
  };

  let startCalendarAt = formatDateStringPicker(new Date(new Date() - ONE_DAY_MS));
  const TODAY_FORMATTED_DATE = formatDateStringPicker(new Date());


  const handleNoFilter = () => {
    if (!noFilterSelected) {
      noFilterSelected = true;
      selectedCamera = null;
      selectedType = null;
    }
  };

  export const cameraSortFunction = (a, b) => (a.label > b.label ? 1 : -1);

  /**
   * Triggers looking for object locations
   * @param token
   * @param userPickedTime
   * @param selectedCamera
   * @param selectedType
   */
  const triggerFetchLocations = async (token, userPickedTime, selectedCamera, selectedType) => {
    if (selectedCamera && selectedCamera.value) {
      startCalendarAt = formatDateStringPicker(new Date(new Date() - (ONE_DAY_MS * 6)));
    }

    filter = {
      token,
      since: currentDate
        ? formatDateString({
          isInitialDate: true,
          date: since ? since : new Date(currentDate).toISOString(),
          userPickedTime
        })
        : setTimeNow(),
      until: formatDateString({
        isInitialDate: false,
        date: until ? until : new Date(finishDate).toISOString(),
        userPickedTime
      }),
      siteName: $selectedPark.name,
      cameraName: selectedCamera && selectedCamera.value,
      type: selectedType && selectedType.value
    }
    noFilterSelected = !(selectedCamera || selectedType);


    if (JSON.stringify(filter) !== JSON.stringify(lastFilter)) {
      await getObjectLocations(filter);
    }
  };

  onMount(async () => {
    setDatesAccordingToLocation(true);
    await triggerFetchLocations(token, userPickedTime, selectedCamera, selectedType);

    loading = true;
    const cameraResponse = await getCameras(token, {siteName: $selectedPark.name});

    if (cameraResponse.fulfilled) {
      cameraOptions = cameraResponse.cameras.map(camera => ({
        label: camera.name,
        value: camera.name
      }));
      loading = false;
    } else {
      loadingError = true;
    }
  });

  $: if (lastFilter) {
    triggerFetchLocations(token, userPickedTime, selectedCamera, selectedType);
  }

</script>

<style>
  /* globals */
  :global(.date-time-picker-container > .row) {
    margin-left: 0 !important;
  }

  :global(.date-time-picker-container .box) {
    z-index: var(--cox2m-z-index-1) !important;
  }

  /* layout */
  .page-content-container {
    display: grid;
    grid-template-areas:
      'filters filters'
      'map map';

    grid-template-columns: 60% 1fr;
    grid-template-rows: auto 1fr;
    min-height: 100%;
    column-gap: var(--cox2m-spacing-5-units);
    row-gap: var(--cox2m-spacing-5-units);
  }

  #filters-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--cox2m-spacing-2-units);
    grid-area: filters;
  }

  .map-container {
    display: flex;
    justify-content: space-between;
    grid-area: map;
  }

  .date-time-picker-container {
    display: flex;
    align-items: center;
    gap: var(--cox2m-spacing-6-units);
  }
  .selector-badges-container {
    display: flex;
    align-items: center;
    gap: var(--cox2m-spacing-2-units);
  }

  .all-badge-filter {
    color: var(--cox2m-clr-neutral-black);
    border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-brand-400);
    border-radius: 54px;
  }

  .all-badge-filter.active {
    background-color: var(--cox2m-clr-brand-600);
    border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-brand-600);
    color: var(--cox2m-clr-neutral-white);
  }
  #events-empty-message {
    color: var(--cox2m-clr-neutral-600);
  }
  #events-page-error-message {
    color: var(--cox2m-clr-critical-500);
  }

  :global(.esri-popup__footer) {
    display: none !important;
  }
  :global(#filters-container .date-time-picker-container #date-picker) {
    padding-left: 0 !important;
  }

  @media only screen and (max-width: 1200px) {
    :global(#events-time-picker) {
      flex-grow: 1;
      justify-content: center;
    }
    :global(.date-time-picker-container > .position-relative) {
      width: 100%;
    }

    .page-content-container {
      grid-template-areas:
        'map'
        'filters';
      grid-template-columns: 100%;
      grid-template-rows: 1fr auto auto;
      column-gap: 0;
      row-gap: var(--cox2m-spacing-6-units);
    }

    .date-time-picker-container {
      flex-wrap: wrap;
    }
    #filters-container {
      flex-direction: column;
      row-gap: var(--cox2m-spacing-6-units);
    }
    .selector-badges-container {
      margin-bottom: var(--cox2m-spacing-6-units);
    }
  }
</style>

<MainScreen
  title="Park Hours"
  menuOpt={$menuOpts}
  showAlertsBanner={false}
  user={$user}
  appSlug="smart-security">
  <div slot="main-dashboard" class="w-100 h-100">
    {#if loading}
      <div class="h-100 w-100 d-flex align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    {:else}
      <div class="page-content-container" id="heatmap-page-container">
        <div id="filters-container" class="filters-container">
          <div class="date-time-picker-container">
            <DateTimePicker
              on:confirm-selection-change={e => {
                e.detail.latestValidFirstSelectedDate && (currentDate = e.detail.latestValidFirstSelectedDate);
                e.detail.latestValidLatestSelectedDate && (finishDate = e.detail.latestValidLatestSelectedDate);
                userPickedTime = {selectedStartTime: e.detail.selectedStartTime, selectedEndTime: e.detail.selectedEndTime};
                since = null;
                until = null;
              }}
              firstSelectedDate={formatDateStringPicker(currentDate)}
              latestSelectedDate={formatDateStringPicker(finishDate)}
              startCalendarAt={startCalendarAt}
              endCalendarAt={TODAY_FORMATTED_DATE}
              initialStartTime={initialTime}
              initialEndTime={finalTime}
              timeRange={true} />
          </div>

          <div class="selector-badges-container">
            <span class="d-flex filter-icon-container">
              <Icon
                icon="sliders-horiz"
                size="var(--cox2m-spacing-5-units)"
                color="var(--cox2m-clr-neutral-500)" />
            </span>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <span
              class="reset-filter-badge d-flex"
              on:click={() => handleNoFilter()}>
              <span
                class="all-badge-filter d-flex align-items-center
                justify-content-center cox2m-smalltext-1 {noFilterSelected ? 'active' : ''}
                px-3 py-0 pointer">
                All
              </span>
            </span>
            <span class="d-flex">
              <SearchableSelectBadge
                bind:selectedOption={selectedCamera}
                id="camera-filter-badge"
                options={cameraOptions.sort(cameraSortFunction)}
                defaultLabel="Camera"
                responsiveTitle="Filter by camera" />
            </span>
            <span class="d-flex">
              <SelectBadge
                bind:selectedOption={selectedType}
                id="type-filter-badge"
                options={typeOptions}
                defaultLabel="Type"
                responsiveTitle="Filter by type" />
            </span>
          </div>
        </div>

        <div class="map-container">
          <Map
          props={{zoom: 18, center: [$selectedPark.coordinates[1], $selectedPark.coordinates[0]]}}
          basemap="satellite"
          bind:view={mapView}>
            <Heatmap
              layerId="heatmap-test"
              layerTitle="Heatmap"
              collection={locations}
              simpleRendererScale={heatmapScale}
              />
          </Map>
        </div>
      </div>
    {/if}
  </div>
</MainScreen>
