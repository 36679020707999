<script>
  import TextInput from '@cox2m/city-services-ui-components/src/forms/TextInput.svelte';

  import {
    getCookieByName,
    setSuccessToast
  } from '@cox2m/city-services-ui-components/src/funcs';
  import {createEventDispatcher} from 'svelte';
  import {shareAlert} from '../../../actions';
  import {fly} from 'svelte/transition';
  import {css} from 'emotion';

  export let event;
  export let show;

  const dispatch = createEventDispatcher();
  const TOKEN = getCookieByName(`${'ENV'}_token`);

  let message = '';
  let email = '';

  const validateEmail = () => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const sendAlert = async () => {
    const opts = {
      securityEventId: event.id,
      notes: message,
      to: email
    };
    const sendResponse = await shareAlert(TOKEN, {notificationRequestDto: opts});

    if (sendResponse.fulfilled) {
      dispatch('alertShared');
      setSuccessToast(window.dispatchEvent, 'Alert shared.');
      document.getElementById(`${event.id}-close-share-modal-button`).click();
    }
  };
</script>

<style>
  :global(.event-message-container > div:first-of-type) {
    height: var(--cox2m-spacing-30-units);
  }
  :global(.event-message-container div) {
    height: var(--cox2m-spacing-30-units);
    width: 100%;
    border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-400);
  }
  :global(.event-message-container:focus-within div) {
    object-fit: contain;
    border-radius: 5px;
    border: var(--cox2m-brd-w-2) solid var(--cox2m-clr-brand-600);
    background-color: var(--cox2m-clr-neutral-white)
  }
  textarea {
    border: none;
    resize: none;
    width: 100%;
    height: 100%;
    outline: none;
    padding-left: 1em;
  }
  textarea:focus-visible {
    border: none;
    box-shadow: unset;
    outline: none;
  }
  .modal-content {
    border-radius: 1rem;
    width: 360px;
    height: 400px;
  }
  .modal-title {
    color: var(--cox2m-clr-neutral-black);
  }
  .send-button {
    background-color: var(--cox2m-clr-brand-600);
    color: var(--cox2m-clr-neutral-white);
    width: var(--cox2m-spacing-17-units);
    height: var(--cox2m-spacing-8-units);
  }
  .email-info {
    color: var(--cox2m-clr-neutral-500);
  }
  .fs-1300{
    font-size: var(--cox2m-fs-1300);
  }
  @media only screen and (max-width: 1200px){
    .modal-content{
      width: 100% !important;
    }
  }
</style>

<div
  class="position-fixed d-block h-100 w-100"
  style="background-color:rgb(0,0,0,0.5); top: 0; left: 0; z-index: 10;">
  <div
    transition:fly={{y: -50, duration: 1000}}
    class="modal fade show d-block"
    data-backdrop="false"
    data-keyboard="false"
    id={`${event.id}-share-modal`}
    tabindex="-1"
    role="dialog"
    aria-labelledby={`${event.id}-share-modal-label`}
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content p-3 m-auto">
        <div class="modal-header border-0 p-0">
          <h5 class="modal-title mb-4" id={`${event.id}-share-modal-label`}>
            Share Alert
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            on:click={() => (show = false)}>
            <span
              aria-hidden="true"
              id={`${event.id}-close-share-modal-button`}>
              &times;
            </span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div
            class="mb-3 email-input-container {css`
              & div:first-of-type {
                border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-neutral-400) !important;
              }`}">
            <span>Name</span>
            <TextInput
              id={`${event.id}-share-modal-add-email-name`}
              name="email"
              bind:value={email}
              placeholder="Enter name or email"
              onChange={() => {}} />
            <p class="email-info fs-1300 text-center w-100">
              Recipients will see the name of the alert and your message
            </p>
          </div>

          <div class="event-message-container">
            <span>Message</span>
            <div class="rounded">
              <textarea
                id={`${event.id}-share-modal-add-event-message`}
                on:input={e => {
                  message = e.target.value;
                }}
                placeholder="Add a message"
                name="event-message"
                rows="4"
                cols="50" />
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 p-0 pt-3 mb-4">
          <button
            type="button"
            id={`${event.id}-share-modal-send-button`}
            disabled={!email || !validateEmail()}
            on:click={() => sendAlert()}
            class="btn send-button d-flex justify-content-center
            align-items-center {!email || !validateEmail() ? 'disabled' : ''}">
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
