<script>
  import ThoughtspotWrapper from '@cox2m/city-services-ui-components/src/components/thoughtspot/ThoughtspotWrapper.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';

  import {menuOpts, user, selectedPark} from '../stores';

  let runtimeFilters = [
    {
      columnName: 'Camera Site',
      operator: 'IN',
      values: [$selectedPark.name]
    }
  ];

</script>

<Modal>
  <MainScreen
    title="Analytics"
    menuOpt={$menuOpts}
    showAlertsBanner={false}
    user={$user}
    appSlug="smart-security">
    <div slot="main-dashboard" class="w-100 h-100">
      <ThoughtspotWrapper liveboardId="ANALYTICS_REPORT_ID" {runtimeFilters} />
    </div>
  </MainScreen>

</Modal>
