<script>
  import AlertsScreen from "@cox2m/city-services-ui-components/src/components/alerts/AlertsScreen.svelte";
  import MainScreen from "@cox2m/city-services-ui-components/src/main/MainScreen.svelte";

  import { menuOpts, user } from "../stores";
</script>

<MainScreen title="Alerts" menuOpt={$menuOpts} user={$user} showAlertsBanner={false} appSlug="smart-security">
  <div slot="main-dashboard" class="w-100 h-100">
    <AlertsScreen  appName='Smart Parks' />
  </div>
</MainScreen>
