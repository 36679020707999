<script>
  import ScalableDataTable from '@cox2m/city-services-ui-components/src/components/ScalableDataTable.svelte';
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte'
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Modal from '@cox2m/city-services-ui-components/src/components/Modal.svelte';
  import SiteIndicator from './components/shared/SiteIndicator.svelte';

  import {getCookieByName} from '@cox2m/city-services-ui-components/src/funcs.js';
  import {getCamerasPagination} from '../actions';
  import {menuOpts, selectedPark, user} from '../stores';
  import {onMount} from 'svelte';

  const token = getCookieByName(`${'ENV'}_token`);

  const columns = [
    {label: 'IP', key: 'ip', type: 'text'},
    {label: 'Name', key: 'name', type: 'text'},
    {label: 'Site Name', key: 'siteName', type: 'text', minWidth: 180},
    {label: 'Aliases', key: 'aliases', type: 'text'},
    {label: 'Groups', key: 'groups', type: 'text'},
    {label: 'Latitude', key: 'latitude', type: 'text'},
    {label: 'Longitude', key: 'longitude', type: 'text'},
    {label: 'Address', key: 'address', type: 'text'},
    {label: 'Status', key: 'status', type: 'text'},
    {label: 'Firmware', key: 'firmware', type: 'text'}
  ];

  let rows = [];
  let loading = true;
  let sortString = null;
  let paginationResponse;
  let isSiteLoading;
  let isSiteOnError;

  const INITIAL_ITEMS_PER_PAGE = 20;
  let paginationOptions = {
    limit: INITIAL_ITEMS_PER_PAGE,
    offset: 0
  };

  const handleFilterChange = async () => {
    loading = true;
    const opts = {
      ...paginationOptions,
      ...(sortString && {sort: sortString})
    };
    await getCamerasResponse(opts);
  };

  const handleFetchDataEvent = async e => {
    paginationOptions = e.detail.dataPositioning;
    await handleFilterChange();
  };

  const getCamerasResponse = async opts => {
    const camerasPaginationResponse = await getCamerasPagination(token, {
      ...paginationOptions,
      ...(sortString && {sort: sortString}),
      ...opts,
      ...($selectedPark && $selectedPark.name
        ? {siteName: $selectedPark.name}
        : {})
    });

    if (camerasPaginationResponse.fulfilled) {
      paginationResponse = camerasPaginationResponse.paging;
      rows = camerasPaginationResponse.collection.map(camera => ({
        ...camera
      }));
    }
    loading = false;
  };

  const updateSortingField = async e => {
    sortString = e.detail.sortString;
    await handleFilterChange();
  };

  onMount(async () => {
    await getCamerasResponse();
  });
</script>

<style>
  .dashboard-component-container {
    background-color: var(--cox2m-clr-neutral-white);
    border-radius: 16px;
    padding: var(--cox2m-spacing-8-units) 0;
    min-height: 100%;
    box-shadow: var(--cox2m-shadow-3-dp-dir) var(--cox2m-shadow-3-dp-clr);
  }
</style>

<Modal>
  <MainScreen title="Devices" menuOpt={$menuOpts} showAlertsBanner={false} user={$user} appSlug="smart-security">
    <div slot="main-dashboard">
      <div class="w-100">
        <SiteIndicator
          title="Devices Table"
          bind:isLoading={isSiteLoading}
          bind:isOnError={isSiteOnError} />
      </div>
      {#if isSiteLoading}
        <div class="w-100 h-100 d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      {:else if !isSiteLoading && !isSiteOnError }
      <div class="dashboard-component-container">
        <ScalableDataTable
          on:fetchData={handleFetchDataEvent}
          on:sortRequestByField={updateSortingField}
          id="devices-table"
          {columns}
          pagination
          isLoading={loading}
          sort={sortString}
          {paginationResponse}
          bind:rows
          dataPositioning={paginationOptions}
          itemsPerPage={paginationOptions.limit}
          containerClassName={`rounded bg-white text-dark overflow-auto
            w-100 cox2m-smalltext-1`} />
      </div>
      {:else if !isSiteLoading && isSiteOnError}
        <div class="w-100 h-100 mt-3 d-flex align-items-center justify-content-center">
          <h3>
            We are sorry we cannot fetch the devices information right now
          </h3>
        </div>
      {/if}
    </div>
  </MainScreen>
</Modal>
