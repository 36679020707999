export const InviteStrategies = {
    ACTIVE_DIRECTORY: 'active_directory',
    REGULAR_EMAIL: 'email'
}
export const EMAIL_REGEX = /^.+@.+\..+$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const PHONE_NUMBER_REGEX = /^\+[0-9]{1,15}$/;

export const HLS_CONFIG = {
  nudgeOffset: 0.5,
  progressive: true,
  enableWorker: true,
  lowLatencyMode: true,
  nudgeMaxRetry: 100,
  maxBufferLength: 3600,
  liveSyncDurationCount: 5,
  liveDurationInfinity: true,
  maxLiveSyncPlaybackRate: 2
};

export const PARK_JOURNEY_MARKER_TYPE = [
  {
    for: 'journey-start-selected',
    type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
    url: "images/park-journey-assets/start-selected-icon.svg",
    width: "32px",
    height: "32px"
  },
  {
    for: 'journey-start',
    type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
    url: "images/park-journey-assets/start-icon.svg",
    width: "32px",
    height: "32px"
  },
  {
    for: 'selected-marker',
    type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
    color: [230,30,40],
    outline: {
      // autocasts as new SimpleLineSymbol()
      color: [255, 255, 255],
      width: 2,
    }
  },
  {
    for: 'regular-marker',
    type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
    color: [196,201,217],
    outline: {
      // autocasts as new SimpleLineSymbol()
      color: [255, 255, 255],
      width: 2,
    }
  },
  {
    for: 'journey-end',
    type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
    url: "images/park-journey-assets/end-icon.svg",
    width: "32px",
    height: "32px"
  },
  {
    for: 'journey-end-selected',
    type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
    url: "images/park-journey-assets/end-selected-icon.svg",
    width: "32px",
    height: "32px"
  }
]

export const MarkersByEventType = [
  {
    for: "VEHICLE",
    type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
    url: "images/event-type-assets/event-type-vehicle.svg",
    width: "24px",
    height: "24px"
  },
  {
    for: 'PEOPLE',
    type: "picture-marker",  // autocasts as new PictureMarkerSymbol()
    url: "images/event-type-assets/event-type-people.svg",
    width: "24px",
    height: "24px"
  },
];

export const MONTHS = ["January","February","March","April","May","June","July","August","September","October","November","December"];

export const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
