<script>
  import lazyimage from '@cox2m/city-services-ui-components/src/svelte-actions/lazyimage';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
  import ShareModal from './events/ShareModal.svelte';
  import AlertPoliceModal from './events/AlertPoliceModal.svelte';

  import {getCookieByName, isMobile, setSuccessToast} from '@cox2m/city-services-ui-components/src/funcs';
  import {createEventDispatcher, onMount} from 'svelte';
  import {dismissEvent, reportEvent} from '../../actions';

  export let isViolationEvent = false;
  export let dashboardEvent = false;
  export let selected = false;
  export let event;
  export let authorities = [];
  export let showExpand = true;
  export let expanded = false;

  const TOKEN = getCookieByName(`${'ENV'}_token`);

  let shareModalShow = false;
  let smsModalShow = false;

  let alertShared = event.shared;
  let smsShared = event.alertPolice;
  let isMobileView = false;
  let licensePlates = null;

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const getFormattedDate = d => {
    let date = new Date(d);
    let month = months[date.getMonth()];
    let weekday = days[date.getDay()];
    let minutes = date.getMinutes();
    let year = date.getFullYear();
    let hour = date.getHours();
    let day = date.getDate();

    return `${hour.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')} ${weekday} ${day}, ${month} ${year}`;
  };

  const dispatch = createEventDispatcher();
  const dismissAlert = async () => {
    const dismissEventResponse = await dismissEvent(TOKEN, event.id);
    if (dismissEventResponse.fulfilled) {
      expanded = false;
      dispatch('eventDeleted', {
        eventId: event.id
      });
      setSuccessToast(window.dispatchEvent, 'Alert dispatched successfully.');
    }
  };

  const reportEventAlert = async () => {
    const reportEventResponse = await reportEvent(TOKEN, event.id);
    if (reportEventResponse.fulfilled) {
      expanded = false;
      dispatch('eventDeleted', {
        eventId: event.id
      });
      setSuccessToast(window.dispatchEvent, 'Alert reported successfully.');
    }
  };

  const dispatchEventImageToViewer = e => {
    if (
      !e.target.classList.contains('expand-button-container') &&
      !e.target.classList.contains('icon')
    ) {
      dispatch('eventSelected', {
        event: {...event, isViolationEvent }
      });
    }
  };

  const showAlertBadge = type => {
    if (type === 'alert') {
      alertShared = true;
    } else if (type === 'sms') {
      smsShared = true;
    }
  };

  const countDetectionLabel = () => {
    if (isViolationEvent) {
      return `${event.camera.name}`;
    } else {
      if (event.type === 'VEHICLE_PEOPLE') {
        return `${parseInt(event.vehicleCount)} vehicle${
          parseInt(event.vehicleCount) > 1 ? 's' : ''
        } - ${parseInt(event.pedestrianCount)} person${
          parseInt(event.pedestrianCount) > 1 ? 's' : ''
        } `;
      } else if (event.type === 'VEHICLE') {
        return `${parseInt(event.vehicleCount)} vehicle${
          parseInt(event.vehicleCount) > 1 ? 's' : ''
        } `;
      } else if (event.type === 'PEOPLE') {
        return `${parseInt(event.pedestrianCount)} person${
          parseInt(event.pedestrianCount) > 1 ? 's' : ''
        } `;
      }
    }
  };

  const eventTypeLabel = () => {
    if (event.type === 'VEHICLE_PEOPLE') {
      return `Car/Pedestrian ${isViolationEvent ? 'Detected' : 'Event'}`;
    } else if (event.type === 'VEHICLE') {
      return `Car ${isViolationEvent ? 'Detected' : 'Event'}`;
    } else if (event.type === 'PEOPLE') {
      return `Pedestrian ${isViolationEvent ? 'Detected' : 'Event'}`;
    }
  };

  /**
   * Extract license plates
   * @returns {string}
   */
  const getLicensePlates = () => {
    if (event.vehicles) {
      const licensePlateValues = event.vehicles
        .filter(vehicle => vehicle.licensePlate && vehicle.licensePlate.value)
        .map(vehicle => {
          if (vehicle.licensePlate.isBestDetection) {
            return `Best: ${vehicle.licensePlate.value}`;
          } else {
            return vehicle.licensePlate.value;
          }
        });

      if (licensePlateValues.length > 0) {
        return licensePlateValues.join(" - ");
      }
    }

    return "";
  };

  /**
   * Dispatch a show on map event when the show on map action is selected
   */
  const dispatchShowOnMap = () => {
    dispatch('showEventOnMap', {
      event: {...event}
    });
  }

  onMount(() => {
    isMobileView = isMobile();
    licensePlates = getLicensePlates();
  });
</script>

<style>
  :global(.action-card:hover svg){
    color: var(--cox2m-clr-neutral-white) !important;
  }
  .event-card {
    z-index: 1;
  }
  .event-card.selected {
    border: var(--cox2m-brd-w-2) solid var(--cox2m-clr-brand-600) !important;
  }

  .card {
    background-color: var(--cox2m-clr-neutral-white);
    height: var(--cox2m-spacing-30-units);
    border-radius: 1rem;
  }

  img {
    width: var(--cox2m-spacing-16-units);
    height: var(--cox2m-spacing-16-units);
    border-radius: 16px;
    object-fit: cover;
    max-height: 100%;
    overflow: hidden;
  }

  .event-card-info {
    font-size: var(--cox2m-fs-1000);
  }

  .event-type {
    color: var(--cox2m-clr-brand-600);
  }

  .event-type,
  .event-card-info {
    margin: var(--cox2m-spacing-1-units) 0 var(--cox2m-spacing-1-units) 0 !important;
  }
  .event-actions {
    margin-top: var(--cox2m-spacing-2-units);
  }
  .action-card {
    cursor: pointer;
    color: var(--cox2m-clr-brand-600);
    background-color: var(--cox2m-clr-neutral-white);
  }

  .action-card:hover {
    background-color: var(--cox2m-clr-brand-600);
    color: var(--cox2m-clr-neutral-white);
  }
  .event-badge {
    background-color: var(--cox2m-clr-brand-600);
    color: var(--cox2m-clr-neutral-white);
    font-size: var(--cox2m-fs-1000);
    width: var(--cox2m-spacing-25-units);
    height: var(--cox2m-spacing-6-units);
    z-index: 2;
  }
  .pointer {
    cursor: pointer;
  }

  @media only screen and (min-width: 1441px) {
    img {
      height: var(--cox2m-spacing-20-units);
      width: var(--cox2m-spacing-20-units);
    }
    .card {
      height: var(--cox2m-spacing-30-units);
      padding: var(--cox2m-spacing-4-units) !important;
    }
    .event-card-info,
    .event-type {
      margin-bottom: var(--cox2m-spacing-2-units);
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .event-card-info,
    .event-type {
      margin-bottom: var(--cox2m-spacing-1-units);
    }

    .card {
      height: var(--cox2m-spacing-20-units);
    }
    .event-badge {
      font-size: var(--cox2m-fs-1300);
      width: var(--cox2m-spacing-15-units);
      height: var(--cox2m-spacing-4-units);
    }
  }
  @media only screen and (max-width: 1199px) {
    .card {
      padding-top: var(--cox2m-spacing-4-units);
      padding-bottom: var(--cox2m-spacing-4-units);
    }
    .event-type {
      margin-top: var(--cox2m-spacing-4-units);
    }
  }
  @media only screen and (max-width: 1440px){
    .event-type {
      font-size: var(--cox2m-fs-1200) !important;
    }
    .event-card-info, .event-badge {
      font-size: var(--cox2m-fs-1300) !important;
    }
  }

  .event-actions-mobile {
    position: fixed;
    width: 100vw;
    bottom: 0;
    left: 0;
    height: auto !important;
    z-index: 10;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: var(--cox2m-spacing-4-units);
    padding-right: var(--cox2m-spacing-4-units);
  }

  .event-actions-mobile-header {
    display: flex;
    justify-content: space-between;
    padding-left: var(--cox2m-spacing-2);
    padding-right: var(--cox2m-spacing-2);
    height: 60px;
  }

  .action-card-mobile {
    display: flex;
    margin-left: var(--cox2m-spacing-2);
    height: 48px;
    color: rgba(0, 0, 0, 0.6);
  }

  .action-card-mobile-text {
    margin-left: 36px;
    font-size: var(--cox2m-smalltext-1);
    font-weight: 600;
  }

  @media only screen and (max-width: 1200px){
    :global(.action-card:hover svg){
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }
</style>

<div>
  <div class="position-relative pointer">
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div
      on:click={e => dispatchEventImageToViewer(e)}
      class="row mx-0 card border-0
      cox2m-shadow-1-dp px-3 justify-content-center event-card"
      class:selected={selected}
      id={`${event.id}-card`}>
      <div class="col-8 p-0 d-flex flex-column justify-content-between">
        <p
          id={`${event.id}-card-event-type`}
          class="event-type d-flex align-items-center font-weight-bold">
          {eventTypeLabel()}
        </p>
        <p
          id={`${event.id}-card-detection-count`}
          class="d-flex align-items-center event-card-info event-detection-count">
          {#if event.presetName && !isViolationEvent}<strong>{event.presetName}&nbsp;&nbsp;</strong>{/if}
          {countDetectionLabel()}
        </p>
        {#if licensePlates}
          <p
            id={`${event.id}-license-plates`}
            class="d-flex align-items-center event-card-info">
            {licensePlates}
          </p>
        {/if}
        <div class="d-flex">
          {#if alertShared}
            <span
              id={`${event.id}-alert-shared-badge`}
              class="event-badge d-flex justify-content-center
              align-items-center font-weight-bold rounded mr-1">
              Alert sent
            </span>
          {/if}
          {#if smsShared}
            <span
              id={`${event.id}-sms-sent-badge`}
              class="event-badge d-flex justify-content-center
              align-items-center font-weight-bold rounded">
              SMS sent
            </span>
          {/if}
        </div>
        <p
          id={`${event.id}-card-event-date`}
          class="d-flex align-items-center event-card-info event-date">
          {getFormattedDate(event.createdAt)}
        </p>
      </div>
      <div class="m-0 p-0 justify-content-end d-flex align-items-center">
        <!-- do not remove this conditional for unit testing purpose -->
        {#if dashboardEvent}
          <img
            src={event.imageUrl}
            class="img-fluid m-0 mr-3"
            alt={`${event.id}-picture`} />
        {:else}
          <img
            use:lazyimage
            data-src={event.imageUrl}
            class="img-fluid m-0 mr-3"
            alt={`${event.id}-picture`} />
        {/if}
        {#if !dashboardEvent && showExpand}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <span
            style="cursor: pointer;"
            id={`${event.id}-card-expand-button`}
            class="expand-button-container"
            on:click={() => (expanded = !expanded)}>
            <Icon
              icon={expanded ? 'chevron-up' : 'chevron-down'}
              size="var(--cox2m-spacing-5-units)"
              color="var(--cox2m-clr-brand-600)" />
          </span>
        {/if}
      </div>
    </div>
    {#if expanded && !dashboardEvent}
      {#if isMobileView}
        <div
          class="event-actions-mobile card"
          id={`${event.id}-event-actions-mobile`}>

          <div class="event-actions-mobile-header d-flex align-items-center">
            <span class="font-weight-bold">Menu</span>
            <Icon icon="cross" size="var(--cox2m-spacing-6-units)" onClick={() => (expanded = !expanded)} />
          </div>
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            id={`${event.id}-alert-action-button-mobile`}
            data-toggle="modal"
            data-target={`#${event.id}-share-modal`}
            on:click={() => (shareModalShow = true)}
            class="action-card action-card-mobile">
            <Icon
              icon="direction-2"
              size="24px"
              color="rgba(0, 0, 0, 0.6)"
              className="event-card-icon-hover" />
            <p class="action-card-mobile-text">Share Alert</p>
          </div>
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            id={`${event.id}-alert-action-button-mobile`}
            on:click={() => isViolationEvent ? (smsModalShow = true) : (reportEventAlert()) }
            class="action-card action-card-mobile">
            <Icon
              icon={isViolationEvent ? "comment-text" : "image-cross" }
              size="24px"
              color="rgba(0, 0, 0, 0.6)"
              className="event-card-icon-hover" />
            <p class="action-card-mobile-text">{isViolationEvent ? "Alert Police" : "Report Alert" } </p>
          </div>
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            id={`${event.id}-dismiss-action-button-mobile`}
            on:click={() => dismissAlert()}
            class="action-card action-card-mobile">
            <Icon
              icon="cross"
              size="24px"
              color="rgba(0, 0, 0, 0.6)"
              className="event-card-icon-hover" />
            <p class="action-card-mobile-text">Dismiss Violation</p>
          </div>
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            id={`${event.id}-view-on-map-button-mobile`}
            on:click={() => dispatchShowOnMap()}
            class="action-card action-card-mobile">
            <Icon
              icon="map-view"
              size="24px"
              color="rgba(0, 0, 0, 0.6)"
              className="event-card-icon-hover" />
            <p class="action-card-mobile-text">View on map</p>
          </div>
        </div>
      {:else}
        <div
          class="row mx-0 mb-2 event-actions position-relative"
          id={`${event.id}-event-actions`}>
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            id={`${event.id}-alert-action-button`}
            data-toggle="modal"
            data-target={`#${event.id}-share-modal`}
            on:click={() => (shareModalShow = true)}
            class="col card cox2m-shadow-2-dp position-relative pt-2
          action-card text-center d-flex align-items-center
          justify-content-center">
            <Icon
              icon="direction-2"
              size="var(--cox2m-spacing-6-units)"
              color="var(--cox2m-clr-brand-600)"
              className="event-card-icon-hover" />
            <p class="mb-0 font-weight-bold">Share</p>
            <p class=" font-weight-bold m-0">Alert</p>
          </div>
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            id={`${event.id}-alert-action-button`}
            on:click={() => isViolationEvent ? (smsModalShow = true) : (reportEventAlert())}
            class="col card cox2m-shadow-2-dp position-relative pt-2
            action-card text-center d-flex align-items-center
            justify-content-center">
            <Icon
              icon={isViolationEvent ? "comment-text" : "image-cross" }
              size="var(--cox2m-spacing-6-units)"
              color="var(--cox2m-clr-brand-600)"
              className="event-card-icon-hover" />
            {#if isViolationEvent}
              <p class="mb-0 font-weight-bold">Alert </p>
              <p class=" font-weight-bold m-0">Police</p>
            {:else}
              <p class="mb-0 font-weight-bold">Report </p>
              <p class=" font-weight-bold m-0">Alert</p>
            {/if}
          </div>
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            id={`${event.id}-dismiss-action-button`}
            on:click={() => dismissAlert()}
            class="col card cox2m-shadow-2-dp position-relative pt-2
            action-card text-center d-flex align-items-center
            justify-content-center">
            <Icon
              icon="cross"
              size="var(--cox2m-spacing-6-units)"
              color="var(--cox2m-clr-brand-600)"
              className="event-card-icon-hover" />
            <p class="mb-0 font-weight-bold">Dismiss</p>
            <p class=" font-weight-bold m-0">Violation</p>
          </div>
        </div>
      {/if}
    {/if}
  </div>

  {#if shareModalShow}
    <ShareModal
      bind:show={shareModalShow}
      on:alertShared={() => showAlertBadge('alert')}
      {event} />
  {/if}
  {#if smsModalShow}
    <AlertPoliceModal
      {authorities}
      bind:show={smsModalShow}
      on:smsSent={() => showAlertBadge('sms')}
      {event} />
  {/if}
</div>
