<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';

  import {createEventDispatcher} from 'svelte';
  import {MONTHS, DAYS} from '../../constants';

  export let groupList = null;
  export let event = null;

  export let shouldShowVideo = false;
  export let selectedEventIndex = 0;

  export let loading = true;
  export let onError = false;

  export let windowWidth;

  const dispatch = createEventDispatcher();

  const groupEventLabel = (event, index) => {
    if (!event || !event.type) return '';

    if (index === 0) {
      return 'Start Date';
    } else if (index === groupList.length - 1) {
      return 'Finish Date';
    }

    if (event.type === 'PEOPLE') {
      return 'Pedestrian Violation';
    } else if (event.type === 'VEHICLE') {
      return 'Vehicle Violation';
    } else {
      return 'Pedestrian Vehicle Violation';
    }
  };

  const getFormattedDate = d => {
    let date = new Date(d);
    let month = MONTHS[date.getMonth()];
    let weekday = DAYS[date.getDay()];
    let minutes = date.getMinutes();
    let year = date.getFullYear();
    let hour = date.getHours();
    let day = date.getDate();

    return `${hour.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')} ${weekday} ${day}, ${month} ${year}`;
  };

  $: label = groupEventLabel(event, null);
</script>

<style>
  .group-card {
    padding: var(--cox2m-spacing-5-units) var(--cox2m-spacing-3-units);
    background-color: var(--cox2m-clr-neutral-white);
    border-radius: 12px;
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--cox2m-spacing-2-units);
  }
  .group-label {
    font-size: var(--cox2m-fs-800);
  }
  .group-label,
  .watch-video-label {
    color: var(--cox2m-clr-brand-600);
    font-weight: var(--cox2m-fw-bold);
  }
  .watch-video-label {
    margin-left: var(--cox2m-spacing-2-units);
    font-size: var(--cox2m-fs-1100);
  }
  .group-title-container {
    margin-bottom: var(--cox2m-spacing-4-units);
  }
  .steps-container {
    max-height: 270px;
    overflow: auto;
  }
  .steps-container::-webkit-scrollbar {
    display: none;
  }

  .steps-container:hover::-webkit-scrollbar {
    display: initial;
  }

  .event-step {
    /* height: 60px; */
    /* this height will be removed */
    display: grid;
    grid-template-columns: 20px 1fr;
    padding-inline: var(--cox2m-spacing-1-units);
    /* align-items: center; */
    position: relative;
    z-index: 1;
  }

  .step-number {
    color: var(--cox2m-clr-neutral-white);
    height: var(--cox2m-spacing-5-units);
    width: var(--cox2m-spacing-5-units);
    background-color: var(--cox2m-clr-neutral-500);
    border-radius: 50%;
    font-size: var(--cox2m-fs-1300);
    display: grid;
    place-content: center;
    outline-width: 8px !important;
    outline-style: solid;
    outline-color: var(--cox2m-clr-neutral-white);
  }
  .step-number.selected {
    background-color: var(--cox2m-clr-critical-500);
  }
  .step-data {
    margin-left: var(--cox2m-spacing-3-units);
    display: grid;
    grid-template-areas:
      'label'
      'date'
      'icon';
    grid-template-columns: 250px;
  }
  .step-number:after {
    display: block;
    position: absolute;
    content: ' ';
    height: 100%;
    border-left: var(--cox2m-brd-w-2) solid var(--cox2m-clr-neutral-500);
    top: 0;
    left: 13px;
    z-index: -1;
  }

  .step-number.last-step:after {
    height: 50%;
  }

  .step-number.first-step:after{
    height: 60%;
    top: 30%;
  }

  .last-step{
    align-self: center;
  }
  .step-number.no-steps:after {
    height: 0%;
  }
  p.event-data {
    margin-bottom: 0;
    font-size: var(--cox2m-fs-1100);
    color: var(--cox2m-clr-neutral-500);
  }
  p.event-label {
    font-weight: var(--cox2m-fw-bold);
    grid-area: label;
  }

  p.event-date {
    grid-area: date;
  }

  .event-data-icon {
    grid-area: icon;
    display: flex;
    gap: var(--cox2m-spacing-2-units);
    align-items: center;
    margin-bottom: var(--cox2m-spacing-2-units);
  }

  p.selected.event-label,
  p.selected.event-date {
    color: var(--cox2m-clr-neutral-black);
  }

  .watch-video-label-container,
  .actions-container {
    display: flex;
    align-items: center;
    gap: var(--cox2m-spacing-3-units);
    color: var(--cox2m-clr-brand-600);
    font-weight: var(--cox2m-fw-bold);
    cursor: pointer;
  }

  .loading-container,
  .error-container {
    display: grid;
    place-items: center;
    height: 100%;
    text-align: center;
  }
  .icon-background{
    padding-inline: var(--cox2m-spacing-1-units);
  }
  .icon-background.selected{
    background: var(--cox2m-clr-brand-400);
    padding: var(--cox2m-spacing-1-units);
    border-radius: 50%;
  }
  :global(.icon-background.selected svg){
    color: var(--cox2m-clr-neutral-white) !important;
  }

  @media only screen and (max-width: 1200px) {
    .group-card {
      grid-template-columns: 1fr;
      grid-template-areas:
        'image'
        'content';
    }
    .event-image {
      grid-area: image;
      width: 100%;
      height: auto;
    }
    .steps-container {
      grid-area: content;
    }
  }
</style>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<svelte:window bind:innerWidth={windowWidth} />

{#if event}
  <div class="group-title-container d-flex justify-content-between">
    <span
      class="group-label"
      id="{event.id}-title"
      data-testid="{event.id}-title">
      {label}
    </span>
    <div class="actions-container">
      {#if windowWidth <= 1200}
        <Icon
          icon="more-vert"
          size="var(--cox2m-spacing-6-units)"
          color="var(--cox2m-clr-neutral-black)"
          onClick={() => dispatch('open-group-menu')} />
      {/if}
    </div>
  </div>
  <div
    class="group-card cox2m-shadow-2-dp"
    id="event-{event.id}-group-detail"
    data-testid="event-{event.id}-group-detail">
    <div class="steps-container">
      {#if loading}
        <div class="event-{event.id}-loading-container loading-container">
          <LoadingSpinner />
        </div>
      {:else if onError}
        <div class="event-{event.id}-error-container error-container">
          <h2 class="error-message">
            We are sorry we couldn't get the events associated with this object.
            if the error persists contact your administrator
          </h2>
        </div>
      {:else}
        {#each groupList as groupEvent, i}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            class="event-step cursor-pointer"
            on:click={() => {
              selectedEventIndex = i;
              dispatch('change-selected-event', groupEvent);
            }}>
            <span
              class="step-number"
              class:first-step={i === 0}
              class:last-step={i === groupList.length - 1}
              class:selected={selectedEventIndex === i}
              class:no-steps={groupList.length <= 1}>
              {i + 1}
            </span>
            <span class="step-data">
              <p
                class="event-data event-label"
                class:selected={selectedEventIndex === i}>
                {groupEventLabel(groupEvent, i)}
              </p>
              <p
                class="event-data event-date"
                class:selected={selectedEventIndex === i}>
                {getFormattedDate(groupEvent.createdAt)}
              </p>
              <div class="event-data-icon">
                <div
                  class="icon-background"
                  class:selected={selectedEventIndex === i && !shouldShowVideo}>
                  <Icon
                    style="align-self: center;"
                    icon="image-3"
                    color={selectedEventIndex === i ? 'var(--cox2m-clr-neutral-black)' : 'var(--cox2m-clr-neutral-500)'}
                    size="var(--cox2m-spacing-6-units)"
                    onClick={() => (shouldShowVideo = false)} />
                </div>
                {#if groupList[i].videoUrl}
                  <div
                    class="icon-background"
                    class:selected={selectedEventIndex === i && shouldShowVideo}>
                    <Icon
                      style="align-self: center;"
                      icon="video-camera"
                      color={selectedEventIndex === i ? 'var(--cox2m-clr-neutral-black)' : 'var(--cox2m-clr-neutral-500)'}
                      size="var(--cox2m-spacing-6-units)"
                      onClick={(e) => { e.preventDefault(); shouldShowVideo = true}} />
                  </div>
                {/if}
              </div>

            </span>
          </div>
        {/each}
      {/if}
    </div>
    {#if !loading && !onError && windowWidth < 1200}
      {#if !shouldShowVideo}
        <img
          src={event.imageUrl}
          class="event-image img-fluid m-0 mr-3"
          alt={`${event.id}-picture`} />
      {:else}
        <video
          autoplay
          controls
          muted
          class="d-block w-100 h-100"
          poster={event.imageUrl}
          src={event.videoUrl}
          alt={event.id + 'video'} />
      {/if}
    {/if}
  </div>
{/if}
