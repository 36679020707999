<script>
  import MapMarkersCollection from '@cox2m/city-services-ui-components/src/components/maps/arcgis/MapMarkersCollection.svelte';
  import SearchableSelectBadge from '@cox2m/city-services-ui-components/src/components/SearchableSelectBadge.svelte';
  import MobileModal from '@cox2m/city-services-ui-components/src/components/mobile/MobileModal/MobileModal.svelte';
  import DateTimePicker from '@cox2m/city-services-ui-components/src/components/time/DateTimePicker.svelte';
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';
  import SelectBadge from '@cox2m/city-services-ui-components/src/components/SelectBadge.svelte';
  import InexistentEventErrorModal from './components/events/InexistentEventErrorModal.svelte';
  import ToggleSwitch from '@cox2m/city-services-ui-components/src/forms/ToggleSwitch.svelte';
  import Map from '@cox2m/city-services-ui-components/src/components/maps/arcgis/Map.svelte';
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';
  import Icon from '@cox2m/city-services-ui-components/src/components/Icon.svelte';
  import CameraViewer from './components/CameraViewer.svelte';
  import EventCard from './components/EventCard.svelte';
  import axios from 'axios';

  import {
    getCookieByName,
    isMobile,
    setErrorEventData,
    titleCase
  } from '@cox2m/city-services-ui-components/src/funcs';
  import {
    getCameras,
    getEventsCoordinatesUsingFilter,
    getMinEventDate,
    getEventId
  } from '../actions';
  import {location, querystring} from 'svelte-spa-router';
  import {getMarkerConfig} from '../../funcs';
  import {menuOpts, selectedPark, user} from '../stores';
  import {onDestroy, onMount} from 'svelte';

  import {
    buildSocketUrl,
    cameraSortFunction,
    clearSockets,
    formatDateString,
    getPoliceUsers,
    handleEventMarkerClick,
    setEventsWebsocket,
    setTimeNow,
    typeOptions
  } from './components/scripts/events-screen/events-screen-funcs';
  import SiteIndicator from './components/shared/SiteIndicator.svelte';

  export let useViolationEvents = $location.startsWith('/violations');
  export let params = {};
  export let from = null;
  export let to = null;

  const token = getCookieByName(`${'ENV'}_token`);

  const authMessage = `{"auth" : "${token}"}`;
  const ONE_DAY_MS = 1000 * 60 * 60 * 24;
  const headers = {
    headers: {
      Authorization: token
    }
  };

  let eventsWebsocket = null;
  let initialParkNameQueryString = new URLSearchParams($querystring).get(
    'sitename'
  );
  let startDate = new Date();
  let currentDate; //this initial dates are handled by setDatesAccordingToLocation
  let finishDate; //this initial dates are handled by setDatesAccordingToLocation
  let userPickedTime;

  let eventsResponse;
  let filteredEvents = [];
  let events = [];

  let isDetailModalOpen = false;
  let isMapModalOpen = false;

  let liveEventsActive = false;
  let eventsFetchingError = false;
  let eventsLoading = true;

  let cameraOptionsError = false;
  let loadingOptions = true;

  let allEventsSelected = true;
  let selectedEvent = null;

  let selectedCamera = null;
  let selectedType = null;

  let camerasWithEvents = [];

  let filteredCameraOptions = [];
  let cameraOptions = [];

  let policeUsers = [];

  let mapView = undefined;
  let mapMobileView = undefined;

  let isSiteLoading;
  let isSiteOnError;
  let queryParams;
  let initialTime;
  let finalTime;

  let inexistentEventError = false;

  const formatDateStringPicker = date => {
    let dateToFormat = new Date(date);
    let tempDate = new Date(
      dateToFormat.getFullYear(),
      dateToFormat.getMonth(),
      dateToFormat.getDate()
    );
    let formattedDate = `${tempDate.getFullYear()}/${tempDate.getMonth() +
      1}/${tempDate.getDate()}`;
    return formattedDate;
  };

  const TODAY_FORMATTED_DATE = formatDateStringPicker(new Date());

  const setDatesAccordingToLocation = ({resetDates = false}) => {
    queryParams = new URLSearchParams($querystring);
    if (!params.id || resetDates) {
      currentDate = new Date(new Date() - ONE_DAY_MS);
      initialTime = '12:00 am';
      from = currentDate.toISOString().replace(/T.*/gi, 'T00:00:00.000');

      finishDate = new Date();
      finalTime = '11:59 pm';
      to = finishDate.toISOString().replace(/T.*/gi, 'T23:59:59.999');
    }
  };

  const removeEvent = eventToRemoveId => {
    events = events.filter(event => event.id !== eventToRemoveId);
    filteredEvents = filteredEvents.filter(
      event => event.id !== eventToRemoveId
    );
  };

  const handleAllEvensFilter = () => {
    if (!allEventsSelected) {
      allEventsSelected = true;
      filteredEvents = events;
      selectedCamera = null;
      selectedType = null;
    }
  };

  const eventFetchBuilder = async () => {
    let initialTime = '00:00:00.000';
    let finalTime = '23:59:59.999';

    if (from) {
      initialTime = new Date(from).toTimeString().substring(0, 8) + '.000';
    }
    if (to) {
      finalTime = new Date(to).toTimeString().substring(0, 8) + '.999';
    }

    let fetchOptions = {
      limit: 500,
      since: currentDate
        ? formatDateString({
            isInitialDate: true,
            date: from ? from : new Date(currentDate).toISOString(),
            userPickedTime,
            initialTime,
            finalTime
          })
        : setTimeNow(),
      until: formatDateString({
        isInitialDate: false,
        date: to ? to : new Date(finishDate).toISOString(),
        userPickedTime,
        initialTime,
        finalTime
      }),
      filter: `${selectedCamera ? `camera.name:${selectedCamera.label}` : ''}`,
      type: selectedType ? selectedType.value : null,
      siteName: $selectedPark.name,
      isViolation: useViolationEvents
    };

    return await getEventsCoordinatesUsingFilter(token, fetchOptions);
  };

  const filterByCameraAndType = async () => {
    eventsLoading = true;
    camerasWithEvents = [];
    liveEventsActive = false;

    if (selectedCamera || selectedType) {
      allEventsSelected = false;
      let filteredEventsResponse = await eventFetchBuilder();
      filteredEvents = filteredEventsResponse.events;
      getCamerasWithEvents();
      eventsLoading = false;
    } else {
      allEventsSelected = true;
      await getEventsResponse();
      filteredEvents = events;
      camerasWithEvents = [];
      eventsLoading = false;
    }
  };

  const getCamerasWithEvents = () => {
    if (filteredEvents) {
      filteredEvents.map(event => {
        if (event.camera && event.camera.id) {
          camerasWithEvents = [...camerasWithEvents, event.camera.id];
        }
      });
      camerasWithEvents = Array.from(new Set(camerasWithEvents));
    }
    filterCameraOptions();
  };

  const filterCameraOptions = () => {
    filteredCameraOptions = [];

    camerasWithEvents.forEach(cameraId => {
      let foundCamera = cameraOptions.find(camera => camera.value === cameraId);
      if (foundCamera) {
        filteredCameraOptions = [...filteredCameraOptions, foundCamera];
      }
    });
  };

  const socketMessageHandler = event => {
    let eventData = JSON.parse(event.data);

    if (eventData.camera.siteName === $selectedPark.name) {
      if (
        !filteredEvents.some(event => event.id === eventData.id) &&
        new Date(finishDate).toDateString() === new Date().toDateString() &&
        liveEventsActive
      ) {
        filteredEvents = [eventData, ...filteredEvents];
        getCamerasWithEvents();
      }
    }
  };

  const setEventsWebsocketHandler = () => {
    eventsWebsocket = setEventsWebsocket({
      eventsWebsocket,
      socketUrl: buildSocketUrl({
        selectedType,
        selectedCamera,
        useViolationEvents
      }),
      authMessage,
      liveEventsActive,
      onMessageHandler: socketMessageHandler
    });
  };

  const getEventsResponse = async () => {
    eventsFetchingError = false;
    eventsLoading = true;
    eventsResponse = await eventFetchBuilder();

    if (eventsResponse.fulfilled) {
      events = eventsResponse.events;
      if (params && params.id) {
        selectedEvent = events.find(event => event.id === params.id);
      } else if (useViolationEvents) {
        selectedEvent = events[0];
      }
      filteredEvents = eventsResponse.events;
    } else {
      eventsFetchingError = true;
    }
    eventsLoading = false;
  };

  const eventsScreenSetup = async () => {
    loadingOptions = true;
    allEventsSelected = true;
    selectedCamera = null;
    selectedType = null;
    liveEventsActive = false;

    let policeUsersResponse = await getPoliceUsers({axios, headers});
    policeUsers = policeUsersResponse.data;

    if (!policeUsersResponse.fulfilled) {
      setErrorEventData(
        window.dispatchEvent,
        policeUsersResponse.error.response,
        'get-user'
      );
    }

    const [cameraResponse, startDateResponse] = await Promise.all([
      getCameras(token, {siteName: $selectedPark.name}),
      getMinEventDate(token, {siteName: $selectedPark.name})
    ]);

    if (cameraResponse.fulfilled && startDateResponse.fulfilled) {
      cameraOptions = cameraResponse.cameras.map(camera => ({
        label: camera.name,
        value: camera.id
      }));

      startDate = new Date(startDateResponse.data.date);
      loadingOptions = false;
    } else {
      cameraOptionsError = true;
    }
  };

  /**
   * Handle show map event
   */
  const handleShowMap = () => {
    isMapModalOpen = selectedEvent !== null;
    isDetailModalOpen = false;

    if (mapMobileView) {
      mapMobileView.when(() => {
        const popup = mapMobileView.popup;
        popup.set('dockOptions', {
          breakpoint: false,
          buttonEnabled: false,
          position: 'bottom-center'
        });

        popup.autoOpenEnabled = false;
        const eventType = titleCase(selectedEvent.type);
        popup.open({
          title: `Type: ${eventType}`,
          content: `<b>Count: </b>${selectedEvent.value}<br/><b>Camera: </b>${selectedEvent.sourceId}`,
          location: selectedEvent.camera
        });
        popup.collapsed = false;
        popup.visible = true;

        popup.watch('collapsed', function(value) {
          if (value && popup.currentDockPosition === 'bottom-center') {
            popup.collapsed = false;
          }
        });
      });
    }
  };

  /**
   * Gets and build the map marker collection given an event
   * @param event
   */
  const getMapMarkerCollection = event => {
    let collection = [];
    const pedestrians =
      event.pedestrians &&
      event.pedestrians.length &&
      event.pedestrians.filter(
        eventPedestrians =>
          eventPedestrians.latitude && eventPedestrians.longitude
      );
    if (pedestrians.length) {
      collection.push(...pedestrians);
    }

    const vehicles =
      event.vehicles &&
      event.vehicles.length &&
      event.vehicles.filter(vehicle => vehicle.latitude && vehicle.longitude);
    if (vehicles.length) {
      collection.push(...vehicles);
    }

    if (!collection.length) {
      collection.push(selectedEvent.camera);
    }
    return collection;
  };

  onMount(async () => {
    if (params.id) {
      eventsLoading = true;
      let eventToSearchResponse = await getEventId(token, {
        eventId: 'this should be removed',
        searchedEventId: params.id
      });
      eventsLoading = false;
      if (!eventToSearchResponse.fulfilled) {
        inexistentEventError = true;
        setDatesAccordingToLocation({resetDates: true});
      } else {
        eventToSearchResponse.searchedEvent.camera.siteName !== '' &&
          (initialParkNameQueryString =
            eventToSearchResponse.searchedEvent.camera.siteName);
        currentDate = new Date(
          eventToSearchResponse.searchedEvent.createdAt
        ).toLocaleDateString();
        finishDate = new Date(
          eventToSearchResponse.searchedEvent.createdAt
        ).toLocaleDateString();
        initialTime = '12:00 am';
        finalTime = '11:59 pm';
      }
    }

    window.addEventListener('clearSockets', () =>
      clearSockets(eventsWebsocket)
    );
  });

  onDestroy(() => {
    eventsWebsocket && eventsWebsocket.close();
  });

  $: setDatesAccordingToLocation($location, $selectedPark.name);
  $: useViolationEvents = $location.startsWith('/violations');
  $: eventsScreenSetup(useViolationEvents, $selectedPark.name);
  $: filterByCameraAndType(
    selectedCamera,
    selectedType,
    currentDate,
    finishDate,
    userPickedTime,
    useViolationEvents,
    $selectedPark.name
  );
  $: setEventsWebsocketHandler(liveEventsActive, $selectedPark.name);
  $: handleShowMap(mapMobileView);
  $: $selectedPark && $selectedPark.name && getCamerasWithEvents();

  $: showNoViolationsThatDayMessage = allEventsSelected && filteredEvents.length == 0 &&  (!userPickedTime ||
      (userPickedTime &&
        userPickedTime.selectedEndTime.userFriendlyFormat == '11:59 pm' &&
        userPickedTime.selectedStartTime.userFriendlyFormat == '12:00 am'));

</script>

<style>
  /* globals */
  :global(.date-time-picker-container > .row) {
    margin-left: 0 !important;
  }

  :global(.date-time-picker-container .box) {
    z-index: var(--cox2m-z-index-1) !important;
  }

  /* layout */
  .page-content-container {
    display: grid;
    grid-template-areas:
      'viewer  cards'
      'map     cards';

    grid-template-columns: 65% 1fr;
    grid-template-rows: auto 1fr;
    min-height: 100%;
    column-gap: var(--cox2m-spacing-5-units);
    row-gap: var(--cox2m-spacing-5-units);
  }
  .camera-viewer-container {
    grid-area: viewer;
  }
  .map-container {
    grid-area: map;
    min-height: 240px;
    height: 100%;
  }
  .map-mobile-container {
    min-height: 240px;
    height: 85vh;
  }

  .cards-container {
    grid-area: cards;
    height: 100%;
  }

  /* styles */
  .loading-options-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--cox2m-spacing-6-units);
  }
  #event-cards-container {
    overflow: auto;
    max-height: clamp(1000px, 100vh, 1500px);
    display: flex;
    flex-direction: column;
    gap: var(--cox2m-spacing-2-units);
    padding: var(--cox2m-spacing-1-unit);
  }
  #event-cards-container::-webkit-scrollbar {
    display: none;
  }
  #event-cards-container:hover::-webkit-scrollbar {
    display: initial;
  }

  #filters-container {
    display: flex;
    justify-content: space-between;
  }
  .date-time-picker-container {
    display: flex;
    align-items: center;
    gap: var(--cox2m-spacing-6-units);
  }
  .selector-badges-container {
    display: flex;
    align-items: center;
    gap: var(--cox2m-spacing-4-units);
  }
  .live-switch-container {
    display: flex;
    width: 100%;
    justify-content: end;
    align-items: center;
    margin: var(--cox2m-spacing-2-units) 0 var(--cox2m-spacing-4-units);
  }
  .live-action-label {
    margin-right: var(--cox2m-spacing-2-units);
  }

  .all-badge-filter {
    color: var(--cox2m-clr-neutral-black);
    border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-brand-400);
    border-radius: 54px;
  }

  .all-badge-filter.active {
    background-color: var(--cox2m-clr-brand-600);
    border: var(--cox2m-brd-w-1) solid var(--cox2m-clr-brand-600);
    color: var(--cox2m-clr-neutral-white);
  }
  .live-action-label,
  #events-empty-message {
    color: var(--cox2m-clr-neutral-600);
  }
  #events-page-error-message {
    color: var(--cox2m-clr-critical-500);
  }
  .live-switch-container.mobile-only {
    display: none !important;
  }
  .mobile-card-container {
    margin-top: var(--cox2m-spacing-5-units);
  }
  :global(.esri-popup__footer) {
    display: none !important;
  }
  :global(#filters-container .date-time-picker-container #date-picker) {
    padding-left: 10px;
  }

  .title-container {
    display: none;
  }

  .no-violations-that-day{
    grid-column: -1 / 1;
    margin-top: var(--cox2m-spacing-8-units);
  }

  @media only screen and (max-width: 1200px) {
    :global(#events-time-picker) {
      flex-grow: 1;
      justify-content: center;
    }
    :global(.date-time-picker-container > .position-relative) {
      width: 100%;
    }

    .page-content-container {
      grid-template-areas:
        'viewer'
        'cards';
      grid-template-columns: 100%;
      grid-template-rows: auto 1fr;
      column-gap: 0;
      row-gap: var(--cox2m-spacing-6-units);
    }
    .map-container {
      display: none;
    }
    .date-time-picker-container {
      flex-wrap: wrap;
    }
    #filters-container {
      flex-direction: column;
      row-gap: var(--cox2m-spacing-6-units);
    }
    .live-switch-container {
      display: none;
    }
    .live-switch-container.mobile-only {
      display: flex !important;
    }
    .selector-badges-container {
      margin-bottom: var(--cox2m-spacing-6-units);
    }
    #event-cards-container:hover::-webkit-scrollbar {
      display: none;
    }
    .camera-viewer-container {
      display: none;
    }
    .title-container {
      display: block;
      margin-bottom: -32px;
      font-weight: bold;
      color: var(--cox2m-clr-neutral-black);
    }
  }
</style>

{#if inexistentEventError}
  <InexistentEventErrorModal bind:show={inexistentEventError} />
{/if}

<!-- svelte-ignore a11y-click-events-have-key-events -->
<MobileModal
  viewTitle="Event"
  leftIcon={'chevron-left'}
  leftIconClick={() => {
    isDetailModalOpen = false;
    isMapModalOpen = false;
  }}
  bind:open={isDetailModalOpen}>
  <CameraViewer
    {token}
    enableLinkToLive={true}
    {selectedEvent}
    customClass="camera-viewer-mobile-container" />

  <div class="mobile-card-container">
    <EventCard
      selected={true}
      isViolationEvent={true}
      authorities={policeUsers}
      event={selectedEvent}
      expanded={true}
      on:showEventOnMap={handleShowMap} />
  </div>
</MobileModal>

<MobileModal
  viewTitle="Event Map"
  leftIcon={'chevron-left'}
  leftIconClick={() => {
    isMapModalOpen = false;
    isDetailModalOpen = true;
  }}
  bind:open={isMapModalOpen}>
  <div id="map-mobile-container" class="map-mobile-container">
    <Map
      props={{zoom: 18, center: [selectedEvent.camera.longitude, selectedEvent.camera.latitude]}}
      basemap="hybrid"
      bind:view={mapView}>
      <MapMarkersCollection
        collection={getMapMarkerCollection(selectedEvent)}
        markerConfig={getMarkerConfig()} />
    </Map>
  </div>
</MobileModal>

<MainScreen title="Events" menuOpt={$menuOpts} showAlertsBanner={false} user={$user} appSlug="smart-security">
  <div slot="main-dashboard">
    <div>
      <SiteIndicator
        title={useViolationEvents ? 'Violations' : 'Events'}
        bind:isLoading={isSiteLoading}
        bind:isOnError={isSiteOnError}
        pickedSite={initialParkNameQueryString ? decodeURI(initialParkNameQueryString) : null} />
    </div>
    {#if !loadingOptions && !cameraOptionsError && !isSiteLoading}
      <div id="filters-container" class="filters-container">
        <div class="date-time-picker-container">
          <DateTimePicker
            on:confirm-selection-change={e => {
              e.detail.latestValidFirstSelectedDate && (currentDate = e.detail.latestValidFirstSelectedDate);
              e.detail.latestValidLatestSelectedDate && (finishDate = e.detail.latestValidLatestSelectedDate);
              userPickedTime = {selectedStartTime: e.detail.selectedStartTime, selectedEndTime: e.detail.selectedEndTime};
              from = null;
              to = null;
            }}
            firstSelectedDate={formatDateStringPicker(currentDate)}
            latestSelectedDate={formatDateStringPicker(finishDate)}
            endCalendarAt={TODAY_FORMATTED_DATE}
            initialStartTime={initialTime}
            initialEndTime={finalTime}
            timeRange={true} />
        </div>

        <div class="selector-badges-container">
          <span class="d-flex filter-icon-container">
            <Icon
              icon="filter"
              size="calc(--cox2m-spacing-4-units) +
              var(--cox2m-visual-corrector))"
              color="var(--cox2m-clr-neutral-700)" />
          </span>
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <span
            class="reset-filter-badge d-flex"
            on:click={() => handleAllEvensFilter()}>
            <span
              class="all-badge-filter d-flex align-items-center
              justify-content-center cox2m-smalltext-1 {allEventsSelected ? 'active' : ''}
              px-3 py-0 pointer">
              All
            </span>
          </span>
          <span class="d-flex">
            <SearchableSelectBadge
              bind:selectedOption={selectedCamera}
              id="camera-filter-badge"
              options={allEventsSelected ? cameraOptions.sort(cameraSortFunction) : filteredCameraOptions.sort(cameraSortFunction)}
              defaultLabel="Camera"
              responsiveTitle="Filter by camera" />
          </span>
          <span class="d-flex">
            <SelectBadge
              bind:selectedOption={selectedType}
              id="type-filter-badge"
              options={typeOptions}
              defaultLabel="Type"
              responsiveTitle="Filter by type" />
          </span>
        </div>
      </div>
    {:else}
      <div class="loading-options-container">
        <LoadingSpinner />
      </div>
    {/if}

    <div class="live-switch-container">
      <span class="live-action-label">
        {liveEventsActive ? 'stop live' : 'live events'}
      </span>
      <ToggleSwitch
        id="live-events-switch"
        disabled={new Date(finishDate).toDateString() !== new Date().toDateString()}
        bind:checked={liveEventsActive} />
    </div>

    <div class="page-content-container" id="events-page-container">
      <div class="title-container">
        {#if useViolationEvents}
          <h2>Violations</h2>
        {:else}
          <h2>Events</h2>
        {/if}
      </div>
      {#if showNoViolationsThatDayMessage}
        <h2 class="w-100 text-center no-violations-that-day">There are no {useViolationEvents ? 'violations' : 'events'} for the selected days.</h2>
      {:else}
        <div class="camera-viewer-container">
          <CameraViewer
            {token}
            {camerasWithEvents}
            {allEventsSelected}
            activateCameraCarousel={!useViolationEvents}
            enableLinkToLive={useViolationEvents}
            bind:selectedEvent />
        </div>
        <div id="map-container" class="map-container">
          {#if selectedEvent && !isDetailModalOpen}
            <Map
              props={{zoom: 18, center: [selectedEvent.camera.longitude, selectedEvent.camera.latitude]}}
              basemap="hybrid"
              bind:view={mapView}>
              <MapMarkersCollection
                collection={getMapMarkerCollection(selectedEvent)}
                onMarkerClick={(data, event) => handleEventMarkerClick({
                    mapView,
                    data,
                    event
                  })}
                markerConfig={getMarkerConfig()} />
            </Map>
          {/if}
        </div>
        <div class="cards-container">

          <div class="live-switch-container mobile-only">
            <span class="live-action-label">
              {liveEventsActive ? 'stop live' : 'live events'}
            </span>
            <ToggleSwitch
              id="live-events-switch"
              disabled={new Date(finishDate).toDateString() !== new Date().toDateString()}
              bind:checked={liveEventsActive} />
          </div>

          <div class="event-cards-container" id="event-cards-container">
            {#if eventsLoading || isSiteLoading}
              <div class="d-flex justify-content-center align-items-center">
                <LoadingSpinner />
              </div>
            {:else if filteredEvents && filteredEvents.length === 0 && !eventsFetchingError && !isSiteOnError}
              <span id="events-empty-message" class="d-block w-100 text-center">
                The selected criteria doesn't show any results
              </span>
            {:else if filteredEvents && !eventsFetchingError && !isSiteOnError}
              {#each filteredEvents as event (event.id)}
                <EventCard
                  selected={selectedEvent && selectedEvent.id === event.id}
                  isViolationEvent={useViolationEvents}
                  on:eventSelected={e => {
                    isDetailModalOpen = isMobile();
                    selectedEvent = e.detail.event;
                  }}
                  on:eventDeleted={e => removeEvent(e.detail.eventId)}
                  showExpand={!isMobile()}
                  authorities={policeUsers}
                  {event} />
              {/each}
            {:else if eventsFetchingError || !filteredEvents || isSiteOnError}
              <span
                id="events-page-error-message"
                class="d-block w-100 text-center">
                We are sorry, we couldn't get the events information, please
                reload the page or try again later.
              </span>
            {/if}
          </div>
        </div>
      {/if}
    </div>
  </div>
</MainScreen>
