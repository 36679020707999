<script>
  //THIS COMPONENT SHOULD BE MOVED TO UI-COMPONENTS AND ADJUSTED
  import {loadModules} from 'esri-loader/dist/esm/modules.js';
  import {getContext, onDestroy} from 'svelte';
  import {getMarkerConfig} from '@cox2m/city-services-ui-components/src/components/maps/arcgis/MapMarker.svelte';
  import {key} from '@cox2m/city-services-ui-components/src/components/maps/arcgis/arcgis';

  export let collection = [];
  export let layer = null;
  export let layerId = null;
  export let layerTitle = null;
  export let simpleRendererScale = 200;

  export let markerConfig = undefined;

  const {getMap, getView} = getContext(key);
  const map = getMap();
  const view = getView();

  async function addCollectionData() {
    const [Graphic, FeatureLayer] = await loadModules(
      ['esri/Graphic', 'esri/layers/FeatureLayer'],
      {css: true}
    );

    const graphics = collection.map(location => {
      // First create a point geometry
      const point = {
        type: 'point', // autocasts as new Point()
        longitude: location.longitude,
        latitude: location.latitude
      };

      // Create a symbol for drawing the point
      const markerSymbol = markerConfig || getMarkerConfig({props: location});

      // Create a graphic and add the geometry and symbol to it
      const pointGraphic = new Graphic({
        geometry: point,
        symbol: markerSymbol,
        attributes: location
      });

      return pointGraphic;
    });

    // Create a layer, add the graphics to it, and then we add the layer to the map.
    layer = new FeatureLayer({
      //With the layerId we can find the layer in the map using findLayerById.
      id: layerId,
      title: layerTitle,
      source: graphics, // autocast as a Collection of new Graphic()
      objectIdField: 'heatmapobjectid',
      renderer: {
          type: "heatmap",
          colorStops: [
            { color: "rgba(0,0,0,0)", ratio: 0/7 },
            { color: "#FF00FF", ratio: 1/7 },
            { color: "#800080", ratio: 2/7 },
            { color: "#0000FF", ratio: 3/7 },
            { color: "#00FF00", ratio: 4/7 },
            { color: "#FFA500", ratio: 5/7 },
            { color: "#FFFF00", ratio: 6/7 },
            { color: "#FF0000", ratio: 7/7 }
          ]
        }
    });

    map.add(layer);

    if (simpleRendererScale > 0) {
      view.when().then(() => {
        // When the view is ready, clone the heatmap renderer
        // from the only layer in the web map

        const layer = view.map.layers.getItemAt(0);
        const heatmapRenderer = layer.renderer.clone();

        // The following simple renderer will render all points as simple
        // markers at certain scales
        const simpleRenderer = {
          type: "simple",
          symbol: {
            type: "simple-marker",
            color: "#00D084",
            size: 5
          }
        };

        // When the scale is larger than 1:72,224 (zoomed in passed that scale),
        // then switch from a heatmap renderer to a simple renderer. When zoomed
        // out beyond that scale, switch back to the heatmap renderer
        view.watch("scale", (newValue) => {
          console.info('[scale]', newValue);
          layer.renderer =
            newValue <= simpleRendererScale ? simpleRenderer : heatmapRenderer;
        });
      });
    }
  }

  function shouldRefreshData() {
    if (layer) {
      map.remove(layer);
      layer = null;
    }
    if (collection.length > 0) {
      addCollectionData();
    }
  }


  $: shouldRefreshData(collection);

  onDestroy(() => {
    map.remove(layer);
  });
</script>
