<script>
  import LoadingSpinner from '@cox2m/city-services-ui-components/src/components/LoadingSpinner.svelte';

  import {getCookieByName} from '@cox2m/city-services-ui-components/src/funcs';
  import {getAllSites} from '../../../actions';
  import {selectedPark} from '../../../stores';
  import {onMount} from 'svelte';

  export let pickedSite = null;
  export let isLoading = false;
  export let isOnError = false;
  export let title = '';

  const token = getCookieByName(`${'ENV'}_token`);

  const setUpSite = async () => {
    if (
      pickedSite ||
      !$selectedPark ||
      Object.keys($selectedPark).length === 0
    ) {
      isLoading = true;
      const sitesResponse = await getAllSites(token);
      if (sitesResponse.fulfilled) {
        if (pickedSite) {
          //this implementation is mostly for the email templates so we wouldn't need to send a json format in a url
          let parkFound = sitesResponse.sites.filter(
            site => site.name === pickedSite
          )[0];
          if (parkFound) {
            selectedPark.setSelectedPark(parkFound);
          } else {
            isOnError = true;
          }
        } else {
          selectedPark.setSelectedPark(sitesResponse.sites[0]);
        }
      } else {
        isOnError = true;
      }
      isLoading = false;
    }
  };

  onMount(async () => {
    setUpSite();
  });

  $: setUpSite(pickedSite);
</script>

<style>
  p {
    margin-bottom: 0;
  }
  .title {
    font-weight: var(--cox2m-fw-bold);
    color: var(--cox2m-clr-neutral-black);
    font-size: var(--cox2m-fs-400);
    margin-bottom: var(--cox2m-spacing-2-units);
  }
  .site-title {
    color: var(--cox2m-clr-neutral-700);
    font-size: var(--cox2m-fs-1200);
  }
  .loading-spinner-container {
    margin-bottom: var(--cox2m-spacing-4-units);
  }
  @media only screen and (max-width: 1200px) {
    .site-title {
      font-size: var(--cox2m-fs-700);
      word-break: break-all;
    }
  }
</style>

{#if isLoading}
  <div class="loading-spinner-container">
    <LoadingSpinner />
  </div>
{:else}
  <div>
    {#if !isOnError && $selectedPark && $selectedPark.name}
      <p class="site-title">{$selectedPark.name}</p>
    {/if}

    <p class="title">{title}</p>
  </div>
{/if}
